import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import {
  AdminPrinterCreateParams,
  AdminPrinterRepository,
} from "../../../application/data/repositories/AdminPrinterRepository";
import { AdminPrinter } from "../../../application/models/AdminPrinter";
import { AdminPrinterMapper } from "../mappers/AdminPrinterMapper";
import { AdminPrinterRaw } from "../models/AdminPrinterRaw";

export class AdminPrinterRepositoryImpl implements AdminPrinterRepository {
  constructor(
    private httpProvider: HttpProvider,
    private printerMapper: AdminPrinterMapper
  ) {}

  async findAll(): Promise<AdminPrinter[]> {
    const response = await this.httpProvider.request<AdminPrinterRaw[]>({
      method: "GET",
      url: "/printer",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) => this.printerMapper.printerRawToPrinter(e))
    );
  }

  async create(params: AdminPrinterCreateParams): Promise<AdminPrinter> {
    const response = await this.httpProvider.request<AdminPrinterRaw>({
      method: "POST",
      url: "/printer",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.printerMapper.printerRawToPrinter(response.body);
  }

  async delete(id: number): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/printer/${id}`,
    });
  }
}
