import { withRouter } from "react-router-dom";
import { List, Datagrid, TextField, DeleteButton } from "react-admin";
import { ListProps } from "@material-ui/core";

const PrinterList = (props: ListProps) => {
  return (
    <List pagination={false} {...props}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="model" sortable={false} />
        <TextField label="MAC Address" source="macAddress" sortable={false} />
        <DeleteButton basePath="/printer" />
      </Datagrid>
    </List>
  );
};

export default withRouter(PrinterList);
