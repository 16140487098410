import React, { useEffect, useMemo } from "react";
import { Button, Card, Dropdown, DropdownButton, Form } from "react-bootstrap";

import Section from "../../../components/shared/section";

import {
  ControlSectionViewModel,
  ControlSectionViewModelState,
} from "./ControlSectionViewModel";
import { userContainer } from "../../../../../di/container";
import { UserPrinter } from "../../../../../../application/models/UserPrinter";
import { SyringePrimingControls } from "../../../components/shared/SyringePrimingControls";
import { PrintingStatus } from "../../../../models/PrintingStatus";
import { PrinterConnection } from "../../../../../../application/data/connections/PrinterConnection";
import { MixMode } from "../../../../models/TissuestartState";
import { ViewModelAlertModal } from "../../../components/shared/ViewModelAlertModal";
import { PrinterControlAxis } from "./AxisControls";
import { PrintingModeControls } from "./PrintingModeControls";

interface ControlSectionProps {
  state: ControlSectionViewModelState;
  printers: UserPrinter[];
  selectedPrinter: UserPrinter | null;
  mixtureRatio: number;
  mixMode: MixMode;
  priming: number;
  disableMovement: boolean;
  showMixModeSlider: boolean;
  isPrintingViaServerMode: boolean;
  printingStatus: PrintingStatus;
  printerConnection: PrinterConnection | null;
  isSerialConnectionSupported: boolean;
  onStateChange: (state: ControlSectionViewModelState) => void;
  onDisconnect: () => void;
  onConnect: () => void;
  onMoveSyringeUp: () => void;
  onMoveSyringeDown: () => void;
  setMixtureRatio: (value: number) => void;
  setMixMode: (value: MixMode) => void;
  setPriming: (value: number) => void;
  updateSelectedPrinter: (selected: UserPrinter) => void;
  updatePrintingMode: (selected: boolean) => void;
}

export const ControlSection: React.FC<ControlSectionProps> = ({
  state,
  mixtureRatio,
  disableMovement,
  priming,
  printingStatus,
  mixMode,
  showMixModeSlider,
  printerConnection,
  printers,
  selectedPrinter,
  isPrintingViaServerMode,
  isSerialConnectionSupported,
  setMixMode,
  onStateChange,
  setMixtureRatio,
  setPriming,
  onMoveSyringeDown,
  onMoveSyringeUp,
  onDisconnect,
  onConnect,
  updatePrintingMode,
  updateSelectedPrinter,
}) => {
  const viewModel = useMemo(() => {
    const vm = new ControlSectionViewModel(
      userContainer.providers.commandGcode,
      userContainer.services.printing,
      state,
    );

    vm.setStateChangeCallback((newState) => {
      onStateChange(newState);
    });

    return vm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStateChange]);

  useEffect(() => {
    viewModel.verifyPrintingAlert(printingStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewModel]);

  return (
    <Section>
      <ConnectionSection
        printers={printers}
        selectedPrinter={selectedPrinter}
        isConnected={printingStatus !== "disconnected"}
        isPrintingViaServer={isPrintingViaServerMode}
        isSerialConnectionSupported={isSerialConnectionSupported}
        onConnect={() => onConnect()}
        onDisconnect={() => onDisconnect()}
        onSelectedPrinterChange={(selected) => updateSelectedPrinter(selected)}
        osPrintingViaServerChange={(option) => updatePrintingMode(option)}
      />

      {printerConnection !== null && (
        <div
          className="printer-control"
          style={{
            color: "gray",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <PrinterControlAxis
            isZSet={state.isZSet}
            isHomeSet={state.isHomeSet}
            distance={state.distance}
            onSetZ={() => viewModel.setZ()}
            onHomePrinter={() => viewModel.moveToHome()}
            onMoveForward={() => viewModel.moveForward()}
            onMoveBackward={() => viewModel.moveBackward()}
            onMoveLeft={() => viewModel.moveLeft()}
            onMoveRight={() => viewModel.moveRight()}
            onMoveUpZ={() => viewModel.moveUpZ()}
            onMoveDownZ={() => viewModel.moveDownZ()}
            onCenter={() => viewModel.moveToCenter()}
            onDistanceChange={(value) => viewModel.setDistance(value)}
            disableMovement={disableMovement}
          />
          <PrintingModeControls
            showMixModeSlider={showMixModeSlider}
            mixtureRatio={mixtureRatio}
            mix={mixMode}
            onMixModeChange={(value) => setMixMode(value)}
            onMixtureRatioChange={(value) => setMixtureRatio(value)}
            disableChangeMixMode={printingStatus !== "connected"}
          />
          <SyringePrimingControls
            onPrimingChange={(value) => setPriming(value)}
            priming={priming}
            onSyringeUp={() => onMoveSyringeUp()}
            onSyringeDown={() => onMoveSyringeDown()}
            disable={mixMode === ""}
          />
        </div>
      )}
      <ViewModelAlertModal
        alert={state.alert}
        onClose={() => viewModel.clearAlert()}
      />
    </Section>
  );
};

interface ConnectionSectionProps {
  printers: UserPrinter[];
  selectedPrinter: UserPrinter | null;
  isConnected: boolean;
  isPrintingViaServer: boolean;
  isSerialConnectionSupported: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  onSelectedPrinterChange: (selectedPrinter: UserPrinter) => void;
  osPrintingViaServerChange: (isPrintingViaServer: boolean) => void;
}

const ConnectionSection: React.FC<ConnectionSectionProps> = ({
  printers,
  selectedPrinter,
  isConnected,
  isPrintingViaServer,
  isSerialConnectionSupported,
  onConnect,
  onDisconnect,
  onSelectedPrinterChange,
  osPrintingViaServerChange,
}) => {
  if (isConnected) {
    return (
      <Button variant="danger" onClick={onDisconnect}>
        Disconnect
      </Button>
    );
  }

  return (
    <>
      <Card style={{ width: "100%", padding: "1vw" }}>
        <Form>
          <Form.Group>
            <Form.Label>Connection type</Form.Label>
            <DropdownButton
              id="connection-type-dropdown"
              variant="secondary"
              title={isPrintingViaServer ? "WiFi" : "USB"}
              className="forms-dropdown"
            >
              <Dropdown.Item onClick={() => osPrintingViaServerChange(false)}>
                USB
                {isSerialConnectionSupported
                  ? ""
                  : " (not supported in this browser)"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => osPrintingViaServerChange(true)}>
                WiFi
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>

          {isPrintingViaServer && (
            <Form.Group>
              <Form.Label>Printer</Form.Label>
              <DropdownButton
                id="printers-dropdown"
                variant="secondary"
                title={
                  selectedPrinter
                    ? `TissueStart - ${selectedPrinter.macAddress}`
                    : "Select a printer"
                }
                className="forms-dropdown"
              >
                {printers.map((printer) => (
                  <Dropdown.Item
                    key={printer.id}
                    onClick={() => onSelectedPrinterChange(printer)}
                  >
                    TissueStart - {printer.macAddress} (
                    {printer.isOnline ? "online" : "offline"})
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
          )}
        </Form>
      </Card>
      <Button variant="light" onClick={onConnect}>
        Connect
      </Button>
    </>
  );
};
