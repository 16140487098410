import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetOneParams,
  GetOneResult,
  Record,
  UpdateParams,
  UpdateResult,
} from "react-admin";

import { AdminFirmwareRepository } from "../../../../application/data/repositories/AdminFirmwareRepository";
import { AdminFirmwareFileParserProvider } from "../../../../application/providers/AdminFirmwareFileParserProvider";

export class AdminFirmwareProviderImpl {
  constructor(
    private firmwareFileParserProvider: AdminFirmwareFileParserProvider,
    private firmwareRepository: AdminFirmwareRepository
  ) {}

  async getList<RecordType extends Record = Record>(): Promise<
    GetListResult<RecordType>
  > {
    const data = await this.firmwareRepository.findAll();
    const formattedData = data.map((firmware) => ({
      ...firmware,
      status: firmware.disabledAt ? "disabled" : "enabled",
      typeDisplayName: firmware.type === "STM32" ? "STM32 (Monster)" : "ESP32",
    }));
    return {
      data: formattedData as unknown as RecordType[],
      total: data.length,
    };
  }

  async getOne<RecordType extends Record = Record>(
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const data = await this.firmwareRepository.findById(
      parseInt(params.id.toString())
    );
    const formattedData = {
      ...data,
      status: data.disabledAt ? "disabled" : "enabled",
      typeDisplayName: data.type === "STM32" ? "STM32 (Monster)" : "ESP32",
    };
    return { data: formattedData as unknown as RecordType };
  }

  async create<RecordType extends Record = Record>(
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const parsedFile = await this.firmwareFileParserProvider.execute(
      params.data.file.rawFile
    );

    const data = await this.firmwareRepository.create(parsedFile);

    return { data: data as unknown as RecordType };
  }

  async update<RecordType extends Record = Record>(
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const data = await this.firmwareRepository.toggleDisabledAt(
      parseInt(params.id.toString())
    );
    return { data: data as unknown as RecordType };
  }

  async delete<RecordType extends Record = Record>(
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    await this.firmwareRepository.delete(parseInt(params.id.toString()));
    return { data: {} as RecordType };
  }

  async deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    await Promise.all(
      params.ids.map((id) =>
        this.firmwareRepository.delete(parseInt(id.toString()))
      )
    );

    return { data: [] };
  }
}
