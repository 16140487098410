import React, { HTMLAttributes } from "react";
import { FormControl } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import logo from "../../../assets/images/logo-icon.png";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";

interface SidebarProps
  extends AppState,
    DispatchProp,
    HTMLAttributes<HTMLDivElement> {}

class Sidebar extends React.Component<SidebarProps> {
  render() {
    let sidebar = this.props.isViewerReady ? (
      this.props.children
    ) : (
      <div
        style={{
          background: "#ff80ae",
          padding: "10%",
        }}
      >
        <style>
          {`
        			    @keyframes beat {
        			         0% { width: 8vw; }
        			         100% { width: 10vw; }
        			    }
        			`}
        </style>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 101,
          }}
        >
          <img
            style={{
              animation: `beat 1s linear alternate infinite`,
            }}
            src={logo}
            alt="tissuelabs logo"
          />
          <div style={{ fontSize: "3vw", color: "white" }}>
            {this.props.loadMessage}
          </div>
        </div>
      </div>
    );

    return (
      <aside
        style={{
          position: "fixed",
          color: "white",
          top: "0%",
          left: "0%",
          width: "35%",
          height: "100%",
          background: "#ff80ae",
          boxShadow: "darkred",
          display: "flex",
          flexDirection: "column",
        }}
        className="sidebar"
      >
        {sidebar}
      </aside>
    );
  }
}

export class SidebarHeader extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem 1.5rem",
          marginBottom: "1.5vw",
        }}
        className="sidebar-header"
      >
        {this.props.children}
      </div>
    );
  }
}

export class SidebarTabs extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "0.5vw",
          gap: "1vw",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

interface BodyProps extends DispatchProp, HTMLAttributes<HTMLDivElement> {
  alignMode: boolean;
}

class _SidebarBody extends React.Component<BodyProps> {
  onClickSidebar() {
    if (this.props.alignMode)
      this.props.dispatch({ type: "object/cancelAlign" });
    this.props.dispatch({ type: "project/unsavedChanges" });
  }

  render() {
    return (
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          width: "auto",
          flex: 1,
        }}
        className="sidebar-body"
        onClick={() => this.onClickSidebar()}
      >
        {this.props.children}
      </div>
    );
  }
}

interface LogoProps {
  src: string;
}

export class SidebarLogo extends React.Component<LogoProps> {
  render() {
    return (
      <img
        style={{
          height: "3vw",
        }}
        src={this.props.src}
        alt="tissuelabs logo"
      />
    );
  }
}

interface TitleProps extends DispatchProp {
  projectName?: string;
}

class _SidebarTitle extends React.Component<TitleProps> {
  handleChange(projectName: string) {
    this.props.dispatch({
      type: "project/rename",
      projectName,
    });
  }

  render() {
    return (
      <FormControl
        onChange={(e) => this.handleChange(e.target.value)}
        type="text"
        value={this.props.projectName}
        style={{
          color: "white",
          borderColor: "white",
          background: "#ff80ae",
          width: "40%",
        }}
      />
    );
  }
}

export const SidebarBody = connect(mapStateToProps)(_SidebarBody);
export const SidebarTitle = connect(mapStateToProps)(_SidebarTitle);

export default connect(mapStateToProps)(Sidebar);
