import {
  PrinterConnection,
  PrinterConnectionDisconnectCallback,
  PrinterConnectionReceiveCallback,
} from "../../../application/data/connections/PrinterConnection";

export class PrinterConnectionWebSerial implements PrinterConnection {
  private _isConnected: boolean = false;
  private error: Error | null = null;
  private receiveCallback: PrinterConnectionReceiveCallback | null = null;
  private disconnectCallback: PrinterConnectionDisconnectCallback | null = null;
  private readableStream: ReadableStreamDefaultReader<Uint8Array> | null = null;
  private writableStream: WritableStreamDefaultWriter<Uint8Array> | null = null;

  constructor(
    private port: SerialPort,
    private receiveMessageLineEnding: string,
    baudRate: number
  ) {
    this.connect(baudRate);
  }

  async send(data: string): Promise<void> {
    if (!this._isConnected || !this.writableStream) return;

    try {
      const byteArray = Uint8Array.from(
        Array.from(data).map((e) => e.charCodeAt(0))
      );

      await this.writableStream.write(byteArray);
      console.log(">>", data);
    } catch (err: unknown) {
      await this.handleError(err);
    }
  }

  async isConnected(): Promise<boolean> {
    return this._isConnected;
  }

  async disconnect(): Promise<void> {
    if (!this._isConnected) return;

    this.readableStream?.releaseLock();
    this.readableStream = null;

    this.writableStream?.releaseLock();
    this.writableStream = null;

    this._isConnected = false;
    await this.port.close();
    await this.disconnectCallback?.();
  }

  async setReceiveCallback(
    cb: PrinterConnectionReceiveCallback
  ): Promise<void> {
    this.receiveCallback = cb;
  }

  async setDisconnectCallback(
    cb: PrinterConnectionDisconnectCallback
  ): Promise<void> {
    this.disconnectCallback = cb;

    if (!this._isConnected && this.error) {
      cb();
    }
  }

  private async connect(baudRate: number) {
    try {
      await this.port.open({ baudRate });
      this.readableStream = this.port.readable?.getReader() || null;
      this.writableStream = this.port.writable?.getWriter() || null;
      this._isConnected = true;
      await this.readInLoop();
    } catch (err: unknown) {
      await this.handleError(err);
    }
  }

  private async readInLoop() {
    const decoder = new TextDecoder();
    let acc: string = "";

    while (this._isConnected && this.readableStream) {
      const { done, value } = await this.readableStream.read();

      if (done) {
        await this.disconnect();
      }

      if (value) {
        acc += decoder.decode(value);
      }

      if (!this.receiveCallback) continue;

      for (
        let lineEndIndex = acc.indexOf(this.receiveMessageLineEnding);
        lineEndIndex >= 0;
        lineEndIndex = acc.indexOf(this.receiveMessageLineEnding)
      ) {
        const line = acc.slice(0, lineEndIndex);
        console.log("<<", line);

        await this.receiveCallback(line);
        acc = acc.slice(lineEndIndex + this.receiveMessageLineEnding.length);
      }
    }
  }

  private async handleError(err: unknown) {
    this.error = err instanceof Error ? err : new Error(String(err));
    await this.disconnect();
  }
}
