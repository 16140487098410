import { withRouter } from "react-router-dom";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  CreateProps,
} from "react-admin";

const UserCreate = (props: CreateProps) => {
  return (
    <Create title="Create a user" {...props}>
      <SimpleForm>
        <TextInput source="name" required />
        <TextInput source="email" type="email" required />
        <SelectInput
          source="role"
          choices={[
            { id: "admin", name: "admin" },
            { id: "client", name: "client" },
          ]}
          required
        />
        <PasswordInput source="password" required />
      </SimpleForm>
    </Create>
  );
};

export default withRouter(UserCreate);
