export interface HttpProvider {
  request<T>(params: HttpRequestParams): Promise<HttpRequestResponse<T>>;
}

export interface HttpRequestParams {
  method: HttpMethod;
  url: string;
  body?: any;
  headers?: Record<string, string>;
}

export type HttpRequestResponse<T> = {
  body: T | null;
  headers: Record<string, string>;
  status: number;
};

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export class HttpError extends Error {
  constructor(public message: string, public status: number) {
    super(message);
  }
}
