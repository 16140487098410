import { PrintingServiceImpl } from "../../application/services/impl/PrintingServiceImpl";
import { PrintingService } from "../../application/services/PrintingService";
import { userRepositories } from "./repositories";

export interface UserServices {
  printing: PrintingService;
}

const printing = new PrintingServiceImpl(userRepositories.printer);

export const userServices: UserServices = {
  printing,
};
