import { AdminFirmwareMapper } from "../data/mappers/AdminFirmwareMapper";
import { AdminMapper } from "../data/mappers/AdminMapper";
import { AdminPrinterMapper } from "../data/mappers/AdminPrinterMapper";
import { AdminSettingsMapper } from "../data/mappers/AdminSettingsMapper";
import { AdminTissueModelMapper } from "../data/mappers/AdminTissueModelMapper";
import { AdminUserMapper } from "../data/mappers/AdminUserMapper";

import { AdminFirmwareMapperImpl } from "../data/mappers/impl/AdminFirmwareMapperImpl";
import { AdminMapperImpl } from "../data/mappers/impl/AdminMapperImpl";
import { AdminPrinterMapperImpl } from "../data/mappers/impl/AdminPrinterMapperImpl";
import { AdminSettingsMapperImpl } from "../data/mappers/impl/AdminSettingsMapperImpl";
import { AdminTissueModelMapperImpl } from "../data/mappers/impl/AdminTissueModelMapperImpl";
import { AdminUserMapperImpl } from "../data/mappers/impl/AdminUserMapperImpl";

export interface AdminMappers {
  admin: AdminMapper;
  printer: AdminPrinterMapper;
  firmware: AdminFirmwareMapper;
  settings: AdminSettingsMapper;
  tissueModel: AdminTissueModelMapper;
  user: AdminUserMapper;
}

const admin = new AdminMapperImpl();
const printer = new AdminPrinterMapperImpl();
const firmware = new AdminFirmwareMapperImpl();
const settings = new AdminSettingsMapperImpl();
const tissueModel = new AdminTissueModelMapperImpl();
const user = new AdminUserMapperImpl(printer);

export const adminMappers: AdminMappers = {
  admin,
  printer,
  firmware,
  settings,
  tissueModel,
  user,
};
