export type ViewModelStateChangeCallback<T> = (state: Readonly<T>) => void;

export class ViewModel<T extends object> {
  protected state: Readonly<T>;
  private _stateChangeCallback: ViewModelStateChangeCallback<T> | null = null;

  constructor(state: Readonly<T>) {
    this.state = Object.freeze({ ...state });
  }

  public setStateChangeCallback(callback: ViewModelStateChangeCallback<T>) {
    if (this._stateChangeCallback) {
      throw new Error("ViewModel already has a state change callback.");
    }

    this._stateChangeCallback = callback;
    this._stateChangeCallback(this.state);
  }

  protected changeState(state: Readonly<T>) {
    this.state = Object.freeze({ ...state });
    this._stateChangeCallback?.(this.state);
  }
}
