import React from "react";
import { Button, Card, Dropdown, DropdownButton, Form } from "react-bootstrap";

import Section from "../../../components/shared/section";

import { UserPrinter } from "../../../../../../application/models/UserPrinter";
import { SyringePrimingControls } from "../../../components/shared/SyringePrimingControls";
import { PrintingStatus } from "../../../../models/PrintingStatus";
import { PrinterConnection } from "../../../../../../application/data/connections/PrinterConnection";

interface ControlSectionProps {
  printers: UserPrinter[];
  selectedPrinter: UserPrinter | null;
  priming: number;
  disableMovement: boolean;
  isPrintingViaServerMode: boolean;
  printingStatus: PrintingStatus;
  printerConnection: PrinterConnection | null;
  isSerialConnectionSupported: boolean;
  onDisconnect: () => void;
  onConnect: () => void;
  onMoveSyringeUp: () => void;
  onMoveSyringeDown: () => void;
  setPriming: (value: number) => void;
  updateSelectedPrinter: (selected: UserPrinter) => void;
  updatePrintingMode: (selected: boolean) => void;
}

export const ControlSection: React.FC<ControlSectionProps> = ({
  priming,
  printingStatus,
  printerConnection,
  printers,
  selectedPrinter,
  isPrintingViaServerMode,
  isSerialConnectionSupported,
  setPriming,
  onMoveSyringeDown,
  onMoveSyringeUp,
  onDisconnect,
  onConnect,
  updatePrintingMode,
  updateSelectedPrinter,
}) => {
  return (
    <Section>
      <ConnectionSection
        printers={printers}
        selectedPrinter={selectedPrinter}
        isConnected={printingStatus !== "disconnected"}
        isPrintingViaServer={isPrintingViaServerMode}
        isSerialConnectionSupported={isSerialConnectionSupported}
        onConnect={() => onConnect()}
        onDisconnect={() => onDisconnect()}
        onSelectedPrinterChange={(selected) => updateSelectedPrinter(selected)}
        osPrintingViaServerChange={(option) => updatePrintingMode(option)}
      />

      {printerConnection !== null && (
        <div
          className="printer-control"
          style={{
            color: "gray",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SyringePrimingControls
            onPrimingChange={(value) => setPriming(value)}
            priming={priming}
            onSyringeUp={() => onMoveSyringeUp()}
            onSyringeDown={() => onMoveSyringeDown()}
          />
        </div>
      )}
    </Section>
  );
};

interface ConnectionSectionProps {
  printers: UserPrinter[];
  selectedPrinter: UserPrinter | null;
  isConnected: boolean;
  isPrintingViaServer: boolean;
  isSerialConnectionSupported: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  onSelectedPrinterChange: (selectedPrinter: UserPrinter) => void;
  osPrintingViaServerChange: (isPrintingViaServer: boolean) => void;
}

const ConnectionSection: React.FC<ConnectionSectionProps> = ({
  printers,
  selectedPrinter,
  isConnected,
  isPrintingViaServer,
  isSerialConnectionSupported,
  onConnect,
  onDisconnect,
  onSelectedPrinterChange,
  osPrintingViaServerChange,
}) => {
  if (isConnected) {
    return (
      <Button variant="danger" onClick={onDisconnect}>
        Disconnect
      </Button>
    );
  }

  return (
    <>
      <Card style={{ width: "100%", padding: "1vw" }}>
        <Form>
          <Form.Group>
            <Form.Label>Connection type</Form.Label>
            <DropdownButton
              id="connection-type-dropdown"
              variant="secondary"
              title={isPrintingViaServer ? "WiFi" : "USB"}
              className="forms-dropdown"
            >
              <Dropdown.Item onClick={() => osPrintingViaServerChange(false)}>
                USB
                {isSerialConnectionSupported
                  ? ""
                  : " (not supported in this browser)"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => osPrintingViaServerChange(true)}>
                WiFi
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>

          {isPrintingViaServer && (
            <Form.Group>
              <Form.Label>Printer</Form.Label>
              <DropdownButton
                id="printers-dropdown"
                variant="secondary"
                title={
                  selectedPrinter
                    ? `TissueStart - ${selectedPrinter.macAddress}`
                    : "Select a printer"
                }
                className="forms-dropdown"
              >
                {printers.map((printer) => (
                  <Dropdown.Item
                    key={printer.id}
                    onClick={() => onSelectedPrinterChange(printer)}
                  >
                    TissueStart - {printer.macAddress} (
                    {printer.isOnline ? "online" : "offline"})
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
          )}
        </Form>
      </Card>
      <Button variant="light" onClick={onConnect}>
        Connect
      </Button>
    </>
  );
};
