import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  ReferenceInput,
  FormDataConsumer,
  EditProps,
  SelectArrayInput,
} from "react-admin";

import { settingsTypes } from "../../../../../../shared/application/models/SettingsType";
import { AdminPrinter } from "../../../../../application/models/AdminPrinter";
import { adminContainer } from "../../../../di/container";

const UserEdit = (props: EditProps) => {
  const [printers, setPrinters] = useState<AdminPrinter[]>([]);

  useEffect(() => {
    const loadPrinters = async () => {
      try {
        setPrinters(await adminContainer.repositories.printer.findAll());
      } catch (err) {
        console.error(err);
      }
    };

    loadPrinters();
  }, []);

  return (
    <Edit title="Edit user" {...props}>
      <SimpleForm>
        <TextInput source="name" required />
        <SelectInput
          source="role"
          choices={[
            { id: "admin", name: "admin" },
            { id: "client", name: "client" },
            { id: "owner", name: "owner" },
          ]}
          required
        />
        <FormDataConsumer>
          {() =>
            settingsTypes.map((type) => (
              <ReferenceInput
                source={`settings.${type}`}
                label={type}
                reference="settings"
                filter={{ type }}
              >
                <SelectInput optionText="name" optionValue="id" required />
              </ReferenceInput>
            ))
          }
        </FormDataConsumer>
        <SelectArrayInput
          source="printers"
          choices={printers.map((e) => ({
            id: e.id,
            name: `MAC ${e.macAddress}`,
          }))}
        />
        <PasswordInput
          source="password"
          label="new password"
          autoComplete="off"
          required
        />
      </SimpleForm>
    </Edit>
  );
};

export default withRouter(UserEdit);
