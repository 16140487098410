import { Button, ProgressBar } from "react-bootstrap";
import { PrintingStatus } from "../../../models/PrintingStatus";

type FloatActionsProps = {
  printProgress: number;
  printStatus: PrintingStatus;
  onPlay: () => void;
  onPause: () => void;
  onResume: () => void;
  onCancel: () => void;
};

export const FloatActions = ({
  printProgress,
  printStatus,
  onPlay,
  onPause,
  onResume,
  onCancel,
}: FloatActionsProps) => {
  if (printStatus === "disconnected") return null;

  const verifyPrintStatus = () => {
    if (printStatus === "connected") {
      return {
        title: "print ▶",
        onClick: () => onPlay(),
      };
    }

    if (printStatus === "printing") {
      return {
        title: "pause ❚❚",
        onClick: () => onPause(),
      };
    }

    if (printStatus === "paused") {
      return {
        title: "resume ▶",
        onClick: () => onResume(),
      };
    }

    return {
      title: "set home and z to 0",
      onClick: () => {},
      disabled: true,
    };
  };

  const { title, onClick, disabled } = verifyPrintStatus();

  const showCancel = printStatus === "printing" || printStatus === "paused";

  return (
    <div
      style={{
        position: "fixed",
        top: "1rem",
        left: "29rem",
        background: "#ff80ae",
        boxShadow: "darkred",
        padding: "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <Button onClick={onClick} variant="light" disabled={!!disabled}>
          {title}
        </Button>

        {showCancel && (
          <Button onClick={() => onCancel()} variant="light" disabled={false}>
            cancel
          </Button>
        )}

        <div style={{ width: "12rem" }}>
          <p
            style={{
              margin: 0,
              color: "#ffffff",
            }}
          >
            print progress {printProgress.toFixed(2)} %
          </p>
          <ProgressBar now={printProgress} />
        </div>
      </div>
    </div>
  );
};
