import React, { FormEvent } from "react";
import { Button } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { loadModel } from "../../../../data/network/apiServer";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";

interface UploadBtnProps extends AppState, DispatchProp {
  handleModel: (model: any, response: any) => void;
}

interface UploadBtnState {
  file: any;
  isHovering: boolean;
}

class UploadBtn extends React.Component<UploadBtnProps, UploadBtnState> {
  constructor(props: UploadBtnProps) {
    super(props);
    this.state = {
      file: null,
      isHovering: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async onFormSubmit(e: FormEvent) {
    e.preventDefault();

    if (!this.state.file) return;

    this.props.dispatch({ type: "project/wait" });

    try {
      const [extension, ...nameWords] = this.state.file.name
        .split(".")
        .reverse();
      const name = nameWords.join(".");
      const content = await this.state.file.arrayBuffer();

      const response = await userContainer.repositories.projectModel.create({
        projectId: parseInt(this.props.projectId),
        name,
        file: {
          content,
          extension,
        },
      });

      const model = await loadModel(response.fileAddress);
      this.props.handleModel(model, response);
      this.props.dispatch({ type: "project/save" });
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    } finally {
      this.props.dispatch({ type: "project/doneWait" });
    }
  }

  onChange(e: any) {
    let re = /.+\.((stl)|(STL)|(ply)|(PLY)|(obj)|(OBJ)|(amf)|(AMF))/;
    let file = e.target.files[0];
    let isValidFormat = re.exec(file.name);
    if (isValidFormat) this.setState({ file });
    else
      Popup.message(this.props.dispatch, `${file.name} is not a valid format`);
  }

  handleUploadEmpty() {
    Popup.message(
      this.props.dispatch,
      "browse and select your object file first",
    );
  }

  render() {
    if (this.props.isProjectWaiting) return [];

    let uploadBtn: React.ReactElement | null = null;
    if (this.state.file) {
      uploadBtn = (
        <Button type="submit" variant="primary">
          upload
        </Button>
      );
    } else {
      uploadBtn = (
        <Button onClick={() => this.handleUploadEmpty()} variant="primary">
          upload
        </Button>
      );
    }
    return (
      <form
        onSubmit={this.onFormSubmit}
        className="upload-form"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",
          gap: "0.5vw",
          padding: "1vw",
        }}
      >
        <label
          className="browse-link"
          style={{
            backgroundColor: "white",
            color: this.state.isHovering ? "darkGray" : "gray",
            cursor: "pointer",
            border: "1px solid gray",
            borderRadius: "4px",
            padding: "0.5rem",
          }}
          onMouseEnter={() => this.setState({ isHovering: true })}
          onMouseLeave={() => this.setState({ isHovering: false })}
        >
          <input
            className="browse-file"
            type="file"
            onChange={this.onChange}
            style={{
              position: "fixed",
              right: "100%",
              bottom: "100%",
              visibility: "hidden",
            }}
          />
          browse ...
        </label>
        {this.state.file && this.state.file.name.length < 12 && (
          <label>{this.state.file.name}</label>
        )}
        {this.state.file && this.state.file.name.length >= 12 && (
          <label>...{this.state.file.name.slice(-9)}</label>
        )}

        <pre> </pre>
        {uploadBtn}
      </form>
    );
  }
}

export default connect(mapStateToProps)(UploadBtn);
