import { UserPrinterRepository } from "../../data/repositories/UserPrinterRepository";
import {
  PrintingServiceProgressCallback,
  PrintingService,
  PrintingServiceProgress,
} from "../PrintingService";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export class PrintingServiceImpl implements PrintingService {
  private _isPaused = false;
  private _isCancelled = false;

  private currentGcodes: string[] = [];
  private commandGcodesQueue: string[][] = [];
  private printingGcodesQueue: string[] = [];
  private isPrinterReadyToReceive: boolean = true;
  private printingGcodeIndex: number = 0;

  private progressCallback: PrintingServiceProgressCallback | null = null;

  constructor(private printerRepository: UserPrinterRepository) {}

  async start(): Promise<void> {
    this._isPaused = false;
    this._isCancelled = false;

    while (!this._isCancelled) {
      if (this.skipLoop()) {
        await delay(100);
        continue;
      }

      if (this.currentGcodes.length === 0) {
        this.currentGcodes = this.getCommand();
      }

      const current = this.currentGcodes.shift();

      this.printerRepository.send(current!);
      this.isPrinterReadyToReceive = false;
      this.progressCallback?.(this.getProgress());
    }
  }

  async resume(): Promise<void> {
    this._isPaused = false;
  }

  async pause(): Promise<void> {
    this._isPaused = true;
  }

  async reset(): Promise<void> {
    this._isPaused = false;
    this._isCancelled = false;

    this.currentGcodes = [];
    this.commandGcodesQueue = [];
    this.printingGcodesQueue = [];
    this.isPrinterReadyToReceive = true;
    this.printingGcodeIndex = 0;
  }

  async sendCommands(gcodes: string[]): Promise<void> {
    this.commandGcodesQueue = [gcodes];
  }

  async load(printingGcodesQueue: string[]): Promise<void> {
    this.printingGcodesQueue = printingGcodesQueue;
  }

  async printerReadyToReceive(): Promise<void> {
    this.isPrinterReadyToReceive = true;
  }

  async setPrintServiceProgressCallback(
    cb: PrintingServiceProgressCallback,
  ): Promise<void> {
    this.progressCallback = cb;
  }

  private skipLoop(): boolean {
    if (this._isPaused) {
      return true;
    }

    if (!this.isPrinterReadyToReceive) {
      return true;
    }

    if (
      this.printingGcodeIndex >= this.printingGcodesQueue.length &&
      this.commandGcodesQueue.length === 0 &&
      this.currentGcodes.length === 0
    ) {
      return true;
    }

    return false;
  }

  private getCommand(): string[] {
    if (this.commandGcodesQueue.length) {
      return this.commandGcodesQueue.shift()!;
    }

    if (this.printingGcodesQueue.length > this.printingGcodeIndex) {
      const gcode = this.printingGcodesQueue[this.printingGcodeIndex];

      this.printingGcodeIndex += 1;
      return [gcode];
    }

    return [];
  }

  private getProgress(): PrintingServiceProgress {
    return {
      total: this.printingGcodesQueue.length,
      current: this.printingGcodeIndex,
    };
  }
}
