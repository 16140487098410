import { AuthProvider, DataProvider } from "react-admin";

import { AdminAuthProviderImpl } from "../data/reactAdmin/providers/AdminAuthProviderImpl";
import { AdminDataProviderImpl } from "../data/reactAdmin/providers/AdminDataProviderImpl";
import { AdminSettingsProviderImpl } from "../data/reactAdmin/providers/AdminSettingsProviderImpl";
import { AdminUserProviderImpl } from "../data/reactAdmin/providers/AdminUserProviderImpl";
import { AdminTissueModelProviderImpl } from "../data/reactAdmin/providers/AdminTissueModelProviderImpl";
import { AdminFirmwareProviderImpl } from "../data/reactAdmin/providers/AdminFirmwareProviderImpl";

import { adminRepositories } from "./repositories";
import { adminProviders } from "./providers";
import { AdminPrinterProviderImpl } from "../data/reactAdmin/providers/AdminPrinterProviderImpl";

export interface ReactAdminProviders {
  auth: AuthProvider;
  data: DataProvider;
}

const auth = new AdminAuthProviderImpl(
  adminRepositories.admin,
  adminProviders.tokenStorage
);

const data = new AdminDataProviderImpl(
  new AdminFirmwareProviderImpl(
    adminProviders.firmwareFileParser,
    adminRepositories.firmware
  ),
  new AdminPrinterProviderImpl(adminRepositories.printer),
  new AdminSettingsProviderImpl(adminRepositories.settings),
  new AdminUserProviderImpl(adminRepositories.user),
  new AdminTissueModelProviderImpl(
    adminProviders.file,
    adminRepositories.tissueModel
  )
);

export const reactAdminProviders = {
  auth,
  data,
};
