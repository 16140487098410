import { withRouter } from "react-router-dom";
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const SettingsCreate = (props: CreateProps) => {
  return (
    <Create title="Create a setting" {...props}>
      <SimpleForm>
        <TextInput source="name" required />
        <SelectInput
          source="type"
          choices={[
            { id: "tissuestart", name: "tissuestart" },
            { id: "pipettestart", name: "pipettestart" },
          ]}
          required
        />
      </SimpleForm>
    </Create>
  );
};

export default withRouter(SettingsCreate);
