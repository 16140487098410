import { withRouter } from "react-router-dom";
import {
  Create,
  CreateProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { adminPrinterModels } from "../../../../../application/models/AdminPrinter";

const PrinterCreate = (props: CreateProps) => {
  return (
    <Create title="Add a printer" {...props}>
      <SimpleForm>
        <SelectInput
          source="model"
          choices={adminPrinterModels.map((model) => ({
            id: model,
            name: model,
          }))}
          required
        />
        <TextInput source="macAddress" required />
      </SimpleForm>
    </Create>
  );
};

export default withRouter(PrinterCreate);
