import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import {
  UserTissueModelCopyParams,
  UserTissueModelRepositor,
} from "../../../application/data/repositories/UserTissueModelRepository";
import { UserProjectModel } from "../../../application/models/UserProjectModel";
import {
  UserTissueModel,
  UserTissueModelFolder,
} from "../../../application/models/UserTissueModel";
import { UserProjectModelMapper } from "../mappers/UserProjectModelMapper";
import { UserTissueModelMapper } from "../mappers/UserTissueModelMapper";
import { UserProjectModelRaw } from "../models/UserProjectModelRaw";
import {
  UserTissueModelFolderRaw,
  UserTissueModelRaw,
} from "../models/UserTissueModelRaw";

export class UserTissueModelRepositoryImpl implements UserTissueModelRepositor {
  constructor(
    private httpProvider: HttpProvider,
    private userProjectModelMapper: UserProjectModelMapper,
    private userTissueModelMapper: UserTissueModelMapper
  ) {}

  async findAllFolders(): Promise<UserTissueModelFolder[]> {
    const response = await this.httpProvider.request<
      UserTissueModelFolderRaw[]
    >({
      method: "GET",
      url: "/tissue-model/folders",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userTissueModelMapper.userTissueModelFolderRawToUserTissueModelFolder(
          e
        )
      )
    );
  }

  async findAllByFolder(folder: string): Promise<UserTissueModel[]> {
    const response = await this.httpProvider.request<UserTissueModelRaw[]>({
      method: "GET",
      url: `/tissue-model/folders/${folder}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userTissueModelMapper.userTissueModelRawToUserTissueModel(e)
      )
    );
  }

  async copy(params: UserTissueModelCopyParams): Promise<UserProjectModel> {
    const response = await this.httpProvider.request<UserProjectModelRaw>({
      method: "POST",
      url: "/tissue-model/copy",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectModelMapper.userProjectModelRawToUserProjectModel(
      response.body
    );
  }
}
