import { AdminFirmwareRepository } from "../../application/data/repositories/AdminFirmwareRepository";
import { AdminRepository } from "../../application/data/repositories/AdminRepository";
import { AdminSettingsRepository } from "../../application/data/repositories/AdminSettingsRepository";
import { AdminTissueModelRepository } from "../../application/data/repositories/AdminTissueModelRepository";
import { AdminUserRepository } from "../../application/data/repositories/AdminUserRepository";

import { AdminFirmwareRepositoryImpl } from "../data/repositories/AdminFirmwareRepositoryImpl";
import { AdminPrinterRepositoryImpl } from "../data/repositories/AdminPrinterRepositoryImpl";
import { AdminRepositoryImpl } from "../data/repositories/AdminRepositoryImpl";
import { AdminSettingsRepositoryImpl } from "../data/repositories/AdminSettingsRepositoryImpl";
import { AdminTissueModelRepositoryImpl } from "../data/repositories/AdminTissueModelRepositoryImpl";
import { AdminUserRepositoryImpl } from "../data/repositories/AdminUserRepositoryImpl";

import { adminMappers } from "./mappers";
import { adminProviders } from "./providers";

export interface AdminRepositories {
  admin: AdminRepository;
  firmware: AdminFirmwareRepository;
  printer: AdminPrinterRepositoryImpl;
  settings: AdminSettingsRepository;
  tissueModel: AdminTissueModelRepository;
  user: AdminUserRepository;
}

const admin = new AdminRepositoryImpl(adminProviders.http, adminMappers.admin);

const firmware = new AdminFirmwareRepositoryImpl(
  adminProviders.http,
  adminMappers.firmware
);

const printer = new AdminPrinterRepositoryImpl(
  adminProviders.http,
  adminMappers.printer
);

const settings = new AdminSettingsRepositoryImpl(
  adminProviders.http,
  adminMappers.settings
);

const tissueModel = new AdminTissueModelRepositoryImpl(
  adminProviders.http,
  adminMappers.tissueModel
);

const user = new AdminUserRepositoryImpl(
  adminProviders.http,
  adminMappers.user
);

export const adminRepositories: AdminRepositories = {
  admin,
  firmware,
  printer,
  settings,
  tissueModel,
  user,
};
