import React from "react";
import { DispatchProp, connect } from "react-redux";

import { Popup } from "../../../redux/Popup";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";

interface GcodeConfigProps extends AppState, DispatchProp {
  gcodeId: number;
}

interface GcodeConfigState {
  inkVolume: Record<string, number> | null;
  speed: number | null;
}

class GcodeConfig extends React.Component<GcodeConfigProps, GcodeConfigState> {
  constructor(props: GcodeConfigProps) {
    super(props);
    this.state = {
      inkVolume: null,
      speed: null,
    };
  }

  handleGetInfo = async () => {
    try {
      const gcode =
        await userContainer.repositories.projectGcode.findByProjectIdAndGcodeId(
          {
            projectId: parseInt(this.props.projectId),
            gcodeId: this.props.gcodeId,
          }
        );

      this.setState(gcode.details);
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    }
  };

  componentDidMount() {
    this.handleGetInfo();
  }

  render() {
    let inkSection: any[] = [];
    let speedSection: React.ReactElement | null = null;
    const volumes = this.state.inkVolume;

    if (volumes) {
      inkSection = Object.keys(volumes).map((key, index) => {
        let selectedTool = parseInt(key);
        let tool;

        if (Number.isInteger(selectedTool))
          tool = this.props.tools[selectedTool].label;
        else tool = key;

        return (
          <p key={index}>
            ink needed for {tool} tool: {volumes[key].toFixed(2)} ml
          </p>
        );
      });
    }

    if (Number.isFinite(this.state.speed)) {
      speedSection = <p>speed: {this.state?.speed?.toFixed(2) || ""} mm/s</p>;
    }

    return (
      <div>
        {inkSection}
        {speedSection}
      </div>
    );
  }
}

export default connect(mapStateToProps)(GcodeConfig);
