import {
  Admin,
  AdminAndToken,
  AdminRole,
  adminRoles,
} from "../../../../application/models/Admin";
import { AdminAndTokenRaw, AdminRaw } from "../../models/AdminRaw";
import { AdminMapper } from "../AdminMapper";

export class AdminMapperImpl implements AdminMapper {
  async adminRawToAdmin(adminRaw: AdminRaw): Promise<Admin> {
    if (!adminRoles.includes(adminRaw.role as AdminRole)) {
      throw new Error("Invalid admin role.");
    }

    return {
      id: adminRaw.id,
      email: adminRaw.email,
      name: adminRaw.name,
      role: adminRaw.role as AdminRole,
      createdAt: new Date(adminRaw.createdAt),
      updatedAt: new Date(adminRaw.updatedAt),
    };
  }

  async adminAndTokenRawToAdminAndToken(
    adminWithTokenRaw: AdminAndTokenRaw
  ): Promise<AdminAndToken> {
    return {
      token: adminWithTokenRaw.token,
      admin: await this.adminRawToAdmin(adminWithTokenRaw.admin),
    };
  }
}
