import { withRouter } from "react-router-dom";
import {
  Edit,
  SimpleFormIterator,
  TextInput,
  ArrayInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  EditProps,
} from "react-admin";

const SettingsEdit = (props: EditProps) => {
  return (
    <Edit title="Edit settings" {...props}>
      <TabbedForm>
        <FormTab label="tools">
          <ArrayInput source="tool.options" label="tools">
            <SimpleFormIterator>
              <TextInput source="label" label="tool name" />
              <TextInput source="id" label="id" />
              <TextInput source="offset.x" label="offset x" />
              <TextInput source="offset.y" label="offset y" />
              <TextInput source="offset.z" label="offset z" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="medias">
          <ArrayInput source="media.options" label="medias">
            <SimpleFormIterator>
              <TextInput source="label" label="media name" />
              <TextInput
                source="center.x"
                label="media/upper left corner well center x position"
              />
              <TextInput
                source="center.y"
                label="media/upper left corner well center y position"
              />
              <TextInput
                source="gap.x"
                label="x distance between wells center"
              />
              <TextInput
                source="gap.y"
                label="y distance between wells center"
              />
              <TextInput source="columns" label="number of columns" />
              <TextInput source="rows" label="number of rows" />
              <TextInput source="height" label="media height" />
              <TextInput source="lengths" label="media lenght, width" />
              <TextInput source="ray" label="media ray" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="slicer">
          <ArrayInput source="slicer.options" label="slicer parameters">
            <SimpleFormIterator>
              <TextInput source="label" label="label" />
              <TextInput source="id" label="cmd (check slic3r docs)" />
              <SelectInput
                source="inputType"
                label="type"
                choices={[
                  { id: "plain-text", name: "plain text" },
                  { id: "drop-box", name: "drop box" },
                  { id: "copy", name: "copy value" },
                  { id: "fixed", name: "fixed value" },
                ]}
              />
              <BooleanInput
                source="isAdvanced"
                label="is it an advanced parameter?"
              />
              <TextInput source="tips" label="tips for the user" />
              <TextInput source="multiplier" label="multiplication factor" />
              <ArrayInput source="options" label="choices">
                <SimpleFormIterator>
                  <TextInput source="label" label="label" />
                  <TextInput
                    source="id"
                    label="cmd (if plain text type default value, if fixed value type value, if copy type multiplication factor)"
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="pipette">
          <ArrayInput source="pipette" label="pipette operations">
            <SimpleFormIterator>
              <TextInput source="label" label="label" />
              <TextInput source="id" label="id" />
              <ArrayInput source="options" label="parameters">
                <SimpleFormIterator>
                  <TextInput source="label" label="label" />
                  <TextInput source="id" label="id" />
                  <SelectInput
                    source="inputType"
                    label="type"
                    choices={[
                      {
                        id: "plain-text",
                        name: "plain text",
                      },
                      {
                        id: "drop-box",
                        name: "drop box",
                      },
                      {
                        id: "fixed",
                        name: "fixed value",
                      },
                      { id: "wells", name: "wells" },
                      {
                        id: "mixtrusor",
                        name: "mixtrusor",
                      },
                    ]}
                  />
                  <TextInput source="tips" label="tips for the user" />
                  <ArrayInput source="options" label="choices">
                    <SimpleFormIterator>
                      <TextInput source="label" label="label" />
                      <TextInput source="id" label="value" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="storage">
          <NumberInput
            source="objects.maxNumber"
            step={1}
            label="max number of objects"
          />
          <NumberInput
            source="gcodes.maxNumber"
            step={1}
            label="max number of experiments"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default withRouter(SettingsEdit);
