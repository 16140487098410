import { PrinterReducer } from ".";
import { PipetteState, PipetteStep } from "../../models/PipetteState";

export const pipetteState0: PipetteState = {
  pipetteCycles: [],
  pipetteForms: [],
};

export const createPipetteForms: PrinterReducer = (state, action) => {
  let pipetteForms = action.pipetteForms;

  return { ...state, pipetteForms };
};

export const fillPipetteForms: PrinterReducer = (state, action) => {
  let pipetteCycles = action.pipetteCycles;

  if (!pipetteCycles) {
    return createCycle({ ...state, pipetteCycles: [] }, {} as any);
  } else {
    return { ...state, pipetteCycles };
  }
};

export const createCycle: PrinterReducer = (state, action) => {
  let newCycle = {
    repeat: 1,
    steps: [
      {
        operation: "",
      },
    ] as PipetteStep[],
  };

  let pipetteCycles = [...state.pipetteCycles, newCycle];
  return { ...state, pipetteCycles };
};

export const createStep: PrinterReducer = (state, action) => {
  const pipetteCycles = [...state.pipetteCycles];
  const newStep: PipetteStep = {
    operation: "",
  };

  const cycleIndex = action.cycleIndex;
  const steps = [...pipetteCycles[cycleIndex].steps, newStep];
  pipetteCycles[cycleIndex].steps = steps;
  return { ...state, pipetteCycles };
};

export const deleteCycle: PrinterReducer = (state, action) => {
  let pipetteCycles = [...state.pipetteCycles];
  pipetteCycles.splice(action.cycleIndex, 1);
  return { ...state, pipetteCycles };
};

export const deleteStep: PrinterReducer = (state, action) => {
  let pipetteCycles = [...state.pipetteCycles];
  let steps = [...pipetteCycles[action.cycleIndex].steps];
  steps.splice(action.stepIndex, 1);
  pipetteCycles[action.cycleIndex].steps = steps;
  return { ...state, pipetteCycles };
};

export const changeOperation: PrinterReducer = (state, action) => {
  let pipetteCycles = [...state.pipetteCycles];
  (pipetteCycles as any)[action.cycleIndex].steps[action.stepIndex][
    action.stepParameter
  ] = action.value;
  return { ...state, pipetteCycles };
};

export const editParameters: PrinterReducer = (state, action) => {
  let pipetteCycles = [...state.pipetteCycles];
  let step = pipetteCycles[action.cycleIndex].steps[action.stepIndex];
  step = { ...step, ...action.data };
  pipetteCycles[action.cycleIndex].steps[action.stepIndex] = step;
  return { ...state, pipetteCycles };
};

export const changeRepeat: PrinterReducer = (state, action) => {
  let pipetteCycles = [...state.pipetteCycles];
  pipetteCycles[action.cycleIndex].repeat = action.repeat;
  return { ...state, pipetteCycles };
};

export const selectWellsforPipette: PrinterReducer = (state, action) => {
  let cycleIndex = action.cycleIndex;
  let stepIndex = action.stepIndex;
  let selected = action.selected;
  let selectedWells =
    (state.pipetteCycles[cycleIndex]?.steps[stepIndex] as any)?.selectedWells ||
    [];

  if (selectedWells.includes(selected)) {
    selectedWells = selectedWells.filter((well: any) => well !== selected);
  } else {
    selectedWells.push(selected);
  }

  return changeOperation(state, {
    value: selectedWells,
    cycleIndex,
    stepIndex,
    stepParameter: "selectedWells",
  });
};
