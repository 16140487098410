import React from "react";
import { Button, Card } from "react-bootstrap";
import Radio from "./radio";

interface SyringePrimingControlsProps {
  disable?: boolean;
  priming: number;
  onPrimingChange: (led: number) => void;
  onSyringeUp: () => void;
  onSyringeDown: () => void;
}

export const SyringePrimingControls: React.FC<SyringePrimingControlsProps> = ({
  disable = false,
  priming,
  onSyringeUp,
  onSyringeDown,
  onPrimingChange,
}) => {
  const handleSyringeUp = () => {
    onSyringeUp();
  };

  const handleSyringeDown = () => {
    onSyringeDown();
  };

  return (
    <Card
      className="light-card"
      style={{
        gridColumnStart: "2",
        gridColumnEnd: "3",
        gap: "1vw",
        padding: "1vw",
      }}
    >
      <p>syringe priming</p>

      <div
        className="control-pad"
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <div className="up-arrow">
            <Button
              onClick={() => handleSyringeUp()}
              variant="secondary"
              disabled={disable}
            >
              &#9652;
            </Button>
          </div>
          <div className="down-arrow">
            <Button
              onClick={() => handleSyringeDown()}
              variant="secondary"
              disabled={disable}
            >
              &#9662;
            </Button>
          </div>
        </div>
        <Radio
          radios={[
            { name: 10, value: 10 },
            { name: 25, value: 25 },
            { name: 50, value: 50 },
            { name: 100, value: 100 },
          ]}
          selected={priming}
          onSelect={(selected) => onPrimingChange(Number(selected))}
          variant="secondary"
          disabled={disable}
        />
        <p style={{ margin: 0 }}>(µL)</p>
      </div>
    </Card>
  );
};
