import { TokenStorageProvider } from "../../../../../../shared/framework/providers/TokenStorageProvider";
import { UserProjectRepository } from "../../../../../application/data/repositories/UserProjectRepository";
import { UserRepository } from "../../../../../application/data/repositories/UserRepository";
import { User } from "../../../../../application/models/User";
import {
  UserProject,
  UserProjectType,
} from "../../../../../application/models/UserProject";
import { ViewModel } from "../../common/ViewModel";

export interface HomeViewModelState {
  isAuthenticated: boolean;
  user: User | null;
  projects: UserProject[];
  openProject: UserProject | null;
  projectsType: UserProjectType;
  showNewProjectModal: boolean;
  newProjectName: string;
  showUserProfileModal: boolean;
  userProfile: HomeUserProfileData;
  errorMessage: string | null;
}

export interface HomeUserProfileData {
  newName: string;
  newPassword: string;
  oldPassword: string;
}

export class HomeViewModel extends ViewModel<HomeViewModelState> {
  constructor(
    private tokenStorageProvider: TokenStorageProvider,
    private userRepository: UserRepository,
    private userProjectRepository: UserProjectRepository,
    state: HomeViewModelState
  ) {
    super(state);
    this.init();
  }

  selectProjectType(type: UserProjectType) {
    this.changeState({ ...this.state, projects: [], projectsType: type });
    this.fetchProjects();
  }

  updateNewProjectName(name: string) {
    this.changeState({ ...this.state, newProjectName: name });
  }

  updateNewProjectModalVisibility(show: boolean) {
    this.changeState({
      ...this.state,
      showNewProjectModal: show,
      newProjectName: "",
      errorMessage: null,
    });
  }

  openProject(project: UserProject) {
    this.changeState({ ...this.state, openProject: project });
  }

  async createProject() {
    try {
      await this.userProjectRepository.create({
        name: this.state.newProjectName,
        type: this.state.projectsType,
      });
      this.updateNewProjectModalVisibility(false);
      await this.fetchProjects();
    } catch (err: unknown) {
      const message =
        err instanceof Error ? err.message : "Failed to created project.";
      this.changeState({ ...this.state, errorMessage: message });
    }
  }

  async deleteProject(id: number) {
    try {
      await this.userProjectRepository.delete(id);
      await this.fetchProjects();
    } catch (err: unknown) {
      const message =
        err instanceof Error ? err.message : "Failed to created project.";
      this.changeState({ ...this.state, errorMessage: message });
    }
  }

  async signOut() {
    try {
      await this.tokenStorageProvider.clear();
      this.changeState({ ...this.state, isAuthenticated: false });
    } catch (err: unknown) {
      const message =
        err instanceof Error ? err.message : "Failed to sign out.";
      this.changeState({ ...this.state, errorMessage: message });
    }
  }

  updateUserProfileData(userProfileData: HomeUserProfileData) {
    this.changeState({ ...this.state, userProfile: userProfileData });
  }

  updateUserProfileModalVisibility(show: boolean) {
    this.changeState({
      ...this.state,
      showUserProfileModal: show,
      userProfile: {
        newName: this.state.user?.name || "",
        newPassword: "",
        oldPassword: "",
      },
      errorMessage: null,
    });
  }

  async saveUserProfile() {
    try {
      const user = await this.userRepository.update({
        name: this.state.userProfile.newName,
        newPassword: this.state.userProfile.newPassword || undefined,
        oldPassword: this.state.userProfile.oldPassword || undefined,
      });

      this.changeState({ ...this.state, user });
      this.updateUserProfileModalVisibility(false);
    } catch (err: unknown) {
      const message =
        err instanceof Error ? err.message : "Failed to update profile.";
      this.changeState({ ...this.state, errorMessage: message });
    }
  }

  private async init() {
    try {
      const profile = await this.userRepository.profile();
      this.changeState({ ...this.state, user: profile });
      await this.fetchProjects();
    } catch (err) {
      await this.tokenStorageProvider.clear();
      this.changeState({ ...this.state, isAuthenticated: false });
    }
  }

  private async fetchProjects() {
    try {
      const projects = await this.userProjectRepository.findAllByType(
        this.state.projectsType
      );

      this.changeState({ ...this.state, projects });
    } catch (err: unknown) {
      const message =
        err instanceof Error ? err.message : "Failed to fetch projects.";
      this.changeState({ ...this.state, errorMessage: message });
    }
  }

  static initialState: Readonly<HomeViewModelState> =
    Object.freeze<HomeViewModelState>({
      isAuthenticated: true,
      user: null,
      projects: [],
      openProject: null,
      projectsType: "tissuestart",
      showNewProjectModal: false,
      newProjectName: "",
      showUserProfileModal: false,
      userProfile: {
        newName: "",
        newPassword: "",
        oldPassword: "",
      },
      errorMessage: null,
    });
}
