import { SettingsType } from "../../../shared/application/models/SettingsType";
import { AdminPrinter } from "./AdminPrinter";

export interface AdminUser {
  id: number;
  email: string;
  name: string;
  role: AdminUserRole;
  createdAt: Date;
  updatedAt: Date;
}

export interface AdminUserWithDetails extends AdminUser {
  printers: AdminPrinter[];
  settings: Record<SettingsType, number | null>;
}

export const adminUserRoles = ["client", "admin", "owner"] as const;

export type AdminUserRole = (typeof adminUserRoles)[number];
