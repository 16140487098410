import { TokenStorageProvider } from "../../../../../../shared/framework/providers/TokenStorageProvider";
import { UserRepository } from "../../../../../application/data/repositories/UserRepository";
import { ViewModel } from "../../common/ViewModel";

export interface SignInViewModelState {
  isAuthenticated: boolean;
  email: string;
  password: string;
  errorMessage: string | null;
}

export class SignInViewModel extends ViewModel<SignInViewModelState> {
  constructor(
    private tokenStorageProvider: TokenStorageProvider,
    private userRepository: UserRepository,
    state: SignInViewModelState
  ) {
    super(state);
    this.checkAuthentication();
  }

  updateEmail(email: string) {
    this.changeState({ ...this.state, email });
  }

  updatePassword(password: string) {
    this.changeState({ ...this.state, password });
  }

  async singIn() {
    try {
      const response = await this.userRepository.signIn({
        email: this.state.email,
        password: this.state.password,
      });

      await this.tokenStorageProvider.set(response.token);

      this.changeState({ ...this.state, isAuthenticated: true });
    } catch (err: unknown) {
      const message = err instanceof Error ? err.message : "Failed to sign in.";
      this.changeState({ ...this.state, errorMessage: message });
    }
  }

  private async checkAuthentication() {
    try {
      await this.userRepository.healthCheck();
      this.changeState({ ...this.state, isAuthenticated: true });
    } catch {}
  }

  static initialState: Readonly<SignInViewModelState> =
    Object.freeze<SignInViewModelState>({
      isAuthenticated: false,
      email: "",
      password: "",
      errorMessage: null,
    });
}
