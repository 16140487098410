import React from "react";
import * as THREE from "three";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DispatchProp, connect } from "react-redux";

import Radio from "../../components/shared/radio";
import { loadGcode, loadModel } from "../../../../data/network/apiServer";
import SectionTool from "../../components/toolSection";
import SectionPipette from "../../components/pipetteSection";
import {
  PRINT_SECTION,
  TISSUESTART_SECTION,
  ROTATION_OFFSET_X,
  PIPETTE_SECTION,
  PIPETTESTART,
  TOOLS_SECTION,
} from "../../../../../../constants";
import Sidebar, {
  SidebarBody,
  SidebarHeader,
  SidebarLogo,
  SidebarTabs,
  SidebarTitle,
} from "../../components/shared/sidebar";
import HelpBtn from "../../components/shared/helpBtn";

import logo from "../../../assets/images/logo-tissuestart.png";
import bed3dModel from "../../../assets/3d-models/bed3D.stl";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";
import { UserProjectGcode } from "../../../../../application/models/UserProjectGcode";
import { ControlSection } from "../pipetteStart/control/ControlSection";
import { PrintSection } from "../pipetteStart/print/PrintSection";
import { SideBarPippeteStartViewModel } from "./SidebarPippeteStartViewModel";
import { FloatActions } from "../../components/shared/FloatActions";
import { ConfirmModal } from "../../components/shared/ConfirmModal";

interface SidebarPipetteParams {
  id: string;
}

interface SidebarPipetteStartProps
  extends RouteComponentProps<SidebarPipetteParams>,
    AppState,
    DispatchProp {}

interface SidebarPipetteStartState {
  selectedTab: string;
  showTutorialTab: string;
  isNewbie: boolean;
}

class SidebarPipetteStart extends React.Component<
  SidebarPipetteStartProps,
  SidebarPipetteStartState
> {
  constructor(props: SidebarPipetteStartProps) {
    super(props);

    this.state = {
      selectedTab: "tools",
      showTutorialTab: "",
      isNewbie: false,
    };
  }

  loadGcodes = async (gcodes: UserProjectGcode[]) => {
    for (var i = 0; i < gcodes.length; i++) {
      const gcode = gcodes[i];
      this.props.dispatch({
        type: "project/loadMessage",
        loadMessage: `... creating gcode ${i + 1}/${gcodes.length}`,
      });

      const content = await loadGcode(gcode.fileAddress);
      if (gcode) {
        this.props.dispatch({
          type: "gcode/create",
          gcode: content,
          file: gcode,
        });
      }
    }
  };

  loadViewer = async () => {
    this.props.dispatch({
      type: "project/loadMessage",
      loadMessage: "... creating 3D bed",
    });

    const bedMaterial = new THREE.MeshLambertMaterial({ color: 0xff80ae });
    let bed: THREE.Mesh | undefined;
    let offset = { x: 0, y: -41.5, z: 0 };

    const bedGeometry = await loadModel(undefined, bed3dModel);
    if (bedGeometry && bedGeometry instanceof THREE.BufferGeometry) {
      bed = new THREE.Mesh(bedGeometry, bedMaterial);
      bed.rotation.set(-ROTATION_OFFSET_X, bed.rotation.y, Math.PI);
    }

    this.props.dispatch({ type: "project/bed", bed, offset });

    const data =
      await userContainer.repositories.projectGcode.findAllByProjectId(
        parseInt(this.props.projectId),
      );
    await this.loadGcodes(data);
  };

  loadSettings = async () => {
    this.props.dispatch({
      type: "project/loadMessage",
      loadMessage: "... preparing environment",
    });

    try {
      const response =
        await userContainer.repositories.settings.findByType("pipettestart");
      const content: any = response.content;

      this.props.dispatch({
        type: "media/create",
        medias: content.media.options,
      });

      this.props.dispatch({
        type: "tool/create",
        tools: content.tool.options,
      });

      this.props.dispatch({
        type: "pipette/create",
        pipetteForms: content.pipette,
      });
    } catch {
      this.props.dispatch({ type: "project/close" });
    }
  };

  loadState = async () => {
    this.props.dispatch({
      type: "project/loadMessage",
      loadMessage: "... recovering state",
    });

    const project = await userContainer.repositories.project.findById(
      parseInt(this.props.projectId),
    );
    const state = project.state as AppState;

    if (project.type === this.props.projectType) {
      let readyToPipette = true;
      let readyToPrint = true;

      if (project.name)
        this.props.dispatch({
          type: "project/rename",
          projectName: project.name,
        });

      if (state.customMedias)
        this.props.dispatch({
          type: "media/create",
          medias: state.customMedias,
          isCustom: true,
        });

      if (state.selectedTool || state.selectedTool === 0)
        this.props.dispatch({
          type: "tool/select",
          selected: state.selectedTool,
        });
      else readyToPipette = false;

      if (state.selectedBrand || state.selectedBrand === 0)
        this.props.dispatch({
          type: "brand/select",
          selected: state.selectedBrand,
        });
      if (state.selectedMedia || state.selectedMedia === 0)
        this.props.dispatch({
          type: "media/select",
          selected: state.selectedMedia,
        });
      else readyToPipette = false;

      this.props.dispatch({
        type: "pipette/fill",
        pipetteCycles: state.pipetteCycles,
      });

      this.props.dispatch({
        type: "gcode/unselectAll",
      });
      if (
        state.selectedGcodes &&
        state.selectedGcodes.length > 0 &&
        (state.selectedGcodes[0] || state.selectedGcodes[0] === 0)
      )
        state.selectedGcodes.forEach((selected: any, index: number) => {
          this.props.dispatch({
            type: "gcode/select",
            selected,
            slot: index,
          });
        });
      else readyToPrint = false;

      if (readyToPrint) this.handleSelectTab(TISSUESTART_SECTION);
      else if (readyToPrint) this.handleSelectTab(PRINT_SECTION);
      else if (readyToPipette) this.handleSelectTab(PIPETTE_SECTION);
      else {
        this.setState({
          isNewbie: true,
        });
        this.handleSelectTab(TOOLS_SECTION);
      }

      this.props.dispatch({
        type: "project/ready",
      });
    }
  };

  private vm = new SideBarPippeteStartViewModel(
    {
      gcodeLoader: userContainer.providers.gcodeLoader,
      printingService: userContainer.services.printing,
      printerRepository: userContainer.repositories.printer,
      commandTlcProvider: userContainer.providers.commandTlc,
      firmwareRepository: userContainer.repositories.firmware,
      commandGcodeProvider: userContainer.providers.commandGcode,
      printerConnectionProvider: userContainer.providers.printerConnection,
      printerResponseParserProvider:
        userContainer.providers.printerReponseParser,
    },
    {
      printers: this.props.printers,
      selectedPrinter: this.props.selectedPrinter,
      firmwareToUpdate: this.props.firmwareToUpdate,
      isSerialConnectionSupported: this.props.isSerialConnectionSupported,
      isPrintingViaServerMode: this.props.isPrintingViaServerMode,
      showMixModeSlider: this.props.tissuestartShowMixModeSlider,
      printerConnection: this.props.printerConnection,
      printingStatus: this.props.printingStatus,
      isZSet: this.props.isTissuestartZSet,
      isHomeSet: this.props.isTissuestartHome,
      mixMode: this.props.tissuestartMix,
      alert: this.props.alert,
      mixtureRatio: this.props.tissuestartMixRatio,
      priming: this.props.tissuestartPriming,
      disableMovement: this.props.tissuestartDisableMovement,
      printProgress: this.props.tissuestartPrintProgress,
      gcodes: this.props.gcodes,
      selectedGcodes: this.props.selectedGcodes,
      isCancelingPrinting: this.props.isCancelingPrinting,
    },
  );

  componentDidUpdate = () => {
    this.vm.updateState({
      printers: this.props.printers,
      selectedPrinter: this.props.selectedPrinter,
      firmwareToUpdate: this.props.firmwareToUpdate,
      isSerialConnectionSupported: this.props.isSerialConnectionSupported,
      isPrintingViaServerMode: this.props.isPrintingViaServerMode,
      showMixModeSlider: this.props.tissuestartShowMixModeSlider,
      printerConnection: this.props.printerConnection,
      printingStatus: this.props.printingStatus,
      isZSet: this.props.isTissuestartZSet,
      isHomeSet: this.props.isTissuestartHome,
      mixMode: this.props.tissuestartMix,
      alert: this.props.alert,
      mixtureRatio: this.props.tissuestartMixRatio,
      priming: this.props.tissuestartPriming,
      disableMovement: this.props.tissuestartDisableMovement,
      printProgress: this.props.tissuestartPrintProgress,
      gcodes: this.props.gcodes,
      selectedGcodes: this.props.selectedGcodes,
      isCancelingPrinting: this.props.isCancelingPrinting,
    });
  };

  getSelectedTool = () => {
    if (this.props.selectedTool === null) return "";

    return this.props.tools[this.props.selectedTool].label;
  };

  componentDidMount() {
    let projectId = this.props.match.params.id;
    let projectType = PIPETTESTART;

    this.props.dispatch({
      type: "project/open",
      projectId,
      projectType,
      center: { x: 67.5, y: 53.5 },
    });

    const load = async () => {
      try {
        await this.loadViewer();
        await this.loadSettings();
        await this.loadState();
      } catch (err) {
        Popup.error(this.props.dispatch, err);
        this.props.dispatch({ type: "project/close" });
      }
    };

    load();

    this.vm.setStateChangeCallback((newState) => {
      this.props.dispatch({
        type: "tissuestart/update-sidebar-pipette-start",
        ...newState,
        mixMode: this.getSelectedTool(),
      });
    });

    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave?";
    });
  }

  handleSelectTab = (selected: string) => {
    if (selected === TOOLS_SECTION) {
      this.props.dispatch({ type: "media/hide" });
    } else {
      this.props.dispatch({ type: "media/show" });
    }

    this.setState({
      selectedTab: selected,
    });
  };

  getCenterMedia = () => {
    return this.props.medias[this.props.selectedBrand!].center;
  };

  render() {
    // Tools Section -----------------------------------------------------
    let toolSection: React.ReactElement | null = null;
    if (this.state.selectedTab === TOOLS_SECTION) {
      toolSection = (
        <SectionTool nextTab={() => this.handleSelectTab(PIPETTE_SECTION)} />
      );
    }

    // Pipette Section -----------------------------------------------------
    let pipetteSection: React.ReactElement | null = null;
    if (this.state.selectedTab === PIPETTE_SECTION) {
      pipetteSection = (
        <SectionPipette
          nextTab={() => this.handleSelectTab(TISSUESTART_SECTION)}
        />
      );
    }

    // Control Section ----------------------------------------------------
    let deviceSection: React.ReactElement | null = null;
    if (this.state.selectedTab === TISSUESTART_SECTION) {
      deviceSection = (
        <ControlSection
          printerConnection={this.props.printerConnection}
          priming={this.props.tissuestartPriming}
          printingStatus={this.props.printingStatus}
          disableMovement={this.props.tissuestartDisableMovement}
          onMoveSyringeUp={() => this.vm.moveSyringeUp()}
          onMoveSyringeDown={() => this.vm.moveSyringeDown()}
          setPriming={(value) => this.vm.setPriming(value)}
          onDisconnect={() => this.vm.disconnect()}
          onConnect={() => this.vm.connect()}
          isSerialConnectionSupported={this.props.isSerialConnectionSupported}
          isPrintingViaServerMode={this.props.isPrintingViaServerMode}
          selectedPrinter={this.props.selectedPrinter}
          printers={this.props.printers}
          updateSelectedPrinter={(value) =>
            this.vm.updateSelectedPrinter(value)
          }
          updatePrintingMode={(value) => this.vm.updatePrintingMode(value)}
        />
      );
    }

    // print Section ----------------------------------------------------
    let printSection: React.ReactElement | null = null;
    if (this.state.selectedTab === PRINT_SECTION) {
      printSection = (
        <PrintSection
          mixMode={this.props.tissuestartMix}
          priming={this.props.tissuestartPriming}
          printingStatus={this.props.printingStatus}
          onDisconnect={() => this.vm.disconnect()}
          onMoveSyringeUp={() => this.vm.moveSyringeUp()}
          onMoveSyringeDown={() => this.vm.moveSyringeDown()}
          setPriming={(value) => this.vm.setPriming(value)}
        />
      );
    }

    return (
      <Sidebar>
        <SidebarHeader>
          <SidebarLogo src={logo} />
          <SidebarTitle />
        </SidebarHeader>

        <SidebarTabs>
          <Radio
            radios={[
              { name: "tools", value: TOOLS_SECTION },
              { name: "pipette", value: PIPETTE_SECTION },
              { name: "control", value: TISSUESTART_SECTION },
              { name: "print", value: PRINT_SECTION },
            ]}
            selected={this.state.selectedTab}
            onSelect={(selected) => this.handleSelectTab(selected)}
          />
        </SidebarTabs>

        {(this.state.selectedTab === TISSUESTART_SECTION ||
          this.state.selectedTab === PRINT_SECTION) && (
          <FloatActions
            printProgress={this.props.tissuestartPrintProgress}
            printStatus={this.props.printingStatus}
            onPlay={() => this.vm.print()}
            onResume={() => this.vm.resume()}
            onPause={() => this.vm.pause()}
            onCancel={() => this.vm.cancel()}
          />
        )}

        <ConfirmModal
          title="Printer Update"
          description="There is a software update available for your printer. Do you want to update it now?"
          show={!!this.props.firmwareToUpdate}
          onResponse={(confirmed) =>
            this.vm.handleUserSoftwareUpdateResponse(confirmed)
          }
          acceptLabel="Update"
        />

        <ConfirmModal
          title="Cancel Printing"
          description="Are you sure you want to cancel printing?"
          show={!!this.props.isCancelingPrinting}
          onResponse={(confirmed) => this.vm.handleCancelPrinting(confirmed)}
          acceptLabel="Cancel"
        />

        <SidebarBody>
          <HelpBtn
            section={this.state.selectedTab}
            isNewbie={this.state.isNewbie}
            onClickScreenProtector={() => this.setState({ isNewbie: false })}
          />
          {toolSection}
          {pipetteSection}
          {deviceSection}
          {printSection}
        </SidebarBody>
      </Sidebar>
    );
  }
}

export default withRouter(connect(mapStateToProps)(SidebarPipetteStart));
