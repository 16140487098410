import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";

interface AlertPopUpProps extends AppState, DispatchProp {}

interface AlertPopUpState {
  files: any[];
}

class AlertPopUp extends React.Component<AlertPopUpProps, AlertPopUpState> {
  handleClose = () => {
    this.props.dispatch({ type: "project/message", alert: null });
  };

  render() {
    return (
      <div>
        <Modal
          show={
            !!this.props.alert && this.props.alert.type !== "viewModelAlert"
          }
          onHide={this.props.isProjectWaiting ? undefined : this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#dc3545" }}>
              {this.props.alert?.title}
              {this.props.isProjectWaiting && (
                <Spinner animation="border" variant="primary" />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="modal-body">
            {this.props.alert?.message}
          </Modal.Body>

          <Modal.Footer>
            {this.props.alert?.type === "close" && (
              <Button
                variant="danger"
                onClick={() => this.props.dispatch({ type: "project/close" })}
              >
                close anyway
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AlertPopUp);
