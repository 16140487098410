import React from "react";
import { Form, Dropdown, DropdownButton, Card, Button } from "react-bootstrap";

import { WELLS } from "../../../../../../constants";
import FormsFromJSON from "../shared/formsFromJSON";
import MediaForms from "../toolSection/mediaForms";
import { TypedAction } from "../../../redux/reducers";

interface PipetteCycleProps {
  cycle: any;
  cycleIndex: number;
  medias: any[];
  selectedMedia: any;
  pipetteForms: any;
  dispatch: (action: TypedAction) => void;
}

class PipetteCycle extends React.Component<PipetteCycleProps> {
  private forms: FormsFromJSON[] = [];

  deleteCycle = (cycleIndex: number) => {
    this.props.dispatch({ type: "cycle/delete", cycleIndex });
  };

  addNewStep = (cycleIndex: number) => {
    this.props.dispatch({ type: "step/create", cycleIndex });
  };

  deleteStep = (cycleIndex: number, stepIndex: number) => {
    this.props.dispatch({ type: "step/delete", cycleIndex, stepIndex });
  };

  editStep = (data: any, cycleIndex: number, stepIndex: number) => {
    this.props.dispatch({
      type: "step/edit",
      data,
      cycleIndex,
      stepIndex,
    });
  };

  changeOperation = (
    value: any,
    cycleIndex: number,
    stepIndex: number,
    stepParameter: string
  ) => {
    this.props.dispatch({
      type: "step/operation",
      value,
      cycleIndex,
      stepIndex,
      stepParameter,
    });
  };

  changeRepeat = (repeat: string, cycleIndex: number) => {
    this.props.dispatch({ type: "cycle/repeat", cycleIndex, repeat });
  };

  selectWell = (selected: number, cycleIndex: number, stepIndex: number) => {
    this.props.dispatch({
      type: "well/select/pipette",
      cycleIndex,
      selected,
      stepIndex,
    });
  };

  getData = () => {
    let steps = this.forms.map((form) => form.getData());
    let cycle = { ...this.props.cycle, steps };
    return cycle;
  };

  render() {
    this.forms = [];
    let cycle = this.props.cycle;
    let cycleIndex = this.props.cycleIndex;
    let pipetteForms = this.props.pipetteForms;
    let mediaCenters = this.props.medias[this.props.selectedMedia].centers;
    let validOperations = pipetteForms.map((field: any) => field.id);

    let steps = cycle.steps.map(
      (step: Record<string, any>, stepIndex: number) => {
        let operations = validOperations.map(
          (operation: any, opIndex: number) => {
            return (
              <Dropdown.Item
                key={opIndex}
                onClick={() =>
                  this.changeOperation(
                    operation,
                    cycleIndex,
                    stepIndex,
                    "operation"
                  )
                }
              >
                {operation}
              </Dropdown.Item>
            );
          }
        );

        let operationFields = pipetteForms.filter(
          (field: Record<string, any>) => field.id === step.operation
        );
        let operationField: any;
        let paramForms: React.ReactElement | null = null;
        if (operationFields.length > 0) {
          operationField = operationFields[0];
          paramForms = (
            <FormsFromJSON
              ref={(el) => (el ? this.forms.push(el) : {})}
              forms={operationField.options}
              data={step}
              handleEdit={(data) => this.editStep(data, cycleIndex, stepIndex)}
            />
          );
        }

        let mediaForms: React.ReactElement | null = null;
        if (
          mediaCenters.length > 1 &&
          operationField &&
          operationField.options.some(
            (option: any) => option.inputType === WELLS
          )
        ) {
          if (!Array.isArray(step.selectedWells)) step.selectedWells = [];
          mediaForms = (
            <MediaForms
              title="target"
              centers={mediaCenters}
              selectedWells={step.selectedWells}
              handleSelectWell={(selected) =>
                this.selectWell(selected, cycleIndex, stepIndex)
              }
            />
          );
        }

        return (
          <div key={stepIndex}>
            <hr />
            <div
              style={{
                display: "inline-flex",
                gap: "1vw",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Form.Label
                style={{
                  color: "#ff80ae",
                  fontFamily: "tissuelabs-bold",
                }}
              >
                step {stepIndex + 1}
              </Form.Label>
              <DropdownButton
                variant="secondary"
                title={step.operation ? step.operation : "operation"}
              >
                {operations}
              </DropdownButton>
              <Button
                onClick={() => this.deleteStep(cycleIndex, stepIndex)}
                variant="danger"
              >
                -
              </Button>
            </div>

            {mediaForms}
            {paramForms}
          </div>
        );
      }
    );

    return (
      <Card className="pipette-forms-card" style={{ padding: "1vw" }}>
        <Form.Group>
          <div
            style={{
              display: "inline-flex",
              gap: "1vw",
              alignItems: "baseline",
              justifyContent: "flex-start",
            }}
          >
            <Form.Label
              style={{
                color: "#ff80ae",
                fontFamily: "tissuelabs-bold",
              }}
            >
              cycle {cycleIndex + 1}
            </Form.Label>
            <div>-</div>
            <div>repeat</div>
            <Form.Control
              onChange={(e) => this.changeRepeat(e.target.value, cycleIndex)}
              value={cycle.repeat}
              style={{ width: "4vw" }}
            />
            <div>times</div>
            <Button
              onClick={() => this.deleteCycle(cycleIndex)}
              variant="danger"
            >
              x
            </Button>
          </div>
          {steps}
        </Form.Group>
        <Button onClick={() => this.addNewStep(cycleIndex)} variant="success">
          +
        </Button>
      </Card>
    );
  }
}

// dont add redux to components with refs
export default PipetteCycle;
