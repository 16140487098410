import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";

interface DownloadBtnProps extends AppState, DispatchProp {
  fileAddress: string;
  type: string;
  variant?: string;
}

class DownloadBtn extends React.Component<DownloadBtnProps> {
  render() {
    const variant = this.props.variant ? this.props.variant : "outline-light";

    return (
      <div>
        {!this.props.isProjectWaiting && (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="downloadBtn">download</Tooltip>}
          >
            <Button variant={variant}>
              <a
                href={this.props.fileAddress}
                download={this.props.fileAddress}
              >
                <i className="fas fa-download text-white" />
              </a>
            </Button>
          </OverlayTrigger>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(DownloadBtn);
