import { Card } from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import MarkSlider from "../../../components/shared/markSlider";

interface PrintAdjustmentProps {
  printerLight: number;
  flow: number;
  speed: number;
  showMixModeSlider: boolean;
  mixtureRatio: number;
  light365: number;
  light405: number;
  onChangePrinterLight: (value: number) => void;
  onChangeSpeed: (value: number) => void;
  onChangeFlow: (value: number) => void;
  onChangeMixtureRatio: (value: number) => void;
  onChangeLight365: (value: number) => void;
  onChangeLight405: (value: number) => void;
}

export const PrintAdjustment = ({
  flow,
  speed,
  showMixModeSlider,
  mixtureRatio,
  light365,
  light405,
  printerLight,
  onChangeSpeed,
  onChangeFlow,
  onChangeMixtureRatio,
  onChangeLight365,
  onChangeLight405,
  onChangePrinterLight,
}: PrintAdjustmentProps) => {
  const handleChangeSpeed = (value: number) => {
    onChangeSpeed(value);
  };
  const handleChangeFlow = (value: number) => {
    onChangeFlow(value);
  };

  const handleChangeMixtrusor = (value: number) => {
    onChangeMixtureRatio(value);
  };

  const handleChangeLight365 = (value: number) => {
    onChangeLight365(value);
  };

  const handleChangeLight405 = (value: number) => {
    onChangeLight405(value);
  };

  const handleChangePrinterLight = (value: number) => {
    onChangePrinterLight(value);
  };

  const mixRight = 100 - mixtureRatio;
  const mixLeft = mixtureRatio;

  return (
    <Card
      className="print-adjustment"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: "1vw",
        padding: "1vw",
        paddingRight: "3vw",
        color: "gray",
      }}
    >
      <div>
        <p>speed (mm/s)</p>
        <MarkSlider
          defaultValue={speed}
          step={1}
          min={0.5}
          max={15}
          marks={[0.5, 15]}
          onChange={() => {}}
          onChangeCommitted={handleChangeSpeed}
        />
      </div>
      <div>
        <p>flow (%)</p>
        <MarkSlider
          defaultValue={flow}
          step={1}
          min={0}
          max={300}
          marks={[0, 100]}
          onChange={() => {}}
          onChangeCommitted={handleChangeFlow}
        />
      </div>
      <div>
        <p>light 365nm (%)</p>
        <MarkSlider
          defaultValue={light365}
          step={1}
          min={0}
          max={100}
          marks={[0, 100]}
          onChange={() => {}}
          onChangeCommitted={handleChangeLight365}
        />
      </div>
      <div>
        <p>light 405nm (%)</p>
        <MarkSlider
          defaultValue={light405}
          step={1}
          min={0}
          max={100}
          marks={[0, 100]}
          onChange={() => {}}
          onChangeCommitted={handleChangeLight405}
        />
      </div>
      <div>
        <p>printer light (%)</p>
        <MarkSlider
          defaultValue={printerLight}
          step={1}
          min={0}
          max={100}
          marks={[0, 100]}
          onChange={() => {}}
          onChangeCommitted={handleChangePrinterLight}
        />
      </div>

      {showMixModeSlider && (
        <div>
          <p>mixture ratio (%)</p>
          <MarkSlider
            defaultValue={mixtureRatio}
            step={1}
            min={0}
            max={100}
            marks={[
              { value: 0, label: `${mixRight}%` },
              { value: 100, label: `${mixLeft}%` },
            ]}
            onChange={() => {}}
            onChangeCommitted={handleChangeMixtrusor}
            valueLabelFormat={(value) => {
              const mixLeft = 100 - value;
              return `${mixLeft}:${value}`;
            }}
          />
        </div>
      )}
    </Card>
  );
};
