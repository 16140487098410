import { Vector3 } from "three";

class AMFExporter {
  parse(objects: Record<string, any>[]) {
    // when there are more than 1 obj
    let output = "";
    let verticesOutput = "";
    let facesOutput = "";
    const vertex = new Vector3();
    const face: any[] = [];

    let indexVertex = 0;
    let nbVertex = 0;

    // headers
    output += '<?xml version="1.0" encoding="UTF-8"?>\n';
    output += '<amf unit="millimeter">\n';
    output += '  <metadata type="cad">TissueWeb</metadata>\n';

    output += '  <object id="0">\n';
    output += `    <metadata type="slic3r.extruder">0</metadata>\n`;
    output += `    <metadata type="name">Composition</metadata>\n`;
    output += "    <mesh>\n";

    // vertices
    objects.forEach((object) => {
      const geometry = object.geometry;
      const vertices = geometry.getAttribute("position");
      const indices = geometry.getIndex();

      if (vertices !== undefined) {
        //output += '      <vertices>\n';
        for (let i = 0, l = vertices.count; i < l; i++, nbVertex++) {
          vertex.x = vertices.getX(i);
          vertex.y = vertices.getY(i);
          vertex.z = vertices.getZ(i);

          // transform the vertex to world space
          vertex.applyMatrix4(object.matrixWorld);

          // transform the vertex to export format
          verticesOutput += "         <vertex>\n";
          verticesOutput += "           <coordinates>\n";
          verticesOutput += `             <x>${vertex.x}</x>\n`;
          verticesOutput += `             <y>${vertex.y}</y>\n`;
          verticesOutput += `             <z>${vertex.z}</z>\n`;
          verticesOutput += "           </coordinates>\n";
          verticesOutput += "         </vertex>\n";
        }
        //output += '      </vertices>\n';
      }

      // faces
      facesOutput += "      <volume>\n";
      facesOutput += `        <metadata type="slic3r.extruder">${object.extruder}</metadata>\n`;
      facesOutput += `        <metadata type="name">${object.name}</metadata>\n`;
      if (indices !== null) {
        for (let i = 0, l = indices.count; i < l; i += 3) {
          facesOutput += "        <triangle>\n";
          for (let m = 0; m < 3; m++) {
            const j = indices.getX(i + m);

            face[m] = indexVertex + j;
            facesOutput += `          <v${m + 1}>${face[m]}</v${m + 1}>\n`;
          }
          facesOutput += "        </triangle>\n";
        }
      } else {
        for (let i = 0, l = vertices.count; i < l; i += 3) {
          facesOutput += "        <triangle>\n";
          for (let m = 0; m < 3; m++) {
            const j = i + m;

            face[m] = indexVertex + j;
            facesOutput += `          <v${m + 1}>${face[m]}</v${m + 1}>\n`;
          }
          facesOutput += "        </triangle>\n";
        }
      }
      facesOutput += "      </volume>\n";
      indexVertex += nbVertex;
    });

    output += "      <vertices>\n";
    output += verticesOutput;
    output += "      </vertices>\n";

    output += facesOutput;

    output += "    </mesh>\n";
    output += "  </object>\n";
    output += "</amf>\n";

    return output;
  }
}

export { AMFExporter };
