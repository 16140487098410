import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import {
  UserProjectGcodeCreateParams,
  UserProjectGcodePipetteCreateParams,
  UserProjectGcodeDeleteParams,
  UserProjectGcodeFindByProjectIdAndGcodeIdParams,
  UserProjectGcodeRepository,
} from "../../../application/data/repositories/UserProjectGcodeRepository";
import {
  UserProjectGcode,
  UserProjectGcodeWithDetails,
} from "../../../application/models/UserProjectGcode";
import { ArrayBufferProvider } from "../../../application/providers/ArrayBufferProvider";
import { UserProjectGcodeMapper } from "../mappers/UserProjectGcodeMapper";
import {
  UserProjectGcodeRaw,
  UserProjectGcodeWithDetailsRaw,
} from "../models/UserProjectGcodeRaw";

export class UserProjectGcodeRepositoryImpl
  implements UserProjectGcodeRepository
{
  constructor(
    private arrayBufferProvider: ArrayBufferProvider,
    private httpProvider: HttpProvider,
    private userProjectGcodeMapper: UserProjectGcodeMapper
  ) {}

  async findAllByProjectId(projectId: number): Promise<UserProjectGcode[]> {
    const response = await this.httpProvider.request<UserProjectGcodeRaw[]>({
      method: "GET",
      url: `/project/${projectId}/gcode`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userProjectGcodeMapper.userProjectGcodeRawToUserProjectGcode(e)
      )
    );
  }

  async findByProjectIdAndGcodeId(
    params: UserProjectGcodeFindByProjectIdAndGcodeIdParams
  ): Promise<UserProjectGcodeWithDetails> {
    const response =
      await this.httpProvider.request<UserProjectGcodeWithDetailsRaw>({
        method: "GET",
        url: `/project/${params.projectId}/gcode/${params.gcodeId}`,
      });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectGcodeMapper.userProjectGcodeWithDetailsRawToUserProjectGcodeWithDetails(
      response.body
    );
  }

  async create(
    params: UserProjectGcodeCreateParams
  ): Promise<UserProjectGcode> {
    const { projectId, file, ...paramsBody } = params;

    const requestBody = {
      ...paramsBody,
      file: {
        content: await this.arrayBufferProvider.arrayBufferToBase64(
          file.content
        ),
        extension: file.extension,
      },
    };

    const response = await this.httpProvider.request<UserProjectGcodeRaw>({
      method: "POST",
      url: `/project/${projectId}/gcode`,
      body: requestBody,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectGcodeMapper.userProjectGcodeRawToUserProjectGcode(
      response.body
    );
  }

  async createPipette(
    params: UserProjectGcodePipetteCreateParams
  ): Promise<UserProjectGcode> {
    const { projectId, ...requestBody } = params;

    const response = await this.httpProvider.request<UserProjectGcodeRaw>({
      method: "POST",
      url: `/project/${projectId}/pipette-gcode`,
      body: requestBody,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectGcodeMapper.userProjectGcodeRawToUserProjectGcode(
      response.body
    );
  }

  async delete(params: UserProjectGcodeDeleteParams): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/project/${params.projectId}/gcode/${params.gcodeId}`,
    });
  }
}
