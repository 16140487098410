import { Button, Modal } from "react-bootstrap";

interface ConfirmModalProps {
  show: boolean;
  title: string;
  description: string;
  acceptLabel?: string;
  declineLabel?: string;
  onResponse: (confirmed: boolean) => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  title,
  description,
  acceptLabel,
  declineLabel,
  onResponse,
}) => (
  <div>
    <Modal show={show} onHide={() => onResponse(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#dc3545" }}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-body">{description}</Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={() => onResponse(false)}>
          {declineLabel === undefined ? "Cancel" : declineLabel}
        </Button>
        <Button variant="primary" onClick={() => onResponse(true)}>
          {acceptLabel === undefined ? "Ok" : acceptLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);
