import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import { UserFirmwareRepository } from "../../../application/data/repositories/UserFirmwareRepository";
import { UserFirmware } from "../../../application/models/UserFirmware";
import { UserPrinterModel } from "../../../application/models/UserPrinter";
import { UserFirmwareMapper } from "../mappers/UserFirmwareMapper";
import { UserFirmwareRaw } from "../models/UserFirmwareRaw";

export class UserFirmwareRepositoryImpl implements UserFirmwareRepository {
  constructor(
    private httpProvider: HttpProvider,
    private userFirmwareMapper: UserFirmwareMapper
  ) {}

  async listEnabled(printerModel: UserPrinterModel): Promise<UserFirmware[]> {
    const response = await this.httpProvider.request<UserFirmwareRaw[]>({
      method: "GET",
      url: `/firmware/${printerModel}/enabled`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userFirmwareMapper.userFirmwareRawToUserFirmware(e)
      )
    );
  }
}
