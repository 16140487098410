import {
  API_BASE_URL,
  API_WS_BASE_URL,
  PRINTER_FEEDRATE,
  PRINTER_SERIAL_BAUD_RATE,
  PRINTER_Z_SAFETY_HEIGHT,
  PRINTER_RECEIVE_LINE_ENDING,
  PRINTER_WEBSOCKET_CONNECTION_DELAY,
} from "../../../constants";
import { HttpProvider } from "../../../shared/framework/providers/HttpProvider";
import { TokenStorageProvider } from "../../../shared/framework/providers/TokenStorageProvider";
import { HttpProviderImpl } from "../../../shared/framework/providers/impl/HttpProviderImpl";
import { TokenStorageProviderImpl } from "../../../shared/framework/providers/impl/TokenStorageProviderImpl";
import { ArrayBufferProvider } from "../../application/providers/ArrayBufferProvider";
import { CommandGcodeProvider } from "../../application/providers/CommandGcodeProvider";
import { PrinterConnectionProvider } from "../../application/providers/PrinterConnectionProvider";
import { PrinterResponseParserProvider } from "../../application/providers/PrinterResponseParserProvider";
import { ArrayBufferProviderImpl } from "../providers/impl/ArrayBufferProviderImpl";
import { CommandGcodeProviderImpl } from "../providers/impl/CommandGcodeProviderImpl";
import { PrinterConnectionProviderImpl } from "../providers/impl/PrinterConnectionProviderImpl";
import { PrinterResponseParserProviderImpl } from "../providers/impl/PrinterResponseParserProviderImpl";
import { GCodeLoaderProvider } from "../../application/providers/GCodeLoaderProvider";
import { GCodeLoaderProviderImpl } from "../providers/impl/GCodeLoaderProviderImpl";
import { CommandTlcProvider } from "../../application/providers/CommandTlcProvider";
import { CommandTlcProviderImpl } from "../providers/impl/CommandTlcProviderImpl";
import { GCodeLayerProvider } from "../providers/GCodeLayerProvider";
import { GCodeLayerProviderImpl } from "../providers/impl/GcodeLayerProviderImpl";

export interface UserProviders {
  arrayBuffer: ArrayBufferProvider;
  commandGcode: CommandGcodeProvider;
  commandTlc: CommandTlcProvider;
  gcodeLayer: GCodeLayerProvider;
  gcodeLoader: GCodeLoaderProvider;
  http: HttpProvider;
  printerConnection: PrinterConnectionProvider;
  printerReponseParser: PrinterResponseParserProvider;
  tokenStorage: TokenStorageProvider;
}

const arrayBuffer = new ArrayBufferProviderImpl();
const commandGcode = new CommandGcodeProviderImpl(
  PRINTER_FEEDRATE,
  PRINTER_Z_SAFETY_HEIGHT
);
const commandTlc = new CommandTlcProviderImpl();
const gcodeLayer = new GCodeLayerProviderImpl();
const gcodeLoader = new GCodeLoaderProviderImpl();
const tokenStorage = new TokenStorageProviderImpl("user-token");
const printerConnection = new PrinterConnectionProviderImpl(
  tokenStorage,
  API_WS_BASE_URL,
  PRINTER_RECEIVE_LINE_ENDING,
  PRINTER_SERIAL_BAUD_RATE,
  PRINTER_WEBSOCKET_CONNECTION_DELAY
);
const printerReponseParser = new PrinterResponseParserProviderImpl();
const http = new HttpProviderImpl(`${API_BASE_URL}/user`, tokenStorage);

export const userProviders: UserProviders = {
  arrayBuffer,
  commandGcode,
  commandTlc,
  gcodeLayer,
  gcodeLoader,
  http,
  printerConnection,
  printerReponseParser,
  tokenStorage,
};
