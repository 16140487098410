import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import {
  UserProjectModelCopyParams,
  UserProjectModelCreateParams,
  UserProjectModelDeleteParams,
  UserProjectModelRepository,
} from "../../../application/data/repositories/UserProjectModelRepository";
import { UserProjectModel } from "../../../application/models/UserProjectModel";
import { ArrayBufferProvider } from "../../../application/providers/ArrayBufferProvider";
import { UserProjectModelMapper } from "../mappers/UserProjectModelMapper";
import { UserProjectModelRaw } from "../models/UserProjectModelRaw";

export class UserProjectModelRepositoryImpl
  implements UserProjectModelRepository
{
  constructor(
    private arrayBufferProvider: ArrayBufferProvider,
    private httpProvider: HttpProvider,
    private userProjectModelMapper: UserProjectModelMapper
  ) {}

  async findAllByProjectId(projectId: number): Promise<UserProjectModel[]> {
    const response = await this.httpProvider.request<UserProjectModelRaw[]>({
      method: "GET",
      url: `/project/${projectId}/model`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userProjectModelMapper.userProjectModelRawToUserProjectModel(e)
      )
    );
  }

  async create(
    params: UserProjectModelCreateParams
  ): Promise<UserProjectModel> {
    const body = {
      name: params.name,
      file: {
        content: await this.arrayBufferProvider.arrayBufferToBase64(
          params.file.content
        ),
        extension: params.file.extension,
      },
    };

    const response = await this.httpProvider.request<UserProjectModelRaw>({
      method: "POST",
      url: `/project/${params.projectId}/model`,
      body: body,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectModelMapper.userProjectModelRawToUserProjectModel(
      response.body
    );
  }

  async copy(params: UserProjectModelCopyParams): Promise<UserProjectModel> {
    const response = await this.httpProvider.request<UserProjectModelRaw>({
      method: "POST",
      url: `/project/${params.projectId}/model/${params.modelId}/copy`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectModelMapper.userProjectModelRawToUserProjectModel(
      response.body
    );
  }

  async delete(params: UserProjectModelDeleteParams): Promise<void> {
    await this.httpProvider.request<UserProjectModelRaw>({
      method: "DELETE",
      url: `/project/${params.projectId}/model/${params.modelId}`,
    });
  }
}
