import {
  AdminSettingsCreateParams,
  AdminSettingsRepository,
  AdminSettingsUpdateParams,
} from "../../../application/data/repositories/AdminSettingsRepository";
import { AdminSettings } from "../../../application/models/AdminSettings";
import { AdminSettingsMapper } from "../mappers/AdminSettingsMapper";
import { AdminSettingsRaw } from "../models/AdminSettingsRaw";
import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import { SettingsType } from "../../../../shared/application/models/SettingsType";

export class AdminSettingsRepositoryImpl implements AdminSettingsRepository {
  constructor(
    private httpProvider: HttpProvider,
    private settingsMapper: AdminSettingsMapper
  ) {}

  async findAll(): Promise<AdminSettings[]> {
    const response = await this.httpProvider.request<AdminSettingsRaw[]>({
      method: "GET",
      url: "/settings",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) => this.settingsMapper.settingsRawToSettings(e))
    );
  }

  async findAllByType(type: SettingsType): Promise<AdminSettings[]> {
    const response = await this.httpProvider.request<AdminSettingsRaw[]>({
      method: "GET",
      url: `/settings?type=${type}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) => this.settingsMapper.settingsRawToSettings(e))
    );
  }

  async findById(id: number): Promise<AdminSettings> {
    const response = await this.httpProvider.request<AdminSettingsRaw>({
      method: "GET",
      url: `/settings/${id}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.settingsMapper.settingsRawToSettings(response.body);
  }

  async create(params: AdminSettingsCreateParams): Promise<AdminSettings> {
    const response = await this.httpProvider.request<AdminSettingsRaw>({
      method: "POST",
      url: "/settings",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.settingsMapper.settingsRawToSettings(response.body);
  }

  async update(params: AdminSettingsUpdateParams): Promise<AdminSettings> {
    const response = await this.httpProvider.request<AdminSettingsRaw>({
      method: "PUT",
      url: `/settings/${params.id}`,
      body: { content: params.content },
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.settingsMapper.settingsRawToSettings(response.body);
  }

  async delete(id: number): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/settings/${id}`,
    });
  }
}
