import React from "react";
import { DispatchProp, connect } from "react-redux";
import * as THREE from "three";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { loadGcode, loadModel } from "../../../../data/network/apiServer";
import SectionTool from "../../components/toolSection";
import SectionObject from "../../components/objectSection";
import SectionSlicer from "../../components/slicerSection";
import {
  MODEL_SECTION,
  PRINT_SECTION,
  SLICER_SECTION,
  TISSUESTART_SECTION,
  TOOLS_SECTION,
  GCODE,
  OBJECT,
  ROTATION_OFFSET_X,
  TISSUESTART,
} from "../../../../../../constants";
import HelpBtn from "../../components/shared/helpBtn";
import Sidebar, {
  SidebarBody,
  SidebarHeader,
  SidebarLogo,
  SidebarTabs,
  SidebarTitle,
} from "../../components/shared/sidebar";

import logo from "../../../assets/images/logo-tissuestart.png";
import bed3dModel from "../../../assets/3d-models/bed3D.stl";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";
import { UserProjectGcode } from "../../../../../application/models/UserProjectGcode";
import { UserProjectModel } from "../../../../../application/models/UserProjectModel";
import { ControlSection } from "./control/ControlSection";
import { ControlSectionViewModelState } from "./control/ControlSectionViewModel";
import { PrintSection } from "./print/PrintSection";
import { PrintSectionViewModelState } from "./print/PrintSectionViewModel";
import { SectionStepsControl } from "../../components/shared/SectionStepsControl";
import { SideBarTissueStartViewModel } from "./SideBarTissueStartViewModel";
import { FloatActions } from "../../components/shared/FloatActions";
import { ConfirmModal } from "../../components/shared/ConfirmModal";

interface SidebarTissueStartParams {
  id: string;
}

interface SidebarTissueStartProps
  extends RouteComponentProps<SidebarTissueStartParams>,
    AppState,
    DispatchProp {}

interface SidebarTissueStartState {
  selectedTab: string;
  isNewbie: boolean;
}

class SidebarTissueStart extends React.Component<
  SidebarTissueStartProps,
  SidebarTissueStartState
> {
  constructor(props: SidebarTissueStartProps) {
    super(props);

    this.state = {
      selectedTab: "tools",
      isNewbie: false,
    };

    this.handleMakeVisible = this.handleMakeVisible.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.loadViewer = this.loadViewer.bind(this);
  }

  async loadModels(models: UserProjectModel[]) {
    for (var i = 0; i < models.length; i++) {
      const model = models[i];
      this.props.dispatch({
        type: "project/loadMessage",
        loadMessage: `... creating model ${i + 1}/${models.length}`,
      });

      const content = await loadModel(model.fileAddress);
      if (model) {
        this.props.dispatch({
          type: "object/create",
          model: content,
          file: models[i],
        });
      }
    }
  }

  async loadGcodes(gcodes: UserProjectGcode[]) {
    for (var i = 0; i < gcodes.length; i++) {
      const gcode = gcodes[i];
      this.props.dispatch({
        type: "project/loadMessage",
        loadMessage: `... creating gcode ${i + 1}/${gcodes.length}`,
      });

      const content = await loadGcode(gcode.fileAddress);
      if (gcode) {
        this.props.dispatch({
          type: "gcode/create",
          gcode: content,
          file: gcodes[i],
        });
      }
    }
  }

  async loadViewer() {
    this.props.dispatch({
      type: "project/loadMessage",
      loadMessage: "... creating 3D bed",
    });

    const bedMaterial = new THREE.MeshLambertMaterial({ color: 0xff80ae });
    let bed: THREE.Mesh | null = null;
    let offset = { x: 0, y: -41.5, z: 0 };
    const bedGeometry = await loadModel(undefined, bed3dModel);

    if (bedGeometry && bedGeometry instanceof THREE.BufferGeometry) {
      bed = new THREE.Mesh(bedGeometry, bedMaterial);
      bed.rotation.set(-ROTATION_OFFSET_X, bed.rotation.y, Math.PI);
    }

    if (bed === null) {
      throw new Error("Bed geometry not available");
    }

    this.props.dispatch({ type: "project/bed", bed, offset });

    const gcodes =
      await userContainer.repositories.projectGcode.findAllByProjectId(
        parseInt(this.props.projectId),
      );
    await this.loadGcodes(gcodes);

    const models =
      await userContainer.repositories.projectModel.findAllByProjectId(
        parseInt(this.props.projectId),
      );
    await this.loadModels(models);
  }

  async loadSettings() {
    this.props.dispatch({
      type: "project/loadMessage",
      loadMessage: "... preparing environment",
    });

    try {
      const settings =
        await userContainer.repositories.settings.findByType("tissuestart");

      const content: any = settings.content;

      this.props.dispatch({
        type: "media/create",
        medias: content.media.options,
      });
      this.props.dispatch({
        type: "tool/create",
        tools: content.tool.options,
      });
      this.props.dispatch({
        type: "slicer/create",
        slicerForms: content.slicer.options,
      });
      this.props.dispatch({
        type: "object",
        objectMaxNumber: content.objects.maxNumber,
      });
    } catch {
      this.props.dispatch({
        type: "project/close",
      });
    }
  }

  async loadState() {
    this.props.dispatch({
      type: "project/loadMessage",
      loadMessage: "... recovering state",
    });

    const project = await userContainer.repositories.project.findById(
      parseInt(this.props.projectId),
    );
    const state = project.state as AppState;

    if (project.type === this.props.projectType) {
      let readyToSlice = true;
      let readyToPrint = true;

      if (project.name)
        this.props.dispatch({
          type: "project/rename",
          projectName: project.name,
        });

      if (state.customMedias)
        this.props.dispatch({
          type: "media/create",
          medias: state.customMedias,
        });

      if (state.selectedTool || state.selectedTool === 0)
        this.props.dispatch({
          type: "tool/select",
          selected: state.selectedTool,
        });
      else readyToSlice = false;

      if (state.selectedBrand || state.selectedBrand === 0)
        this.props.dispatch({
          type: "brand/select",
          selected: state.selectedBrand,
        });
      if (state.selectedMedia || state.selectedMedia === 0)
        this.props.dispatch({
          type: "media/select",
          selected: state.selectedMedia,
        });
      else readyToSlice = false;

      if (
        state.selectedMedia !== null &&
        this.props.medias[state.selectedMedia] &&
        state.selectedWells.length > 0
      ) {
        state.selectedWells.forEach((selected: number | null) => {
          this.props.dispatch({
            type: "well/select/slicer",
            selected,
          });
        });
      }

      this.props.dispatch({
        type: "object/unselectAll",
      });
      if (
        state.selectedObjects &&
        state.selectedObjects.length > 0 &&
        (state.selectedObjects[0] || state.selectedObjects[0] === 0)
      )
        state.selectedObjects.forEach(
          (selected: number | null, index: number) => {
            this.props.dispatch({
              type: "object/select",
              selected,
              slot: index,
            });
          },
        );
      else readyToSlice = false;

      this.props.dispatch({
        type: "gcode/unselectAll",
      });
      if (
        state.selectedGcodes &&
        state.selectedGcodes.length > 0 &&
        (state.selectedGcodes[0] || state.selectedGcodes[0] === 0)
      )
        state.selectedGcodes.forEach(
          (selected: number | null, index: number) => {
            this.props.dispatch({
              type: "gcode/select",
              selected,
              slot: index,
            });
          },
        );
      else readyToPrint = false;

      let inputData = state.slicerFields ? state.slicerFields : [];
      this.props.dispatch({
        type: "slicer/fill",
        inputData,
      });

      if (readyToPrint) this.handleSelectTab(TISSUESTART_SECTION);
      else if (readyToPrint) this.handleSelectTab(PRINT_SECTION);
      else if (readyToSlice) this.handleSelectTab(SLICER_SECTION);
      else {
        this.setState({
          isNewbie: true,
        });
        this.handleSelectTab(TOOLS_SECTION);
      }

      let objects = state.objects;

      if (objects) {
        objects.forEach((object: any, selected: number | null) => {
          let orientation = {
            position: object.position,
            rotation: object.rotation,
            scale: object.scale,
          };

          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "position",
            axis: "x",
            stringValue:
              orientation.position.x || orientation.position.x === 0
                ? orientation.position.x
                : orientation.position["_x"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "position",
            axis: "y",
            stringValue:
              orientation.position.y || orientation.position.y === 0
                ? orientation.position.y
                : orientation.position["_y"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "position",
            axis: "z",
            stringValue:
              orientation.position.z || orientation.position.z === 0
                ? orientation.position.z
                : orientation.position["_z"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "rotation",
            axis: "x",
            stringValue:
              orientation.rotation.x || orientation.rotation.x === 0
                ? orientation.rotation.x
                : orientation.rotation["_x"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "rotation",
            axis: "y",
            stringValue:
              orientation.rotation.y || orientation.rotation.y === 0
                ? orientation.rotation.y
                : orientation.rotation["_y"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "rotation",
            axis: "z",
            stringValue:
              orientation.rotation.z || orientation.rotation.z === 0
                ? orientation.rotation.z
                : orientation.rotation["_z"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "scale",
            axis: "x",
            stringValue:
              orientation.scale.x || orientation.scale.x === 0
                ? orientation.scale.x
                : orientation.scale["_x"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "scale",
            axis: "y",
            stringValue:
              orientation.scale.y || orientation.scale.y === 0
                ? orientation.scale.y
                : orientation.scale["_y"],
          });
          this.props.dispatch({
            type: "object/move",
            selected,
            parameter: "scale",
            axis: "z",
            stringValue:
              orientation.scale.z || orientation.scale.z === 0
                ? orientation.scale.z
                : orientation.scale["_z"],
          });

          let mixProportion = object.mixProportion;
          this.props.dispatch({
            type: "object/material",
            mixProportion,
            selected,
          });
        });
      }

      this.props.dispatch({
        type: "project/ready",
      });
    }
  }

  private vm = new SideBarTissueStartViewModel(
    {
      gcodeLoader: userContainer.providers.gcodeLoader,
      printingService: userContainer.services.printing,
      printerRepository: userContainer.repositories.printer,
      commandTlcProvider: userContainer.providers.commandTlc,
      firmwareRepository: userContainer.repositories.firmware,
      commandGcodeProvider: userContainer.providers.commandGcode,
      printerConnectionProvider: userContainer.providers.printerConnection,
      printerResponseParserProvider:
        userContainer.providers.printerReponseParser,
    },
    {
      printers: this.props.printers,
      selectedPrinter: this.props.selectedPrinter,
      firmwareToUpdate: this.props.firmwareToUpdate,
      isSerialConnectionSupported: this.props.isSerialConnectionSupported,
      isPrintingViaServerMode: this.props.isPrintingViaServerMode,
      showMixModeSlider: this.props.tissuestartShowMixModeSlider,
      printerConnection: this.props.printerConnection,
      printingStatus: this.props.printingStatus,
      isZSet: this.props.isTissuestartZSet,
      isHomeSet: this.props.isTissuestartHome,
      mixMode: this.props.tissuestartMix,
      alert: this.props.alert,
      mixtureRatio: this.props.tissuestartMixRatio,
      priming: this.props.tissuestartPriming,
      disableMovement: this.props.tissuestartDisableMovement,
      printProgress: this.props.tissuestartPrintProgress,
      gcodes: this.props.gcodes,
      selectedGcodes: this.props.selectedGcodes,
      isCancelingPrinting: this.props.isCancelingPrinting
    },
  );

  componentDidUpdate = () => {
    this.vm.updateState({
      printers: this.props.printers,
      selectedPrinter: this.props.selectedPrinter,
      firmwareToUpdate: this.props.firmwareToUpdate,
      isSerialConnectionSupported: this.props.isSerialConnectionSupported,
      isPrintingViaServerMode: this.props.isPrintingViaServerMode,
      showMixModeSlider: this.props.tissuestartShowMixModeSlider,
      printerConnection: this.props.printerConnection,
      printingStatus: this.props.printingStatus,
      isZSet: this.props.isTissuestartZSet,
      isHomeSet: this.props.isTissuestartHome,
      mixMode: this.props.tissuestartMix,
      alert: this.props.alert,
      mixtureRatio: this.props.tissuestartMixRatio,
      priming: this.props.tissuestartPriming,
      disableMovement: this.props.tissuestartDisableMovement,
      printProgress: this.props.tissuestartPrintProgress,
      gcodes: this.props.gcodes,
      selectedGcodes: this.props.selectedGcodes,
      isCancelingPrinting: this.props.isCancelingPrinting
    });
  };

  componentDidMount() {
    let projectId = this.props.match.params.id;
    let projectType = TISSUESTART;

    this.props.dispatch({
      type: "project/open",
      projectId,
      projectType,
      center: { x: 67.5, y: 53.5 },
    });

    const load = async () => {
      try {
        await this.loadViewer();
        await this.loadSettings();
        await this.loadState();
      } catch (err) {
        this.props.dispatch({ type: "project/close" });
        Popup.error(this.props.dispatch, err);
      }
    };

    load();

    this.vm.setStateChangeCallback((newState) => {
      this.props.dispatch({
        type: "tissuestart/update-sidebar-pipette-start",
        ...newState,
      });
    });

    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave?";
    });
  }

  handleMakeVisible(elementType: string | null) {
    if (elementType === OBJECT) {
      this.props.dispatch({ type: "gcode/unstage" });
      this.props.dispatch({ type: "object/stage" });
    } else if (elementType === GCODE) {
      this.props.dispatch({ type: "gcode/stage" });
      this.props.dispatch({ type: "object/unstage" });
    } else {
      this.props.dispatch({ type: "object/unstage" });
      this.props.dispatch({ type: "gcode/unstage" });
    }
  }

  handleSelectTab(selected: string) {
    let medias = this.props.medias;
    let selectedMedia = this.props.selectedMedia;
    if (selected === TOOLS_SECTION) {
      if (
        medias &&
        selectedMedia !== null &&
        medias[selectedMedia] &&
        medias[selectedMedia].centers.length > 1
      )
        this.handleMakeVisible(null);
      else this.handleMakeVisible(OBJECT);
    }
    if (selected === MODEL_SECTION) {
      this.handleMakeVisible(OBJECT);
      this.props.dispatch({
        type: "well/demo",
      });
    } else {
      this.props.dispatch({
        type: "well/nodemo",
      });
    }
    if (selected === SLICER_SECTION) {
      if (
        selectedMedia !== null &&
        medias &&
        medias[selectedMedia] &&
        medias[selectedMedia].centers.length > 1
      )
        this.handleMakeVisible(null);
      else this.handleMakeVisible(OBJECT);
    }
    if (selected === TISSUESTART_SECTION) {
      this.handleMakeVisible(GCODE);
    }
    if (selected === PRINT_SECTION) {
      this.handleMakeVisible(GCODE);
    }

    this.setState({
      selectedTab: selected,
    });
  }

  handleControlSectionStateChange = (
    newState: ControlSectionViewModelState,
  ) => {
    this.props.dispatch({
      type: "tissuestart/update-control-section",
      ...newState,
    });
  };

  handlePrintSectionStateChange = (newState: PrintSectionViewModelState) => {
    this.props.dispatch({
      type: "tissuestart/update-print-section",
      ...newState,
    });
  };

  getCenterMedia = () => {
    if (this.props.selectedMedia === null) {
      return { x: 0, y: 0 };
    }

    const selectedMedia = this.props.medias[this.props.selectedMedia!];

    if (selectedMedia.brand.length > 0) {
      return selectedMedia.brand[this.props.selectedBrand].center;
    }

    return selectedMedia.center;
  };

  render() {
    // Tools Section -----------------------------------------------------
    let toolSection: React.ReactElement | null = null;
    if (this.state.selectedTab === TOOLS_SECTION) {
      toolSection = (
        <SectionTool nextTab={() => this.handleSelectTab(MODEL_SECTION)} />
      );
    }

    // Object Section ----------------------------------------------------
    let objectSection: React.ReactElement | null = null;
    if (this.state.selectedTab === MODEL_SECTION) {
      objectSection = (
        <SectionObject nextTab={() => this.handleSelectTab(SLICER_SECTION)} />
      );
    }

    // Slicer Section ----------------------------------------------------
    let slicerSection: React.ReactElement | null = null;
    if (this.state.selectedTab === SLICER_SECTION) {
      slicerSection = (
        <SectionSlicer
          nextTab={() => this.handleSelectTab(TISSUESTART_SECTION)}
        />
      );
    }

    // TissueStart Section ----------------------------------------------------
    let controlSection: React.ReactElement | null = null;
    if (this.state.selectedTab === TISSUESTART_SECTION) {
      controlSection = (
        <ControlSection
          state={{
            isZSet: this.props.isTissuestartZSet,
            isHomeSet: this.props.isTissuestartHome,
            isHomeWarningShowed: this.props.isTissuestartHomeWarningShowed,
            distance: this.props.tissuestartDistance,
            alert: this.props.alert,
            toolOffSet: this.props.tools[this.props.selectedTool!].offset,
            centers: this.getCenterMedia(),
          }}
          printerConnection={this.props.printerConnection}
          disableMovement={this.props.tissuestartDisableMovement}
          onStateChange={this.handleControlSectionStateChange}
          priming={this.props.tissuestartPriming}
          showMixModeSlider={this.props.tissuestartShowMixModeSlider}
          printingStatus={this.props.printingStatus}
          mixtureRatio={this.props.tissuestartMixRatio}
          onMoveSyringeUp={() => this.vm.moveSyringeUp()}
          onMoveSyringeDown={() => this.vm.moveSyringeDown()}
          setMixtureRatio={(value) => this.vm.setMixtureRatio(value)}
          setPriming={(value) => this.vm.setPriming(value)}
          onDisconnect={() => this.vm.disconnect()}
          onConnect={() => this.vm.connect()}
          isSerialConnectionSupported={this.props.isSerialConnectionSupported}
          isPrintingViaServerMode={this.props.isPrintingViaServerMode}
          selectedPrinter={this.props.selectedPrinter}
          printers={this.props.printers}
          updateSelectedPrinter={(value) =>
            this.vm.updateSelectedPrinter(value)
          }
          updatePrintingMode={(value) => this.vm.updatePrintingMode(value)}
          mixMode={this.props.tissuestartMix}
          setMixMode={(value) => this.vm.setMixMode(value)}
        />
      );
    }

    // print Section ----------------------------------------------------
    let printSection: React.ReactElement | null = null;
    if (this.state.selectedTab === PRINT_SECTION) {
      printSection = (
        <PrintSection
          state={{
            light365: this.props.tissuestartLight365,
            light405: this.props.tissuestartLight405,
            printerLight: this.props.tissuestartPrinterLight,
            alert: this.props.alert,
            flow: this.props.tissuestartFlow,
            speed: this.props.tissuestartSpeed,
          }}
          mixMode={this.props.tissuestartMix}
          priming={this.props.tissuestartPriming}
          printingStatus={this.props.printingStatus}
          showMixModeSlider={this.props.tissuestartShowMixModeSlider}
          mixtureRatio={this.props.tissuestartMixRatio}
          onStateChange={this.handlePrintSectionStateChange}
          onDisconnect={() => this.vm.disconnect()}
          onMoveSyringeUp={() => this.vm.moveSyringeUp()}
          onMoveSyringeDown={() => this.vm.moveSyringeDown()}
          setMixtureRatio={(value) => this.vm.setMixtureRatio(value)}
          setPriming={(value) => this.vm.setPriming(value)}
          gcodes={this.props.gcodes}
          selectedGcodes={this.props.selectedGcodes}
        />
      );
    }

    return (
      <Sidebar>
        <SidebarHeader>
          <SidebarLogo src={logo} />
          <SidebarTitle />
        </SidebarHeader>

        <SidebarTabs>
          <SectionStepsControl
            radios={[
              {
                name: "tools",
                value: TOOLS_SECTION,
                disabled: this.props.printingStatus === "printing",
              },
              {
                name: "model",
                value: MODEL_SECTION,
                disabled: this.props.printingStatus === "printing",
              },
              {
                name: "slicer",
                value: SLICER_SECTION,
                disabled: this.props.printingStatus === "printing",
              },
              {
                name: "control",
                value: TISSUESTART_SECTION,
                disabled: this.props.gcodes && this.props.gcodes.length === 0,
              },
              {
                name: "print",
                value: PRINT_SECTION,
                disabled: this.props.gcodes && this.props.gcodes.length === 0,
              },
            ]}
            selected={this.state.selectedTab}
            onSelect={(selected) => this.handleSelectTab(selected)}
          />
        </SidebarTabs>

        <SidebarBody>
          <HelpBtn
            section={this.state.selectedTab}
            isNewbie={this.state.isNewbie}
            onClickScreenProtector={() => this.setState({ isNewbie: false })}
          />
          {toolSection}
          {objectSection}
          {slicerSection}
          {controlSection}
          {printSection}

          {(this.state.selectedTab === TISSUESTART_SECTION ||
            this.state.selectedTab === PRINT_SECTION) && (
            <FloatActions
              printProgress={this.props.tissuestartPrintProgress}
              printStatus={this.props.printingStatus}
              onPlay={() => this.vm.print()}
              onResume={() => this.vm.resume()}
              onPause={() => this.vm.pause()}
              onCancel={() => this.vm.cancel()}
            />
          )}

          <ConfirmModal
            title="Printer Update"
            description="There is a software update available for your printer. Do you want to update it now?"
            show={!!this.props.firmwareToUpdate}
            onResponse={(confirmed) =>
              this.vm.handleUserSoftwareUpdateResponse(confirmed)
            }
            acceptLabel="Update"
          />

          <ConfirmModal
            title="Cancel Printing"
            description="Are you sure you want to cancel printing?"
            show={!!this.props.isCancelingPrinting}
            onResponse={(confirmed) => this.vm.handleCancelPrinting(confirmed)}
            acceptLabel="Cancel"
          />
        </SidebarBody>
      </Sidebar>
    );
  }
}

export default withRouter(connect(mapStateToProps)(SidebarTissueStart));
