import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const CustomSlider = withStyles({
  thumb: {
    color: "gray",
  },
  root: {
    width: "23vw",
    fontSize: "1rem",
  },
  track: {
    color: "gray",
  },
  rail: {
    color: "gray",
  },
  valueLabel: {
    left: "calc(-50% - 11px)",
    borderRadius: "45% 45% 45% 0% !important",
    "&>*": {
      background: "gray",
      fontSize: "0.75rem",
      width: "48px",
      height: "48px",
      transform: "scale(1) translateY(-30px) rotate(-45deg) !important",
    },
  },
  markLabel: {
    color: "gray",
    marginLeft: "1vw",
    fontSize: "1vw",
  },
})(Slider);

interface MarkSliderProps {
  marks:
    | (number | string)[]
    | { value: number | string; label: number | string }[];
  defaultValue: number;
  step: number;
  min: number;
  max: number;
  onChange: (val: number) => void;
  valueLabelFormat?:
    | string
    | ((value: number, index: number) => React.ReactNode)
    | undefined;
  onChangeCommitted: (val: number) => void;
}

interface MarkSliderState {
  initialValue: number;
}

class MarkSlider extends Component<MarkSliderProps, MarkSliderState> {
  constructor(props: MarkSliderProps) {
    super(props);

    this.state = {
      initialValue: this.props.defaultValue,
    };
  }

  render() {
    let marks = this.props.marks;
    if (
      (marks[0] as any).value === undefined ||
      (marks[0] as any).label === undefined
    ) {
      marks = marks.map((mark) => ({
        value: mark as string,
        label: mark as string,
      }));
    }

    return (
      <CustomSlider
        defaultValue={this.state.initialValue}
        step={this.props.step}
        min={this.props.min}
        max={this.props.max}
        marks={marks as any[]}
        valueLabelDisplay="auto"
        valueLabelFormat={this.props.valueLabelFormat}
        onChange={(_e, val) => this.props.onChange(val as number)}
        onChangeCommitted={(_e, val) =>
          this.props.onChangeCommitted(val as number)
        }
      />
    );
  }
}

export default MarkSlider;
