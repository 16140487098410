import {
  UserProjectGcode,
  UserProjectGcodeWithDetails,
} from "../../../../application/models/UserProjectGcode";
import {
  UserProjectGcodeRaw,
  UserProjectGcodeWithDetailsRaw,
} from "../../models/UserProjectGcodeRaw";
import { UserProjectGcodeMapper } from "../UserProjectGcodeMapper";

export class UserProjectGcodeMapperImpl implements UserProjectGcodeMapper {
  async userProjectGcodeRawToUserProjectGcode(
    userProjectGcodeRaw: UserProjectGcodeRaw
  ): Promise<UserProjectGcode> {
    return {
      id: userProjectGcodeRaw.id,
      name: userProjectGcodeRaw.name,
      fileAddress: userProjectGcodeRaw.fileAddress,
      userId: userProjectGcodeRaw.userId,
      projectId: userProjectGcodeRaw.projectId,
      createdAt: new Date(userProjectGcodeRaw.createdAt),
      updatedAt: new Date(userProjectGcodeRaw.updatedAt),
    };
  }

  async userProjectGcodeWithDetailsRawToUserProjectGcodeWithDetails(
    userProjectGcodeWithDetailsRaw: UserProjectGcodeWithDetailsRaw
  ): Promise<UserProjectGcodeWithDetails> {
    return {
      id: userProjectGcodeWithDetailsRaw.id,
      name: userProjectGcodeWithDetailsRaw.name,
      fileAddress: userProjectGcodeWithDetailsRaw.fileAddress,
      userId: userProjectGcodeWithDetailsRaw.userId,
      projectId: userProjectGcodeWithDetailsRaw.projectId,
      createdAt: new Date(userProjectGcodeWithDetailsRaw.createdAt),
      updatedAt: new Date(userProjectGcodeWithDetailsRaw.updatedAt),
      details: userProjectGcodeWithDetailsRaw.details,
    };
  }
}
