import {
  UserTissueModel,
  UserTissueModelFolder,
} from "../../../../application/models/UserTissueModel";
import {
  UserTissueModelFolderRaw,
  UserTissueModelRaw,
} from "../../models/UserTissueModelRaw";
import { UserTissueModelMapper } from "../UserTissueModelMapper";

export class UserTissueModelMapperImpl implements UserTissueModelMapper {
  async userTissueModelRawToUserTissueModel(
    userTissueModelRaw: UserTissueModelRaw
  ): Promise<UserTissueModel> {
    return {
      id: userTissueModelRaw.id,
      folder: userTissueModelRaw.folder,
      name: userTissueModelRaw.name,
      fileAddress: userTissueModelRaw.fileAddress,
      thumbnailAddress: userTissueModelRaw.thumbnailAddress,
      createdAt: new Date(userTissueModelRaw.createdAt),
      updatedAt: new Date(userTissueModelRaw.updatedAt),
    };
  }

  async userTissueModelFolderRawToUserTissueModelFolder(
    userTissueModelFolderRaw: UserTissueModelFolderRaw
  ): Promise<UserTissueModelFolder> {
    return {
      name: userTissueModelFolderRaw.name,
      modelCount: userTissueModelFolderRaw.modelCount,
    };
  }
}
