import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import "./override.css";
import { adminContainer } from "./admin/framework/di/container";
import { AdminPage } from "./admin/framework/presentation/react/pages";
import { UserPages } from "./user/framework/presentation/react/pages";

const App = () => (
  <>
    <Router>
      <Switch>
        <Route exact path="/admin">
          <AdminPage
            authProvider={adminContainer.reactAdminProviders.auth}
            dataProvider={adminContainer.reactAdminProviders.data}
          />
        </Route>
      </Switch>
    </Router>

    <HashRouter>
      <UserPages />
    </HashRouter>
  </>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
