import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  Record,
} from "react-admin";

import { AdminTissueModelRepository } from "../../../../application/data/repositories/AdminTissueModelRepository";
import { AdminFileProvider } from "../../../../application/providers/AdminFileProvider";

export class AdminTissueModelProviderImpl {
  constructor(
    private fileProvider: AdminFileProvider,
    private tissueModelRepository: AdminTissueModelRepository
  ) {}

  async getList<RecordType extends Record = Record>(): Promise<
    GetListResult<RecordType>
  > {
    const data = await this.tissueModelRepository.findAll();
    return { data: data as unknown as RecordType[], total: data.length };
  }

  async create<RecordType extends Record = Record>(
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const thumbnail = params.data.thumbnail;
    const file = params.data.file;

    const [thumbnailBase64, fileBase64, fileExtension, thumbnailExtension] =
      await Promise.all([
        this.fileProvider.convertFileToBase64(thumbnail.rawFile),
        this.fileProvider.convertFileToBase64(file.rawFile),
        this.fileProvider.extractExtensionFromFileName(file.rawFile.name),
        this.fileProvider.extractExtensionFromFileName(thumbnail.rawFile.name),
      ]);

    const data = await this.tissueModelRepository.create({
      folder: params.data.folder,
      name: params.data.name,
      file: {
        content: fileBase64,
        extension: fileExtension,
      },
      thumbnail: {
        content: thumbnailBase64,
        extension: thumbnailExtension,
      },
    });

    return { data: data as unknown as RecordType };
  }

  async delete<RecordType extends Record = Record>(
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    await this.tissueModelRepository.delete(parseInt(params.id.toString()));
    return { data: {} as RecordType };
  }

  async deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    await Promise.all(
      params.ids.map((id) =>
        this.tissueModelRepository.delete(parseInt(id.toString()))
      )
    );

    return { data: [] };
  }
}
