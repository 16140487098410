import {
  UserProject,
  UserProjectType,
  UserProjectWithState,
  userProjectTypes,
} from "../../../../application/models/UserProject";
import {
  UserProjectRaw,
  UserProjectWithStateRaw,
} from "../../models/UserProjectRaw";
import { UserProjectMapper } from "../UserProjectMapper";

export class UserProjectMapperImpl implements UserProjectMapper {
  async userProjectRawToUserProject(
    userProjectRaw: UserProjectRaw
  ): Promise<UserProject> {
    if (!userProjectTypes.includes(userProjectRaw.type as UserProjectType)) {
      throw new Error("Invalid project type.");
    }

    return {
      id: userProjectRaw.id,
      name: userProjectRaw.name,
      userId: userProjectRaw.userId,
      thumbnailAddress: userProjectRaw.thumbnailAddress,
      type: userProjectRaw.type as UserProjectType,
      createdAt: new Date(userProjectRaw.createdAt),
      updatedAt: new Date(userProjectRaw.updatedAt),
    };
  }

  async userProjectWithStateRawToUserProjectWithState(
    userProjectWithStateRaw: UserProjectWithStateRaw
  ): Promise<UserProjectWithState> {
    const validProjectType = userProjectTypes.includes(
      userProjectWithStateRaw.type as UserProjectType
    );

    if (!validProjectType) {
      throw new Error("Invalid project type.");
    }

    return {
      id: userProjectWithStateRaw.id,
      name: userProjectWithStateRaw.name,
      userId: userProjectWithStateRaw.userId,
      state: userProjectWithStateRaw.state,
      thumbnailAddress: userProjectWithStateRaw.thumbnailAddress,
      type: userProjectWithStateRaw.type as UserProjectType,
      createdAt: new Date(userProjectWithStateRaw.createdAt),
      updatedAt: new Date(userProjectWithStateRaw.updatedAt),
    };
  }
}
