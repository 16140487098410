import {
  UserFirmware,
  UserFirmwareType,
  userFirmwareTypes,
} from "../../../../application/models/UserFirmware";
import {
  UserPrinterModel,
  userPrinterModels,
} from "../../../../application/models/UserPrinter";
import { UserFirmwareRaw } from "../../models/UserFirmwareRaw";
import { UserFirmwareMapper } from "../UserFirmwareMapper";

export class UserFirmwareMapperImpl implements UserFirmwareMapper {
  async userFirmwareRawToUserFirmware(
    userFirmwareRaw: UserFirmwareRaw
  ): Promise<UserFirmware> {
    if (
      !userPrinterModels.includes(
        userFirmwareRaw.printerModel as UserPrinterModel
      )
    ) {
      throw new Error("Invalid printer model.");
    }

    if (!userFirmwareTypes.includes(userFirmwareRaw.type as UserFirmwareType)) {
      throw new Error("Invalid printer model.");
    }

    return {
      id: userFirmwareRaw.id,
      printerModel: userFirmwareRaw.printerModel as UserPrinterModel,
      type: userFirmwareRaw.type as UserFirmwareType,
      version: userFirmwareRaw.version,
      fileName: userFirmwareRaw.fileName,
      fileAddress: userFirmwareRaw.fileAddress,
      createdAt: new Date(userFirmwareRaw.createdAt),
      updatedAt: new Date(userFirmwareRaw.updatedAt),
    };
  }
}
