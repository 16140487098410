import React from "react";
import { Carousel } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { Popup } from "../../../redux/Popup";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";

interface CarouseBtnProps {
  onClick: () => void;
}

interface CarouselBtnState {
  isHovered: boolean;
}

export class CarouselBtn extends React.Component<
  CarouseBtnProps,
  CarouselBtnState
> {
  constructor(props: CarouseBtnProps) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  render() {
    return (
      <span
        className="next-btn-carousel"
        onClick={(_e) => this.props.onClick()}
        style={{
          color: this.state.isHovered ? "#ff005e" : "#ff80ae",
          fontSize: this.state.isHovered ? "1.3vw" : "1.25vw",
          cursor: "pointer",
        }}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        {this.props.children}
      </span>
    );
  }
}

interface MyCarouselProps
  extends React.HTMLAttributes<HTMLDivElement>,
    AppState,
    DispatchProp {
  refresh?: () => Promise<void>;
}

interface MyCarouselState {
  carouselIndex: number;
  isPrevHovered: boolean;
  isNextHovered: boolean;
}

class MyCarousel extends React.Component<MyCarouselProps, MyCarouselState> {
  constructor(props: MyCarouselProps) {
    super(props);

    this.state = {
      carouselIndex: 0,
      isPrevHovered: false,
      isNextHovered: false,
    };
  }

  async handleNextCarousel(itemNumber: number) {
    try {
      if (this.state.carouselIndex + 1 < itemNumber) {
        this.setState({ carouselIndex: this.state.carouselIndex + 1 });
      } else if (
        this.state.carouselIndex + 1 >= itemNumber &&
        this.props.refresh
      ) {
        await this.props.refresh();

        if (this.state.carouselIndex + 1 < (this.props.children as any)?.length)
          this.setState({ carouselIndex: this.state.carouselIndex + 1 });
      }
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    }
  }

  handlePrevCarousel() {
    if (this.state.carouselIndex > 0) {
      this.setState({ carouselIndex: this.state.carouselIndex - 1 });
    }
  }

  render() {
    let itemNumber = (this.props.children as any)?.length;

    let nextBtn = (
      <CarouselBtn onClick={() => this.handleNextCarousel(itemNumber)}>
        <i className="fas fa-angle-right fa-3x" />
      </CarouselBtn>
    );
    let prevBtn = (
      <CarouselBtn onClick={() => this.handlePrevCarousel()}>
        <i className="fas fa-angle-left fa-3x" />
      </CarouselBtn>
    );

    return (
      <Carousel
        activeIndex={this.state.carouselIndex}
        nextIcon={nextBtn}
        prevIcon={prevBtn}
      >
        {this.props.children}
      </Carousel>
    );
  }
}

export default connect(mapStateToProps)(MyCarousel);
