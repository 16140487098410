import { useMemo } from "react";
import { Button } from "react-bootstrap";

import Section from "../../../components/shared/section";
import {
  PrintSectionViewModel,
  PrintSectionViewModelState,
} from "./PrintSectionViewModel";
import { userContainer } from "../../../../../di/container";
import { PrintAdjustment } from "./PrintAdjustment";
import { SyringePrimingControls } from "../../../components/shared/SyringePrimingControls";
import { ViewModelAlertModal } from "../../../components/shared/ViewModelAlertModal";
import { PrintingStatus } from "../../../../models/PrintingStatus";
import { PrintPreviewAndDownload } from "./PrintPreviewAndDownload";
import { Gcode } from "../../../../models/GcodeState";
import { MixMode } from "../../../../models/TissuestartState";

interface PrintSectionProps {
  state: PrintSectionViewModelState;
  mixMode: MixMode;
  mixtureRatio: number;
  priming: number;
  gcodes: Gcode[];
  selectedGcodes: (number | null)[];
  printingStatus: PrintingStatus;
  showMixModeSlider: boolean;
  onStateChange: (state: PrintSectionViewModelState) => void;
  onDisconnect: () => void;
  onMoveSyringeUp: () => void;
  onMoveSyringeDown: () => void;
  setMixtureRatio: (value: number) => void;
  setPriming: (value: number) => void;
}

export const PrintSection = ({
  state,
  mixtureRatio,
  printingStatus,
  priming,
  showMixModeSlider,
  gcodes,
  selectedGcodes,
  mixMode,
  onStateChange,
  onDisconnect,
  onMoveSyringeDown,
  onMoveSyringeUp,
  setMixtureRatio,
  setPriming,
}: PrintSectionProps) => {
  const viewModel = useMemo(() => {
    const vm = new PrintSectionViewModel(
      userContainer.services.printing,
      userContainer.providers.gcodeLayer,
      userContainer.providers.commandGcode,
      state,
    );

    vm.setStateChangeCallback((newState) => {
      onStateChange(newState);
    });

    return vm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStateChange]);

  return (
    <Section className="print-section">
      {printingStatus !== "disconnected" && (
        <Button variant="danger" onClick={() => onDisconnect()}>
          Disconnect
        </Button>
      )}

      {printingStatus !== "disconnected" && (
        <>
          <PrintAdjustment
            light365={state.light365}
            light405={state.light405}
            printerLight={state.printerLight}
            flow={state.flow}
            mixtureRatio={mixtureRatio}
            showMixModeSlider={showMixModeSlider}
            onChangeMixtureRatio={(value) => setMixtureRatio(value)}
            onChangePrinterLight={(value) => viewModel.setPrinterLight(value)}
            onChangeFlow={(value) => viewModel.setFlow(value)}
            onChangeLight365={(value) => viewModel.setLight365(value)}
            onChangeLight405={(value) => viewModel.setLight405(value)}
            speed={state.speed}
            onChangeSpeed={(value) => viewModel.setSpeed(value)}
          />

          <SyringePrimingControls
            onPrimingChange={(value) => setPriming(value)}
            priming={priming}
            onSyringeUp={() => onMoveSyringeUp()}
            onSyringeDown={() => onMoveSyringeDown()}
            disable={mixMode === ""}
          />
        </>
      )}

      <PrintPreviewAndDownload
        gcodes={gcodes}
        selectedGcodes={selectedGcodes}
        onGcodeLayerChange={(layer: number) =>
          viewModel.changeGcodeSelectedLayer(layer, selectedGcodes, gcodes)
        }
      />

      <ViewModelAlertModal
        alert={state.alert}
        onClose={() => viewModel.clearAlert()}
      />
    </Section>
  );
};
