import React from "react";
import { Modal, Carousel } from "react-bootstrap";

import MyCarousel from "./myCarousel";

const tutorials = {
  model: [
    require("../../../assets/tutorials/model/2-1.jpg"),
    require("../../../assets/tutorials/model/2-2.gif"),
    require("../../../assets/tutorials/model/2-3.gif"),
    require("../../../assets/tutorials/model/2-4.gif"),
    require("../../../assets/tutorials/model/2-5.gif"),
    require("../../../assets/tutorials/model/2-6.gif"),
    require("../../../assets/tutorials/model/2-7.gif"),
  ],
  pipette: [
    require("../../../assets/tutorials/pipette/1.jpg"),
    require("../../../assets/tutorials/pipette/2.gif"),
    require("../../../assets/tutorials/pipette/3.gif"),
  ],
  slicer: [
    require("../../../assets/tutorials/slicer/3-1.jpg"),
    require("../../../assets/tutorials/slicer/3-2.gif"),
    require("../../../assets/tutorials/slicer/3-3.jpg"),
    require("../../../assets/tutorials/slicer/3-4.jpg"),
    require("../../../assets/tutorials/slicer/3-5.gif"),
  ],
  tissuestart: [
    require("../../../assets/tutorials/tissuestart/4-1.jpg"),
    require("../../../assets/tutorials/tissuestart/4-2.gif"),
    require("../../../assets/tutorials/tissuestart/4-3.gif"),
    require("../../../assets/tutorials/tissuestart/4-4.gif"),
  ],
  tissuestartprint: [
    require("../../../assets/tutorials/tissuestartprint/5-1.jpg"),
    require("../../../assets/tutorials/tissuestartprint/5-2.gif"),
    require("../../../assets/tutorials/tissuestartprint/5-3.gif"),
    require("../../../assets/tutorials/tissuestartprint/5-4.jpg"),
    require("../../../assets/tutorials/tissuestartprint/5-5.gif"),
  ],
  tools: [
    require("../../../assets/tutorials/tools/1-1.jpg"),
    require("../../../assets/tutorials/tools/1-2.gif"),
    require("../../../assets/tutorials/tools/1-3.gif"),
    require("../../../assets/tutorials/tools/1-4.gif"),
    require("../../../assets/tutorials/tools/1-5.jpg"),
    require("../../../assets/tutorials/tools/1-6.gif"),
    require("../../../assets/tutorials/tools/1-7.gif"),
    require("../../../assets/tutorials/tools/1-8.gif"),
    require("../../../assets/tutorials/tools/1-9.gif"),
    require("../../../assets/tutorials/tools/1-10.gif"),
  ],
};

class Content extends React.Component {
  render() {
    return (
      <div
        style={{
          height: "34vw",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

interface TutorialModalProps {
  section: any;
  onHide: () => void;
}

interface TutorialModalState {
  carouselItems: any[];
}

class TutorialModal extends React.Component<
  TutorialModalProps,
  TutorialModalState
> {
  constructor(props: TutorialModalProps) {
    super(props);

    this.state = {
      carouselItems: [],
    };
  }

  componentDidUpdate(prevProps: TutorialModalProps) {
    if (this.props.section !== prevProps.section) {
      const files =
        tutorials[this.props.section as keyof typeof tutorials] || [];

      if (!files || files.length <= 0)
        return this.setState({
          carouselItems: [],
        });

      let carouselItems = files.map((file) => (
        <Carousel.Item key={String(file)}>
          <Content>
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              src={file}
              alt="tutorial"
            />
          </Content>
        </Carousel.Item>
      ));

      this.setState({
        carouselItems,
      });
    }
  }

  handleHide() {
    this.setState({
      carouselItems: [],
    });
    this.props.onHide();
  }

  render() {
    let carouselItems = <MyCarousel>{this.state.carouselItems}</MyCarousel>;

    return (
      <div>
        <Modal
          variant="dark"
          show={this.props.section || this.props.section === 0 ? true : false}
          onHide={() => this.handleHide()}
          size="lg"
        >
          <Modal.Header className="tutorial-title" closeButton></Modal.Header>
          <Modal.Body className="tutorial-modal">{carouselItems}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default TutorialModal;
