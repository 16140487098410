import { AdminTissueModel } from "../../../application/models/AdminTissueModel";
import { AdminTissueModelRaw } from "../models/AdminTissueModelRaw";
import {
  AdminTissueModelCreateParams,
  AdminTissueModelRepository,
} from "../../../application/data/repositories/AdminTissueModelRepository";
import { AdminTissueModelMapper } from "../mappers/AdminTissueModelMapper";
import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";

export class AdminTissueModelRepositoryImpl
  implements AdminTissueModelRepository
{
  constructor(
    private httpProvider: HttpProvider,
    private tissueModelMapper: AdminTissueModelMapper
  ) {}

  async findAll(): Promise<AdminTissueModel[]> {
    const response = await this.httpProvider.request<AdminTissueModelRaw[]>({
      method: "GET",
      url: "/tissue-model",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.tissueModelMapper.tissueModelRawToTissueModel(e)
      )
    );
  }

  async create(
    params: AdminTissueModelCreateParams
  ): Promise<AdminTissueModel> {
    const response = await this.httpProvider.request<AdminTissueModelRaw>({
      method: "POST",
      url: "/tissue-model",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.tissueModelMapper.tissueModelRawToTissueModel(response.body);
  }

  async delete(id: number): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/tissue-model/${id}`,
    });
  }
}
