import { withRouter } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";
import { ListProps } from "@material-ui/core";

const SettingsList = (props: ListProps) => {
  return (
    <List pagination={false} {...props}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="type" sortable={false} />
        <EditButton basePath="/settings" />
        <DeleteButton basePath="/settings" />
      </Datagrid>
    </List>
  );
};

export default withRouter(SettingsList);
