import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetOneParams,
  GetOneResult,
  Record,
  UpdateParams,
  UpdateResult,
} from "react-admin";

import { AdminUserRepository } from "../../../../application/data/repositories/AdminUserRepository";

export class AdminUserProviderImpl {
  constructor(private userRepository: AdminUserRepository) {}

  async getList<RecordType extends Record = Record>(): Promise<
    GetListResult<RecordType>
  > {
    const data = await this.userRepository.findAll();
    return { data: data as unknown as RecordType[], total: data.length };
  }

  async getOne<RecordType extends Record = Record>(
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const data = await this.userRepository.findById(
      parseInt(params.id.toString())
    );

    const formattedData = {
      ...data,
      printers: data.printers.map((e) => e.id),
    };

    return { data: formattedData as unknown as RecordType };
  }

  async create<RecordType extends Record = Record>(
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const data = await this.userRepository.create(params.data);
    return { data: data as unknown as RecordType };
  }

  async update<RecordType extends Record = Record>(
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const data = await this.userRepository.update({
      id: parseInt(params.id.toString()),
      name: params.data.name,
      role: params.data.role,
      settings: params.data.settings,
      printers: params.data.printers,
      password: params.data.password,
    });

    return { data: data as unknown as RecordType };
  }

  async delete<RecordType extends Record = Record>(
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    await this.userRepository.delete(parseInt(params.id.toString()));
    return { data: {} as RecordType };
  }

  async deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    await Promise.all(
      params.ids.map((id) =>
        this.userRepository.delete(parseInt(id.toString()))
      )
    );

    return { data: [] };
  }
}
