import React from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Radio from "../../../components/shared/radio";

interface PrinterControlAxisProps {
  isHomeSet: boolean;
  disableMovement: boolean;
  isZSet: boolean;
  distance: number;
  onHomePrinter: () => void;
  onSetZ: () => void;
  onMoveForward: () => void;
  onMoveBackward: () => void;
  onMoveLeft: () => void;
  onMoveRight: () => void;
  onMoveUpZ: () => void;
  onMoveDownZ: () => void;
  onDistanceChange: (distance: number) => void;
  onCenter: () => void;
}

export const PrinterControlAxis: React.FC<PrinterControlAxisProps> = ({
  isHomeSet,
  isZSet,
  distance,
  disableMovement,
  onHomePrinter,
  onSetZ,
  onMoveForward,
  onMoveBackward,
  onMoveLeft,
  onMoveRight,
  onMoveUpZ,
  onMoveDownZ,
  onDistanceChange,
  onCenter,
}) => {
  const handleMoveForward = () => {
    onMoveForward();
  };

  const handleMoveBackward = () => {
    onMoveBackward();
  };

  const handleMoveLeft = () => {
    onMoveLeft();
  };

  const handleMoveRight = () => {
    onMoveRight();
  };

  const handleMoveUp = () => {
    onMoveUpZ();
  };

  const handleMoveDown = () => {
    onMoveDownZ();
  };

  const handleSetZ = () => {
    onSetZ();
  };

  const handleSetDistance = (distance: number) => {
    onDistanceChange(distance);
  };

  const handleGoToCenter = () => {
    onCenter();
  };

  return (
    <Card
      className="axis-card"
      style={{
        gridColumn: "1",
        gridColumnEnd: "3",
        gap: "1vw",
        padding: "1vw",
      }}
    >
      <p>move axis</p>

      <div className="axis-control" style={{ padding: "0 2vw 0 2vw" }}>
        <div
          className="control-pad"
          style={{
            textAlign: "center",
            display: "inline-block",
          }}
        >
          <div className="up-arrow">
            <Button
              onClick={() => handleMoveForward()}
              variant="secondary"
              disabled={disableMovement}
            >
              &#9652;
            </Button>
          </div>
          <div className="middle">
            <Button
              onClick={() => handleMoveLeft()}
              variant="secondary"
              disabled={disableMovement}
            >
              &#9666;
            </Button>
            <Button
              onClick={onHomePrinter}
              variant={isHomeSet ? "info" : "secondary"}
              disabled={disableMovement}
            >
              <i className="fa fa-home" />
            </Button>
            <Button
              onClick={() => handleMoveRight()}
              variant="secondary"
              disabled={disableMovement}
            >
              &#9656;
            </Button>
          </div>
          <div className="down-arrow">
            <Button
              onClick={() => handleMoveBackward()}
              variant="secondary"
              disabled={disableMovement}
            >
              &#9662;
            </Button>
          </div>
        </div>
        <Button
          onClick={() => handleGoToCenter()}
          variant="secondary"
          disabled={disableMovement}
        >
          center
        </Button>

        <div
          className="z-pad"
          style={{
            textAlign: "center",
            display: "inline-block",
            marginLeft: "3vw",
          }}
        >
          <div className="up-arrow">
            <Button
              onClick={() => handleMoveUp()}
              variant="secondary"
              disabled={disableMovement}
            >
              &#9652;
            </Button>
          </div>
          <div className="middle">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="set z">set z=0</Tooltip>}
            >
              <Button
                onClick={() => handleSetZ()}
                variant={isZSet ? "info" : "secondary"}
                className="z-button"
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontWeight: "900",
                }}
                disabled={disableMovement}
              >
                <u>Z</u>
              </Button>
            </OverlayTrigger>
          </div>
          <div className="down-arrow">
            <Button
              onClick={() => handleMoveDown()}
              variant="secondary"
              disabled={disableMovement}
            >
              &#9662;
            </Button>
          </div>
        </div>
      </div>

      <div className="dist-control">
        <p>distance (mm):</p>
        <Radio
          radios={[
            { name: 0.1, value: 0.1 },
            { name: 1, value: 1 },
            { name: 10, value: 10 },
            { name: 25, value: 25 },
          ]}
          disabled={disableMovement}
          selected={distance}
          onSelect={(selected) => handleSetDistance(Number(selected))}
          variant="secondary"
        />
      </div>
    </Card>
  );
};
