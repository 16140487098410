import { CommandGcodeProvider } from "../../../../../../application/providers/CommandGcodeProvider";
import {
  PrintingService,
} from "../../../../../../application/services/PrintingService";
import { GCodeLayerProvider } from "../../../../../providers/GCodeLayerProvider";
import { Gcode } from "../../../../models/GcodeState";
import { ProjectAlert } from "../../../../models/ProjectState";
import { ViewModel } from "../../../common/ViewModel";

export interface PrintSectionViewModelState {
  alert: ProjectAlert | null;
  flow: number;
  speed: number;
  light365: number;
  light405: number;
  printerLight: number;
}

export class PrintSectionViewModel extends ViewModel<PrintSectionViewModelState> {
  constructor(
    private printingService: PrintingService,
    private gcodeLayerProvidary: GCodeLayerProvider,
    private commandGcodeProvider: CommandGcodeProvider,
    state: PrintSectionViewModelState
  ) {
    super(state);
  }

  async setFlow(value: number) {
    try {
      const commands = await this.commandGcodeProvider.setFlow(value);
      await this.printingService.sendCommands(commands);

      this.changeState({
        ...this.state,
        flow: value,
      });
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async setSpeed(value: number) {
    try {
      const commands = await this.commandGcodeProvider.setSpeed(value);
      await this.printingService.sendCommands(commands);

      this.changeState({
        ...this.state,
        speed: value,
      });
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async setLight365(value: number) {
    try {
      this.changeState({
        ...this.state,
        light365: value,
      });

      const commands = await this.commandGcodeProvider.setLight365(value);
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async setLight405(value: number) {
    const commands = await this.commandGcodeProvider.setLight405(value);
    await this.printingService.sendCommands(commands);

    this.changeState({
      ...this.state,
      light405: value,
    });
  }

  async setPrinterLight(value: number) {
    const commands = await this.commandGcodeProvider.setPrinterLight(value);
    await this.printingService.sendCommands(commands);

    this.changeState({
      ...this.state,
      printerLight: value,
    });
  }

  async clearAlert() {
    this.changeState({ ...this.state, alert: null });
  }

  async changeGcodeSelectedLayer(layerNumber: number, selectedGcodes: (number | null)[], gcodes: Gcode[]) {
    try {
      const gcodeIndex = selectedGcodes[0];
      if (gcodeIndex === null) return;

      const gcode = gcodes[gcodeIndex];

      await this.gcodeLayerProvidary.makeLayerVisible(gcode, layerNumber);
    } catch (err: unknown) {
      this.handleError("Failed to select Gcode layer.", err);
    }
  }

  private handleError(title: string, err: unknown) {
    const message = err instanceof Error ? err.message : String(err);

    this.changeState({
      ...this.state,
      alert: {
        title,
        message,
        type: "viewModelAlert",
      },
    });
  }
}
