import {
  CommandGcodeProvider,
  MoveToCenterParams,
} from "../../../application/providers/CommandGcodeProvider";
import { MixMode } from "../../presentation/models/TissuestartState";

export class CommandGcodeProviderImpl implements CommandGcodeProvider {
  constructor(
    private printerFeedrate: number,
    private printerZSafetyHeight: number,
  ) {}

  async moveToHome(): Promise<string[]> {
    return ["G28 X Y", "M420 S1"];
  }

  async moveForward(distance: number): Promise<string[]> {
    return ["G91", `G0 Y-${distance} F${this.printerFeedrate}`, `G90`];
  }

  async moveBackward(distance: number): Promise<string[]> {
    return ["G91", `G0 Y${distance} F${this.printerFeedrate}`, `G90`];
  }

  async moveLeft(distance: number): Promise<string[]> {
    return ["G91", `G0 X${distance} F${this.printerFeedrate}`, `G90`];
  }

  async moveRight(distance: number): Promise<string[]> {
    return ["G91", `G0 X-${distance} F${this.printerFeedrate}`, `G90`];
  }

  async moveUpZ(distance: number): Promise<string[]> {
    return ["G91", `G0 Z-${distance} F${this.printerFeedrate}`, `G90`];
  }

  async moveDownZ(distance: number): Promise<string[]> {
    return ["G91", `G0 Z${distance} F${this.printerFeedrate}`, `G90`];
  }

  async moveToCenter({
    height,
    toolOffset,
    centers,
  }: MoveToCenterParams): Promise<string[]> {
    return [
      "G90",
      `G0 Z${height + this.printerZSafetyHeight}`,
      `G0 X${centers.x + toolOffset.x} Y${centers.y + toolOffset.y} F${
        this.printerFeedrate
      }`,
    ];
  }

  async setZAxis(): Promise<string[]> {
    return ["G92 Z0"];
  }

  async setLight365(intensity: number): Promise<string[]> {
    const normalizedIntensity = this.ensurePercentageBounds(intensity);
    return [`M106 S${normalizedIntensity}`];
  }

  async setLight405(intensity: number): Promise<string[]> {
    const normalizedIntensity = this.ensurePercentageBounds(intensity);
    return [`M106 S${normalizedIntensity} P1`];
  }

  async setPrinterLight(intensity: number): Promise<string[]> {
    const normalizedIntensity = this.ensurePercentageBounds(intensity);
    return [`M358 P${normalizedIntensity}`];
  }

  async setFlow(flow: number): Promise<string[]> {
    const normalizedIntensity = this.ensurePercentageBounds(flow, 0, 300);
    return [`M221 S${normalizedIntensity}`];
  }

  async setSpeed(speed: number): Promise<string[]> {
    const normalizedSpeed = speed * 100;
    return [`M220 S${normalizedSpeed}`];
  }

  async setPrintingMode(
    printingMode: MixMode,
    mixtureRatio: number,
  ): Promise<string[]> {
    const mixProportions = this.getMixProportions(printingMode, mixtureRatio);

    const modeMapper: { [key in MixMode]: string[] } = {
      left: ["G101", mixProportions],
      right: ["G102", mixProportions],
      mixtrusor: ["G103", mixProportions],
      coaxial: ["G101", mixProportions],
      "": ["G104"],
    };

    return modeMapper[printingMode];
  }

  async moveSyringePrimingUp(
    priming: number,
    printingMode: MixMode,
    mixtureRatio: number,
  ): Promise<string[]> {
    const mixProportions = this.getMixProportions(printingMode, mixtureRatio);

    return [
      `M200 S1 D12.07`,
      mixProportions,
      `G91`,
      `G1 E-${priming} F500`,
      `G90`,
    ];
  }

  async moveSyringePrimingDown(
    priming: number,
    printingMode: MixMode,
    mixtureRatio: number,
  ): Promise<string[]> {
    const mixProportions = this.getMixProportions(printingMode, mixtureRatio);

    return [
      `M200 S1 D12.07`,
      mixProportions,
      `G91`,
      `G1 E${priming} F500`,
      `G90`,
    ];
  }

  private getMixProportions(
    printingMode: MixMode,
    mixtureRatio: number,
  ): string {
    const mixRight = this.normalizePercentage(mixtureRatio);
    const mixLeft = 1 - mixRight;

    const modeMapper: { [key in MixMode]: string } = {
      left: "M165 A1.0 B0.0",
      right: "M165 A0.0 B1.0",
      mixtrusor: `M165 A${mixLeft} B${mixRight}`,
      coaxial: `M165 A${mixLeft} B${mixRight}`,
      "": "",
    };

    return modeMapper[printingMode];
  }

  private ensurePercentageBounds(value: number, min = 0, max = 100): number {
    return Math.max(Math.min(value, max), min);
  }

  private normalizePercentage(value: number): number {
    return this.ensurePercentageBounds(value) / 100;
  }
}
