import React from "react";
import { Card } from "react-bootstrap";
import { MixMode } from "../../../../models/TissuestartState";
import MarkSlider from "../../../components/shared/markSlider";
import Radio from "../../../components/shared/radio";

interface PrintingModeProps {
  disableChangeMixMode: boolean;
  showMixModeSlider: boolean;
  mixtureRatio: number;
  mix: MixMode;
  onMixModeChange: (mix: MixMode) => void;
  onMixtureRatioChange: (value: number) => void;
}

export const PrintingModeControls: React.FC<PrintingModeProps> = ({
  disableChangeMixMode,
  showMixModeSlider,
  mixtureRatio,
  mix,
  onMixModeChange,
  onMixtureRatioChange,
}) => {
  const handleSetMix = (mix: MixMode) => {
    onMixModeChange(mix);
  };

  const handleSetMixtureRatio = (ratio: number) => {
    onMixtureRatioChange(ratio);
  };

  const mixRight = 100 - mixtureRatio;
  const mixLeft = mixtureRatio;

  return (
    <Card
      className="extruder-card"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1vw",
      }}
    >
      <div>
        <p>printing mode</p>

        <Radio
          radios={[
            { name: "left", value: "left" },
            { name: "right", value: "right" },
            { name: "mixtrusor", value: "mixtrusor" },
            { name: "coaxial", value: "coaxial" },
            { name: "▲", value: "" },
          ]}
          selected={mix}
          onSelect={(selected) => handleSetMix(selected as MixMode)}
          variant="secondary"
          disabled={disableChangeMixMode}
        />
      </div>

      {showMixModeSlider && (
        <div>
          <p>mixture ratio (%)</p>
          <MarkSlider
            defaultValue={mixtureRatio}
            step={1}
            min={0}
            max={100}
            marks={[
              { value: 0, label: `${mixRight}%` },
              { value: 100, label: `${mixLeft}%` },
            ]}
            onChange={() => {}}
            onChangeCommitted={handleSetMixtureRatio}
            valueLabelFormat={(value) => {
              const mixLeft = 100 - value;
              return `${mixLeft}:${value}`;
            }}
          />
        </div>
      )}
    </Card>
  );
};
