import { ArrayBufferProvider } from "../../../application/providers/ArrayBufferProvider";

export class ArrayBufferProviderImpl implements ArrayBufferProvider {
  async arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
    return btoa(
      Array.from(new Uint8Array(buffer))
        .map((n) => String.fromCharCode(n))
        .join("")
    );
  }

  async plainTextToArrayBuffer(text: string): Promise<ArrayBuffer> {
    const blob = new Blob([text], { type: "text/plain" });
    return blob.arrayBuffer();
  }
}
