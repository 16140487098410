import { GCodeLoaderProvider } from "../../../application/providers/GCodeLoaderProvider";

export class GCodeLoaderProviderImpl implements GCodeLoaderProvider {
  async loadFromURL(url: string): Promise<string[]> {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Request failed to load file content.");
    }

    const text = await response.text();

    return text
      .split("\n")
      .map((line) => line.trim())
      .filter((e) => !e.startsWith(";") && e.length > 0);
  }
}
