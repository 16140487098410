import {
  HttpError,
  HttpProvider,
  HttpRequestParams,
  HttpRequestResponse,
} from "../HttpProvider";
import { TokenStorageProvider } from "../TokenStorageProvider";

export class HttpProviderImpl implements HttpProvider {
  constructor(
    private baseUrl: string,
    private tokenStorageProvider: TokenStorageProvider
  ) {}

  async request<T>(params: HttpRequestParams): Promise<HttpRequestResponse<T>> {
    const completeUrl = `${this.baseUrl}${params.url}`;

    const method = params.method;
    const hasBody = params.body !== undefined;
    const body = hasBody ? JSON.stringify(params.body) : undefined;
    const headers = await this.createHeaders(hasBody);

    const response = await fetch(completeUrl, { method, body, headers });

    if (response.ok) {
      return this.handleSuccess(response);
    }

    throw await this.handleFailure(response);
  }

  private async createHeaders(
    hasBody: boolean
  ): Promise<Record<string, string>> {
    const headers: Record<string, string> = {};

    const token = await this.tokenStorageProvider.get();
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }

    if (hasBody) {
      headers["content-type"] = "application/json";
    }

    return headers;
  }

  private async handleSuccess<T>(
    response: Response
  ): Promise<HttpRequestResponse<T>> {
    const headers = this.parseResponseHeaders(response.headers);

    const isJson = headers["content-type"]?.includes("application/json");
    const body = isJson ? await response.json() : null;

    const status = response.status;

    return { body, headers, status };
  }

  private async handleFailure(response: Response): Promise<HttpError> {
    const status = response.status;
    const headers = this.parseResponseHeaders(response.headers);

    const isJson = headers["content-type"]?.includes("application/json");
    const body = isJson ? await response.json() : null;

    const message = body ? body.message : "Request failed.";
    return new HttpError(message, status);
  }

  private parseResponseHeaders(
    responseHeaders: Headers
  ): Record<string, string> {
    const headers: Record<string, string> = {};
    responseHeaders.forEach((value, key) => {
      headers[key.toLocaleLowerCase()] = value;
    });
    return headers;
  }
}
