import { forwardRef } from "react";
import {
  Admin as AdminComponent,
  Resource,
  Layout,
  AppBar,
  UserMenu,
  MenuItemLink,
  AppBarProps,
  LayoutProps,
  DataProvider,
  AuthProvider,
} from "react-admin";
import { ThemeOptions, createTheme } from "@material-ui/core/styles";

import UsersIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import PrintersIcon from "@material-ui/icons/Print";
import TissueModelsIcon from "@material-ui/icons/Category";
import FirmwaresIcon from "@material-ui/icons/SelectAll";

import SettingsList from "./Settings/SettingsList";
import SettingsCreate from "./Settings/SettingsCreate";
import SettingsEdit from "./Settings/SettingsEdit";
import UserList from "./User/UserList";
import UserCreate from "./User/UserCreate";
import UserEdit from "./User/UserEdit";
import TissuemodelList from "./Tissuemodel/TissuemodelList";
import TissuemodelCreate from "./Tissuemodel/TissuemodelCreate";
import FirmwareList from "./Firmware/FirmwareList";
import FirmwareCreate from "./Firmware/FirmwareCreate";
import PrinterList from "./Printer/PrinterList";
import PrinterCreate from "./Printer/PrinterCreate";
import FirmwareEdit from "./Firmware/FirmwareEdit";

const MyMenu = forwardRef((props, _) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/settings"
        primaryText="configurations"
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink to="/user" primaryText="Users" leftIcon={<UsersIcon />} />
    </UserMenu>
  );
});

const MyAppBar = (props: AppBarProps) => (
  <AppBar {...props} userMenu={<MyMenu />} />
);

const MyLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={MyAppBar} />
);

const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#0052cc",
    },
    secondary: {
      main: "#ff3986",
    },
  },
  typography: {
    body1: {
      fontFamily: "tissuelabs",
      fontStyle: "italic",
    },
    body2: {
      fontStyle: "italic",
    },
  },
});

interface AdminProps {
  dataProvider: DataProvider;
  authProvider: AuthProvider;
}

export const AdminPage: React.FC<AdminProps> = ({
  dataProvider,
  authProvider,
}) => {
  return (
    <AdminComponent
      layout={MyLayout}
      theme={theme}
      title="test"
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        name="user"
        options={{ label: "Users" }}
        icon={UsersIcon}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource
        name="printer"
        options={{ label: "Printers" }}
        icon={PrintersIcon}
        list={PrinterList}
        create={PrinterCreate}
      />
      <Resource
        name="firmware"
        options={{ label: "Firmware" }}
        icon={FirmwaresIcon}
        list={FirmwareList}
        edit={FirmwareEdit}
        create={FirmwareCreate}
      />
      <Resource
        name="tissueModel"
        options={{ label: "Tissue models" }}
        icon={TissueModelsIcon}
        list={TissuemodelList}
        create={TissuemodelCreate}
      />
      <Resource
        name="settings"
        options={{ label: "Settings" }}
        icon={SettingsIcon}
        list={SettingsList}
        create={SettingsCreate}
        edit={SettingsEdit}
      />
    </AdminComponent>
  );
};
