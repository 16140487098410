import { AdminMappers, adminMappers } from "./mappers";
import { AdminProviders, adminProviders } from "./providers";
import {
  ReactAdminProviders,
  reactAdminProviders,
} from "./reactAdminProviders";
import { AdminRepositories, adminRepositories } from "./repositories";

interface AdminContainer {
  mappers: AdminMappers;
  providers: AdminProviders;
  repositories: AdminRepositories;
  reactAdminProviders: ReactAdminProviders;
}

export const adminContainer: AdminContainer = {
  mappers: adminMappers,
  providers: adminProviders,
  repositories: adminRepositories,
  reactAdminProviders: reactAdminProviders,
};
