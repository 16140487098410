export interface Admin {
  id: number;
  email: string;
  name: string;
  role: AdminRole;
  createdAt: Date;
  updatedAt: Date;
}

export interface AdminAndToken {
  token: string;
  admin: Admin;
}

export const adminRoles = ["admin", "owner"] as const;

export type AdminRole = (typeof adminRoles)[number];
