import React from "react";

class BtnsRow extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          columnGap: "1vw",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default BtnsRow;
