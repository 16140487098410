import { UserPrinterRepository } from "../../application/data/repositories/UserPrinterRepository";
import { UserProjectGcodeRepository } from "../../application/data/repositories/UserProjectGcodeRepository";
import { UserProjectModelRepository } from "../../application/data/repositories/UserProjectModelRepository";
import { UserProjectRepository } from "../../application/data/repositories/UserProjectRepository";
import { UserSettingsRepository } from "../../application/data/repositories/UserSettingsRepository";
import { UserTissueModelRepositor } from "../../application/data/repositories/UserTissueModelRepository";
import { UserRepository } from "../../application/data/repositories/UserRepository";

import { UserPrinterRepositoryImpl } from "../data/repositories/UserPrinterRepositoryImpl";
import { UserProjectGcodeRepositoryImpl } from "../data/repositories/UserProjectGcodeRepositoryImpl";
import { UserProjectModelRepositoryImpl } from "../data/repositories/UserProjectModelRepositoryImpl";
import { UserProjectRepositoryImpl } from "../data/repositories/UserProjectRepositoryImpl";
import { UserSettingsRepositoryImpl } from "../data/repositories/UserSettingsRepositoryImpl";
import { UserTissueModelRepositoryImpl } from "../data/repositories/UserTissueModelRepositoryImpl";
import { UserRepositoryImpl } from "../data/repositories/UserRepositoryImpl";

import { userMappers } from "./mappers";
import { userProviders } from "./providers";
import { PRINTER_SEND_LINE_ENDING } from "../../../constants";
import { UserFirmwareRepository } from "../../application/data/repositories/UserFirmwareRepository";
import { UserFirmwareRepositoryImpl } from "../data/repositories/UserFirmwareRepositoryImpl";

export interface UserRepositories {
  firmware: UserFirmwareRepository;
  printer: UserPrinterRepository;
  project: UserProjectRepository;
  projectGcode: UserProjectGcodeRepository;
  projectModel: UserProjectModelRepository;
  settings: UserSettingsRepository;
  tissueModel: UserTissueModelRepositor;
  user: UserRepository;
}

const firmware = new UserFirmwareRepositoryImpl(
  userProviders.http,
  userMappers.firmware
);
const printer = new UserPrinterRepositoryImpl(
  userProviders.http,
  userMappers.printer,
  PRINTER_SEND_LINE_ENDING
);
const project = new UserProjectRepositoryImpl(
  userProviders.arrayBuffer,
  userProviders.http,
  userMappers.project
);
const projectGcode = new UserProjectGcodeRepositoryImpl(
  userProviders.arrayBuffer,
  userProviders.http,
  userMappers.projectGcode
);
const projectModel = new UserProjectModelRepositoryImpl(
  userProviders.arrayBuffer,
  userProviders.http,
  userMappers.projectModel
);
const settings = new UserSettingsRepositoryImpl(
  userProviders.http,
  userMappers.settings
);
const tissueModel = new UserTissueModelRepositoryImpl(
  userProviders.http,
  userMappers.projectModel,
  userMappers.tissueModel
);
const user = new UserRepositoryImpl(userProviders.http, userMappers.user);

export const userRepositories: UserRepositories = {
  firmware,
  printer,
  project,
  projectGcode,
  projectModel,
  settings,
  tissueModel,
  user,
};
