import { UserPrinterModel } from "./UserPrinter";

export interface UserFirmware {
  id: number;
  printerModel: UserPrinterModel;
  type: UserFirmwareType;
  version: string;
  fileName: string;
  fileAddress: string;
  createdAt: Date;
  updatedAt: Date;
}

export const userFirmwareTypes = ["STM32", "ESP32"] as const;
export type UserFirmwareType = (typeof userFirmwareTypes)[number];
