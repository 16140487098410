import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { loadModel } from "../../../../data/network/apiServer";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";

interface CopyBtnProps extends AppState, DispatchProp {
  modelId: number;
  onWaitServer: () => void;
  onServerResponse: () => void;
  handleModel: (model: any, file: any) => void;
}

class CopyBtn extends React.Component<CopyBtnProps> {
  async handleClick() {
    try {
      this.props.onWaitServer();

      const response = await userContainer.repositories.projectModel.copy({
        projectId: parseInt(this.props.projectId),
        modelId: this.props.modelId,
      });

      const model = await loadModel(response.fileAddress);

      this.props.handleModel(model, response);

      this.props.onServerResponse();
    } catch (err) {
      Popup.error(this.props.dispatch, err);
      this.props.dispatch({ type: "project/doneWait" });
    }
  }

  render() {
    if (this.props.isProjectWaiting) return null;

    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="copyBtn">copy</Tooltip>}
      >
        <Button variant="success" onClick={() => this.handleClick()}>
          <i className="fas fa-copy" />
        </Button>
      </OverlayTrigger>
    );
  }
}

export default connect(mapStateToProps)(CopyBtn);
