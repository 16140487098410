import * as THREE from "three";

import {
  changeObjectMaterialProportion,
  getProportionFromTool,
} from "./object";
import { ToolState } from "../../models/ToolState";
import { PrinterReducer } from ".";
import { Point3D } from "../../../../application/models/Points";

export const toolState0: ToolState = {
  tools: [],
  selectedTool: null,
  medias: [],
  customMedias: [],
  selectedMedia: null,
  brands: [],
  selectedBrand: 0,
  wells: [],
  demoWell: null,
  selectedWells: [],
};

export const createTools: PrinterReducer = (state, action) => {
  let tools = action.tools;

  return { ...state, tools };
};

export const selectTool: PrinterReducer = (state, action) => {
  let selectedTool = action.selected;
  let oldTool =
    state.selectedTool !== null ? state.tools[state.selectedTool] : null;
  let newTool = state.tools[selectedTool];

  let newOffset: Point3D;
  if (newTool) newOffset = newTool.offset;
  else newOffset = { x: 0, y: 0, z: 0 };
  let oldOffset: Point3D;
  if (oldTool) oldOffset = oldTool.offset;
  else oldOffset = { x: 0, y: 0, z: 0 };

  let gcodes = state.gcodes;
  gcodes.forEach((gcode) => {
    gcode.position.x -= newOffset.x - oldOffset.x;
    gcode.position.y -= newOffset.y - oldOffset.y;
    gcode.position.z -= newOffset.z - oldOffset.z;
  });

  if (!newTool) {
    return { ...state, selectedTool, gcodes };
  }
  let mixProportion = getProportionFromTool(newTool);

  state = changeObjectMaterialProportion(state, { mixProportion });
  return { ...state, selectedTool, gcodes };
};

export const createMedias: PrinterReducer = (state, action) => {
  let medias = action.medias;
  medias = medias.map((media: Record<string, any>) => {
    let center = media.center;
    let gap = media.gap;
    let rows = parseInt(media.rows);
    let columns = parseInt(media.columns);

    let numCenters = [];
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < columns; c++) {
        numCenters.push({
          x: parseFloat(center.x) + c * parseFloat(gap.x),
          y: parseFloat(center.y) + r * parseFloat(gap.y),
        });
      }
    }

    numCenters = numCenters.sort(function (a, b) {
      if (b.y > a.y) {
        return -1;
      }
      if (b.y < a.y) {
        return 1;
      }
      if (a.x > b.x) {
        return -1;
      }
      if (a.x < b.x) {
        return 1;
      }
      return 1;
    });

    return { ...media, centers: numCenters };
  });

  let customMedias = state.customMedias;
  if (action.isCustom) customMedias = [...customMedias, ...medias];

  medias = [...state.medias, ...medias];

  return { ...state, medias, customMedias };
};

export const selectMedia: PrinterReducer = (state, action) => {
  let selectedMedia = action.selected;
  if (!Number.isFinite(selectedMedia)) selectedMedia = state.medias.length - 1;
  if (!state.medias || state.medias.length === 0)
    return { ...state, selectedMedia };
  let centers = state.medias[selectedMedia].centers;
  let ray = state.medias[selectedMedia].ray;
  let lengths = state.medias[selectedMedia].lengths;
  let height = state.medias[selectedMedia].height;
  let wells = state.wells;

  wells.forEach((well) => {
    state.scene?.remove(well);
  });

  wells = [];
  for (let i = 0; i < centers.length; i++) {
    let l, w;
    let well: any;

    if (ray) {
      let cylinder = new THREE.CylinderGeometry(ray, ray, height, 32);
      let wireCylinder = new THREE.WireframeGeometry(cylinder);
      well = new THREE.Line(wireCylinder);
    }
    if (lengths.length === 2) {
      [l, w] = lengths;
      let cube = new THREE.BoxGeometry(w, height, l);
      let wireCube = new THREE.WireframeGeometry(cube);
      well = new THREE.Line(wireCube);
    }

    well.material.depthTest = true;
    well.material.opacity = 0.1;
    well.material.transparent = true;
    well.position.x = centers[i].x;
    well.position.y = height / 2;
    well.position.z = -centers[i].y;
    well.visible = false;
    well.material.color.setHex(0xffffff);
    state.scene?.add(well);

    wells.push(well);
  }

  return { ...state, selectedMedia, wells };
};

export const selectBrand: PrinterReducer = (state, action) => {
  let selectedBrand = action.selected;

  return { ...state, selectedBrand };
};

export const showMedia: PrinterReducer = (state, action) => {
  let wells = state.wells;
  if (wells)
    wells.forEach((well) => {
      well.visible = true;
    });
  return state;
};

export const hideMedia: PrinterReducer = (state, action) => {
  let wells = state.wells;
  if (wells)
    wells.forEach((well) => {
      well.visible = false;
    });
  return state;
};

export const demonstrateWell: PrinterReducer = (state, action) => {
  if (state.demoWell || !state.wells || state.wells.length < 1) return state;
  else {
    let demoWell = state.wells[0].clone();
    demoWell.position.x = state.center.x;
    demoWell.position.z = -state.center.y;
    demoWell.visible = true;
    state.scene?.add(demoWell);
    state.wells.forEach((well) => (well.visible = false));
    return { ...state, demoWell };
  }
};

export const hideDemoWell: PrinterReducer = (state, action) => {
  let demoWell = state.demoWell;
  let wells = state.wells;
  let selectedWells = state.selectedWells;

  if (demoWell) demoWell.visible = false;
  if (wells && selectedWells)
    selectedWells.forEach((selected) => {
      if (wells[selected]) {
        wells[selected].visible = true;
      }
    });
  return { ...state, demoWell: null };
};
