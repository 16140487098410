import { DeviceState } from "../../models/DeviceState";

export const deviceState0: DeviceState = {
  printers: [],
  selectedPrinter: null,
  printerConnection: null,
  printingStatus: "disconnected",
  firmwareToUpdate: null,
  isPrintingViaServerMode: false,
  isSerialConnectionSupported: false,
};
