import { AuthProvider } from "react-admin";

import { HttpError } from "../../../../../shared/framework/providers/HttpProvider";
import { TokenStorageProvider } from "../../../../../shared/framework/providers/TokenStorageProvider";
import { AdminRepository } from "../../../../application/data/repositories/AdminRepository";

export class AdminAuthProviderImpl implements AuthProvider {
  constructor(
    private adminRepository: AdminRepository,
    private tokenStorage: TokenStorageProvider
  ) {}

  async login(params: any) {
    const response = await this.adminRepository.session({
      email: params.username,
      password: params.password,
    });

    await this.tokenStorage.set(response.token);
  }

  async logout() {
    await this.tokenStorage.clear();
    return Promise.resolve();
  }

  async checkError(error: HttpError) {
    const status = error.status;
    if (status === 401) {
      await this.tokenStorage.clear();
      return Promise.reject(error.message);
    }
    return Promise.resolve();
  }

  async checkAuth() {
    try {
      await this.adminRepository.healthCheck();
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  }

  async getPermissions() {
    try {
      await this.adminRepository.healthCheck();
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  }
}
