import React from "react";
import { Modal } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import Radio from "../shared/radio";
import UploadBtn from "./uploadBtn";
import DbModelList from "./dbModelList";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";

const LOCALMODELS = "local";
const TISSUEMODELS = "tissuemodels";

interface UploadModalProps extends AppState, DispatchProp {
  onHide: () => void;
  show: boolean;
}

interface UploadModalState {
  selectedTab: string;
  tissuemodels: any[];
}

class UploadModal extends React.Component<UploadModalProps, UploadModalState> {
  constructor(props: UploadModalProps) {
    super(props);

    this.state = {
      selectedTab: LOCALMODELS,
      tissuemodels: [],
    };
  }

  handleObjectUpload = (model: any, file: any, isPositionCorrect: boolean) => {
    this.props.dispatch({
      type: "object/create",
      model,
      file,
      isPositionCorrect,
    });
    this.props.dispatch({ type: "object/select" });

    this.props.onHide();
  };

  render() {
    return (
      <div>
        <Modal
          variant="dark"
          show={this.props.show}
          onHide={this.props.onHide}
          size="lg"
        >
          <Modal.Header className="upload-title" closeButton>
            <Modal.Title>
              <Radio
                radios={[
                  { name: "local", value: LOCALMODELS },
                  { name: "tissuelabs", value: TISSUEMODELS },
                ]}
                selected={this.state.selectedTab}
                onSelect={(selected) =>
                  this.setState({ selectedTab: selected })
                }
                variant="secondary"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.selectedTab === LOCALMODELS && (
              <div>
                <UploadBtn
                  handleModel={(model, file) =>
                    this.handleObjectUpload(model, file, false)
                  }
                />
              </div>
            )}
            {this.state.selectedTab === TISSUEMODELS && (
              <DbModelList
                getModels={(folder) =>
                  userContainer.repositories.tissueModel.findAllByFolder(folder)
                }
                getFolders={() =>
                  userContainer.repositories.tissueModel.findAllFolders()
                }
                models={this.state.tissuemodels}
                updateModels={(newValue) =>
                  new Promise((resolve) =>
                    this.setState(
                      {
                        tissuemodels: newValue,
                      },
                      resolve
                    )
                  )
                }
                handleObjectUpload={(model, file) => {
                  this.handleObjectUpload(model, file, false);
                  this.props.dispatch({
                    type: "project/doneWait",
                  });
                }}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps)(UploadModal);
