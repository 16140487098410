import { UserProjectModel } from "../../../../application/models/UserProjectModel";
import { UserProjectModelRaw } from "../../models/UserProjectModelRaw";
import { UserProjectModelMapper } from "../UserProjectModelMapper";

export class UserProjectModelMapperImpl implements UserProjectModelMapper {
  async userProjectModelRawToUserProjectModel(
    userProjectModelRaw: UserProjectModelRaw
  ): Promise<UserProjectModel> {
    return {
      id: userProjectModelRaw.id,
      name: userProjectModelRaw.name,
      fileAddress: userProjectModelRaw.fileAddress,
      userId: userProjectModelRaw.userId,
      projectId: userProjectModelRaw.projectId,
      createdAt: new Date(userProjectModelRaw.createdAt),
      updatedAt: new Date(userProjectModelRaw.updatedAt),
    };
  }
}
