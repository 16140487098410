import { TypedPrinterReducer } from ".";
import { TissuestartState } from "../../models/TissuestartState";

export const tissuestartState0: TissuestartState = {
  isTissuestartZSet: false,
  isTissuestartHome: false,
  isTissuestartHomeWarningShowed: false,
  tissuestartPosition: { x: 0, y: 0, z: 0 },
  tissuestartLight365: 0,
  tissuestartLight405: 0,
  tissuestartPrinterLight: 50,
  tissuestartLed: 100,
  tissuestartTotalCmd: 0,
  tissuestartCurrentCmd: 0,
  centerPositionX: 67.5,
  centerPositionY: 53.5,
  tissuestartFlow: 100,
  tissuestartMix: "",
  tissuestartMixRatio: 50,
  tissuestartDistance: 1,
  tissuestartVelocity: 900,
  tissuestartPrintProgress: 0,
  tissuestartShowMixModeSlider: false,
  tissuestartPriming: 25,
  tissuestartDisableMovement: false,
  tissuestartSpeed: 1,
  isCancelingPrinting: false,
};

export const controlSectionTissuestart: TypedPrinterReducer = (
  state,
  action,
) => ({
  ...state,
  isTissuestartZSet: action.isZSet,
  isTissuestartHome: action.isHomeSet,
  isTissuestartHomeWarningShowed: action.isHomeWarningShowed,
  tissuestartDistance: action.distance,
  alert: action.alert,
});

export const printSectionTissuestart: TypedPrinterReducer = (
  state,
  action,
) => ({
  ...state,
  alert: action.alert,
  tissuestartLight365: action.light365,
  tissuestartLight405: action.light405,
  tissuestartPrinterLight: action.printerLight,
  tissuestartFlow: action.flow,
  tissuestartSpeed: action.speed,
});

export const sidebarPipetteStartUpdate: TypedPrinterReducer = (
  state,
  action,
) => ({
  ...state,
  alert: action.alert,
  tissuestartPriming: action.priming,
  printingStatus: action.printingStatus,
  tissuestartDisableMovement: action.disableMovement,
  tissuestartMixRatio: action.mixtureRatio,
  printerConnection: action.printerConnection,
  tissuestartPrintProgress: action.printProgress,
  isTissuestartHome: action.isHomeSet,
  isTissuestartZSet: action.isZSet,
  tissuestartMix: action.mixMode,
  printers: action.printers,
  selectedPrinter: action.selectedPrinter,
  firmwareToUpdate: action.firmwareToUpdate,
  isPrintingViaServerMode: action.isPrintingViaServerMode,
  isSerialConnectionSupported: action.isSerialConnectionSupported,
  tissuestartShowMixModeSlider: action.showMixModeSlider,
  isCancelingPrinting: action.isCancelingPrinting,
});
