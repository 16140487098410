import {
  AdminUser,
  AdminUserRole,
  AdminUserWithDetails,
  adminUserRoles,
} from "../../../../application/models/AdminUser";
import {
  AdminUserRaw,
  AdminUserWithDetailsRaw,
} from "../../models/AdminUserRaw";
import { AdminPrinterMapper } from "../AdminPrinterMapper";
import { AdminUserMapper } from "../AdminUserMapper";

export class AdminUserMapperImpl implements AdminUserMapper {
  constructor(private printerMapper: AdminPrinterMapper) {}
  async userRawToUser(userRaw: AdminUserRaw): Promise<AdminUser> {
    if (!adminUserRoles.includes(userRaw.role as AdminUserRole)) {
      throw new Error("Invalid user role.");
    }

    return {
      id: userRaw.id,
      email: userRaw.email,
      name: userRaw.name,
      role: userRaw.role as AdminUserRole,
      createdAt: new Date(userRaw.createdAt),
      updatedAt: new Date(userRaw.updatedAt),
    };
  }

  async userWithDetailsRawToUserWithDetails(
    userWithDetailsRaw: AdminUserWithDetailsRaw
  ): Promise<AdminUserWithDetails> {
    if (!adminUserRoles.includes(userWithDetailsRaw.role as AdminUserRole)) {
      throw new Error("Invalid user role.");
    }

    const printers = await Promise.all(
      userWithDetailsRaw.printers.map((printerRaw) =>
        this.printerMapper.printerRawToPrinter(printerRaw)
      )
    );

    return {
      id: userWithDetailsRaw.id,
      email: userWithDetailsRaw.email,
      name: userWithDetailsRaw.name,
      role: userWithDetailsRaw.role as AdminUserRole,
      createdAt: new Date(userWithDetailsRaw.createdAt),
      updatedAt: new Date(userWithDetailsRaw.updatedAt),
      printers,
      settings: userWithDetailsRaw.settings,
    };
  }
}
