import {
  createGcode,
  deleteGcode,
  selectGcode,
  unselectAllGcodes,
  unselectGcode,
  unstageAllGcodes,
  stageAllGcodes,
  gcodeState0,
} from "./gcode";
import {
  addObject,
  addObjectSlot,
  alignObject,
  cancelAlignInBed,
  centerObject,
  changeObjectMaterialProportion,
  createObject,
  deleteObject,
  editObject,
  generateAlignInBed,
  moveObject,
  objectState0,
  selectObject,
  stageAllObjects,
  unselectAllObjects,
  unselectObject,
  unstageAllObjects,
} from "./object";
import {
  createMedias,
  createTools,
  demonstrateWell,
  hideDemoWell,
  hideMedia,
  selectBrand,
  selectMedia,
  selectTool,
  showMedia,
  toolState0,
} from "./tool";
import {
  closeProject,
  createBed,
  doneWaitProject,
  finishLoadingProject,
  hideSaveMessage,
  openProject,
  projectState0,
  renameProject,
  saveProject,
  unsavedChanges,
  waitingProject,
  writeLoadMessage,
  writeMessage,
} from "./project";
import { deviceState0 } from "./device";
import {
  createSlicerForms,
  fillSlicerForms,
  selectWellsForSlicer,
  slicerState0,
} from "./slicer";
import {
  controlSectionTissuestart,
  printSectionTissuestart,
  sidebarPipetteStartUpdate,
  tissuestartState0,
} from "./tissueStart";
import {
  changeOperation,
  changeRepeat,
  createCycle,
  createPipetteForms,
  createStep,
  deleteCycle,
  deleteStep,
  editParameters,
  fillPipetteForms,
  pipetteState0,
  selectWellsforPipette,
} from "./pipette";
import { AppState } from "../../models/AppState";

const INITIAL_STATE: AppState = {
  ...projectState0,
  ...objectState0,
  ...gcodeState0,
  ...toolState0,
  ...slicerState0,
  ...pipetteState0,
  ...deviceState0,
  ...tissuestartState0,
};

export type TypedPrinterReducer = (
  state: AppState,
  action: TypedAction
) => AppState;

export type PrinterReducer = (state: AppState, action: Action) => AppState;

export interface TypedAction {
  type: string;
  [x: string]: any;
}

export type Action = Omit<TypedAction, "type">;

export const printerReducer = (
  state: AppState = INITIAL_STATE,
  action: TypedAction
) => {
  switch (action.type) {
    case "project/open":
      return openProject(state, action);
    case "project/close":
      return closeProject(state, action);
    case "project/rename":
      return renameProject(state, action);
    case "project/wait":
      return waitingProject(state, action);
    case "project/doneWait":
      return doneWaitProject(state, action);
    case "project/save":
      return saveProject(state, action);
    case "project/unsavedChanges":
      return unsavedChanges(state, action);
    case "project/hideSaveMessage":
      return hideSaveMessage(state, action);
    case "project/message":
      return writeMessage(state, action);
    case "project/loadMessage":
      return writeLoadMessage(state, action);
    case "project/ready":
      return finishLoadingProject(state, action);
    case "project/bed":
      return createBed(state, action);

    case "object":
      return { ...state, objectMaxNumber: action.objectMaxNumber };
    case "object/create":
      return createObject(state, action);
    case "object/add":
      return addObject(state, action);
    case "object/edit":
      return editObject(state, action);
    case "object/delete":
      return deleteObject(state, action);
    case "object/select":
      return selectObject(state, action);
    case "object/unselect":
      return unselectObject(state, action);
    case "object/unselectAll":
      return unselectAllObjects(state, action);
    case "object/addSlot":
      return addObjectSlot(state, action);
    case "object/stage":
      return stageAllObjects(state, action);
    case "object/unstage":
      return unstageAllObjects(state, action);
    case "object/move":
      return moveObject(state, action);
    case "object/center":
      return centerObject(state, action);
    case "object/material":
      return changeObjectMaterialProportion(state, action);
    case "object/generateAlign":
      return generateAlignInBed(state, action);
    case "object/align":
      return alignObject(state, action);
    case "object/cancelAlign":
      return cancelAlignInBed(state, action);

    case "gcode/create":
      return createGcode(state, action);
    case "gcode/delete":
      return deleteGcode(state, action);
    case "gcode/select":
      return selectGcode(state, action);
    case "gcode/unselect":
      return unselectGcode(state, action);
    case "gcode/unselectAll":
      return unselectAllGcodes(state, action);
    case "gcode/stage":
      return stageAllGcodes(state, action);
    case "gcode/unstage":
      return unstageAllGcodes(state, action);

    case "media/create":
      return createMedias(state, action);
    case "media/select":
      return selectMedia(state, action);
    case "media/show":
      return showMedia(state, action);
    case "media/hide":
      return hideMedia(state, action);
    case "brand/select":
      return selectBrand(state, action);
    case "well/select/slicer":
      return selectWellsForSlicer(state, action);
    case "well/select/pipette":
      return selectWellsforPipette(state, action);
    case "well/demo":
      return demonstrateWell(state, action);
    case "well/nodemo":
      return hideDemoWell(state, action);

    case "tool/create":
      return createTools(state, action);
    case "tool/select":
      return selectTool(state, action);

    case "slicer/create":
      return createSlicerForms(state, action);
    case "slicer/fill":
      return fillSlicerForms(state, action);

    case "pipette/create":
      return createPipetteForms(state, action);
    case "pipette/fill":
      return fillPipetteForms(state, action);
    case "cycle/create":
      return createCycle(state, action);
    case "step/create":
      return createStep(state, action);
    case "cycle/delete":
      return deleteCycle(state, action);
    case "step/delete":
      return deleteStep(state, action);
    case "cycle/repeat":
      return changeRepeat(state, action);
    case "step/operation":
      return changeOperation(state, action);
    case "step/edit":
      return editParameters(state, action);

    case "tissuestart/update-control-section":
      return controlSectionTissuestart(state, action);
    case "tissuestart/update-print-section":
      return printSectionTissuestart(state, action);
    case "tissuestart/update-sidebar-pipette-start":
      return sidebarPipetteStartUpdate(state, action);

    default:
      return state;
  }
};
