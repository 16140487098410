import { UserProjectGcodeMapper } from "../data/mappers/UserProjectGcodeMapper";
import { UserProjectMapper } from "../data/mappers/UserProjectMapper";
import { UserProjectModelMapper } from "../data/mappers/UserProjectModelMapper";
import { UserSettingsMapper } from "../data/mappers/UserSettingsMapper";
import { UserTissueModelMapper } from "../data/mappers/UserTissueModelMapper";
import { UserMapper } from "../data/mappers/UserMapper";

import { UserProjectGcodeMapperImpl } from "../data/mappers/impl/UserProjectGcodeMapperImpl";
import { UserProjectMapperImpl } from "../data/mappers/impl/UserProjectMapperImpl";
import { UserProjectModelMapperImpl } from "../data/mappers/impl/UserProjectModelMapperImpl";
import { UserSettingsMapperImpl } from "../data/mappers/impl/UserSettingsMapperImpl";
import { UserTissueModelMapperImpl } from "../data/mappers/impl/UserTissueModelMapperImpl";
import { UserMapperImpl } from "../data/mappers/impl/UserMapperImpl";
import { UserPrinterMapper } from "../data/mappers/UserPrinterMapper";
import { UserPrinterMapperImpl } from "../data/mappers/impl/UserPrinterMapperImpl";
import { UserFirmwareMapper } from "../data/mappers/UserFirmwareMapper";
import { UserFirmwareMapperImpl } from "../data/mappers/impl/UserFirmwareMapperImpl";

export interface UserMappers {
  firmware: UserFirmwareMapper;
  printer: UserPrinterMapper;
  project: UserProjectMapper;
  projectGcode: UserProjectGcodeMapper;
  projectModel: UserProjectModelMapper;
  settings: UserSettingsMapper;
  tissueModel: UserTissueModelMapper;
  user: UserMapper;
}

const firmware = new UserFirmwareMapperImpl();
const printer = new UserPrinterMapperImpl();
const project = new UserProjectMapperImpl();
const projectGcode = new UserProjectGcodeMapperImpl();
const projectModel = new UserProjectModelMapperImpl();
const settings = new UserSettingsMapperImpl();
const tissueModel = new UserTissueModelMapperImpl();
const user = new UserMapperImpl();

export const userMappers: UserMappers = {
  firmware,
  printer,
  project,
  projectGcode,
  projectModel,
  settings,
  tissueModel,
  user,
};
