import { AdminTissueModel } from "../../../../application/models/AdminTissueModel";
import { AdminTissueModelRaw } from "../../models/AdminTissueModelRaw";
import { AdminTissueModelMapper } from "../AdminTissueModelMapper";

export class AdminTissueModelMapperImpl implements AdminTissueModelMapper {
  async tissueModelRawToTissueModel(
    tissueModelRaw: AdminTissueModelRaw
  ): Promise<AdminTissueModel> {
    return {
      id: tissueModelRaw.id,
      folder: tissueModelRaw.folder,
      name: tissueModelRaw.name,
      fileName: tissueModelRaw.fileName,
      thumbnail: tissueModelRaw.thumbnail,
      createdAt: new Date(tissueModelRaw.createdAt),
      updatedAt: new Date(tissueModelRaw.updatedAt),
    };
  }
}
