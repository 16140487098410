import React, { Component } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { loadGcode, sliceObjects } from "../../../../data/network/apiServer";
import Section from "../shared/section";
import FormsFromJSON from "../shared/formsFromJSON";
import MediaForms from "../toolSection/mediaForms";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";

interface SectionSlicerProps extends AppState, DispatchProp {
  nextTab: () => void;
}

interface SectionSlicerState {
  showAdvanced: boolean;
  gcodeName: string;
}

class SectionSlicer extends Component<SectionSlicerProps, SectionSlicerState> {
  private forms: React.RefObject<FormsFromJSON>;

  constructor(props: SectionSlicerProps) {
    super(props);

    this.state = {
      showAdvanced: false,
      gcodeName: "",
    };

    this.forms = React.createRef();
  }

  componentDidMount() {
    if (
      !this.props.selectedObjects ||
      this.props.selectedObjects[0] === null ||
      !this.props.objects[this.props.selectedObjects[0]]
    ) {
      Popup.message(this.props.dispatch, "Please select an object to print");
    } else if (
      !this.props.medias ||
      this.props.selectedMedia === null ||
      !this.props.medias[this.props.selectedMedia]
    ) {
      Popup.message(this.props.dispatch, "Please select a print media");
    } else if (
      !this.props.tools ||
      this.props.selectedTool === null ||
      !this.props.tools[this.props.selectedTool]
    ) {
      Popup.message(this.props.dispatch, "Please select a extruder");
    }
  }

  async handleSubmit() {
    try {
      const inputData = this.forms.current?.getData();
      await this.handleSlicer(inputData);
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    }
  }

  handleShowAdvanced() {
    this.setState({
      showAdvanced: true,
    });
  }

  handleChangeName(event: any) {
    this.setState({
      gcodeName: event.target.value,
    });
  }

  async handleSlicer(slicerFields: any) {
    if (this.props.selectedWells && this.props.selectedWells.length >= 1) {
      try {
        this.props.dispatch({ type: "project/wait" });

        const objects = this.props.selectedObjects
          .filter((e) => e !== null)
          .map((selected) => this.props.objects[selected!]);

        const media = this.props.medias[this.props.selectedMedia!];
        const tool = this.props.tools[this.props.selectedTool!];

        const file = await sliceObjects(
          objects,
          slicerFields,
          media,
          this.props.selectedWells,
          tool,
          parseInt(this.props.projectId),
        );

        const gcode = await loadGcode(file.fileAddress);
        this.props.dispatch({ type: "gcode/unselectAll" });
        this.props.dispatch({ type: "gcode/create", gcode, file });
        this.props.dispatch({ type: "gcode/select", slot: 0 });

        if (this.props.nextTab) {
          this.props.nextTab();
        }
      } finally {
        this.props.dispatch({ type: "project/doneWait" });
      }
    } else if (
      !this.props.selectedWells ||
      this.props.selectedWells.length < 1
    ) {
      Popup.message(this.props.dispatch, "select well to print");
    }
  }

  render() {
    let mediaForms: React.ReactElement | null = null;
    if (
      this.props.selectedMedia !== null &&
      this.props.medias[this.props.selectedMedia]
    ) {
      const mediaCenters = this.props.medias[this.props.selectedMedia].centers;
      mediaForms = (
        <MediaForms
          title="wells*"
          centers={mediaCenters}
          selectedWells={this.props.selectedWells}
          handleSelectWell={(selected) =>
            this.props.dispatch({
              type: "well/select/slicer",
              selected,
            })
          }
        />
      );
    }

    const generateBtn = this.props.isProjectWaiting ? null : (
      <Button onClick={() => this.handleSubmit()} variant="light">
        generate
      </Button>
    );

    if (
      this.props.selectedObjects &&
      this.props.selectedObjects[0] !== null &&
      this.props.objects[this.props.selectedObjects[0]] &&
      this.props.medias &&
      this.props.selectedMedia !== null &&
      this.props.medias[this.props.selectedMedia] &&
      this.props.tools &&
      this.props.selectedTool !== null &&
      this.props.tools[this.props.selectedTool]
    ) {
      return (
        <Section className="slicer-section">
          <Card style={{ width: "100%", padding: "1vw" }}>
            <Form>
              {mediaForms}
              <FormsFromJSON
                ref={this.forms}
                forms={this.props.slicerForms}
                data={this.props.slicerFields}
                showAdvanced={this.state.showAdvanced}
                handleEdit={(inputData) =>
                  this.props.dispatch({
                    type: "slicer/fill",
                    inputData,
                  })
                }
              />
              {!this.state.showAdvanced && (
                <div>
                  <Button
                    onClick={() => this.handleShowAdvanced()}
                    variant="secondary"
                  >
                    advanced
                  </Button>
                </div>
              )}
            </Form>
          </Card>
          {generateBtn}
        </Section>
      );
    } else {
      return (
        <Section className="slicer-section">
          <p style={{
              color: '#fff'
          }}>go to tools section and select a print media</p>
        </Section>
      );
    }
  }
}

export default connect(mapStateToProps)(SectionSlicer);
