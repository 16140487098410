import { SettingsType } from "../../../../shared/application/models/SettingsType";
import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import { UserSettingsRepository } from "../../../application/data/repositories/UserSettingsRepository";
import {
  UserSettings,
  UserSettingsWithContent,
} from "../../../application/models/UserSettings";
import { UserSettingsMapper } from "../mappers/UserSettingsMapper";
import {
  UserSettingsRaw,
  UserSettingsWithContentRaw,
} from "../models/UserSettingsRaw";

export class UserSettingsRepositoryImpl implements UserSettingsRepository {
  constructor(
    private httpProvider: HttpProvider,
    private userSettingsMapper: UserSettingsMapper
  ) {}

  async findAll(): Promise<UserSettings[]> {
    const response = await this.httpProvider.request<UserSettingsRaw[]>({
      method: "GET",
      url: "/settings",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userSettingsMapper.userSettingsRawToUserSettings(e)
      )
    );
  }

  async findByType(type: SettingsType): Promise<UserSettingsWithContent> {
    const response =
      await this.httpProvider.request<UserSettingsWithContentRaw>({
        method: "GET",
        url: `/settings/${type}`,
      });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userSettingsMapper.userSettingsWithContentRawToUserSettingsWithContent(
      response.body
    );
  }
}
