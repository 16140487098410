import { PrinterConnection } from "../../../../../application/data/connections/PrinterConnection";
import { UserFirmwareRepository } from "../../../../../application/data/repositories/UserFirmwareRepository";
import { UserPrinterRepository } from "../../../../../application/data/repositories/UserPrinterRepository";
import { PrinterResponse } from "../../../../../application/models/PrinterResponse";
import { UserFirmware } from "../../../../../application/models/UserFirmware";
import {
  UserPrinter,
  UserPrinterModel,
} from "../../../../../application/models/UserPrinter";
import { CommandGcodeProvider } from "../../../../../application/providers/CommandGcodeProvider";
import { CommandTlcProvider } from "../../../../../application/providers/CommandTlcProvider";
import { GCodeLoaderProvider } from "../../../../../application/providers/GCodeLoaderProvider";
import { PrinterConnectionProvider } from "../../../../../application/providers/PrinterConnectionProvider";
import { PrinterResponseParserProvider } from "../../../../../application/providers/PrinterResponseParserProvider";
import {
  PrintingService,
  PrintingServiceProgress,
} from "../../../../../application/services/PrintingService";
import { Gcode } from "../../../models/GcodeState";
import { PrintingStatus } from "../../../models/PrintingStatus";
import { ProjectAlert } from "../../../models/ProjectState";
import { MixMode } from "../../../models/TissuestartState";
import { ViewModel } from "../../common/ViewModel";

export interface SideBarTissueStartViewModelState {
  alert: ProjectAlert | null;
  printerConnection: PrinterConnection | null;
  printers: UserPrinter[];
  selectedPrinter: UserPrinter | null;
  printingStatus: PrintingStatus;
  firmwareToUpdate: UserFirmware | null;
  isSerialConnectionSupported: boolean;
  isPrintingViaServerMode: boolean;
  gcodes: Gcode[];
  selectedGcodes: (number | null)[];
  printProgress: number;
  mixtureRatio: number;
  mixMode: MixMode;
  priming: number;
  disableMovement: boolean;
  showMixModeSlider: boolean;
  isHomeSet: boolean;
  isZSet: boolean;
  isCancelingPrinting: boolean;
}

type ViewModelDependencies = {
  printerRepository: UserPrinterRepository;
  printingService: PrintingService;
  gcodeLoader: GCodeLoaderProvider;
  commandGcodeProvider: CommandGcodeProvider;
  printerConnectionProvider: PrinterConnectionProvider;
  commandTlcProvider: CommandTlcProvider;
  firmwareRepository: UserFirmwareRepository;
  printerResponseParserProvider: PrinterResponseParserProvider;
};

export class SideBarTissueStartViewModel extends ViewModel<SideBarTissueStartViewModelState> {
  private printerRepository!: UserPrinterRepository;
  private printingService!: PrintingService;
  private gcodeLoader!: GCodeLoaderProvider;
  private commandGcodeProvider!: CommandGcodeProvider;
  private printerConnectionProvider!: PrinterConnectionProvider;
  private commandTlcProvider!: CommandTlcProvider;
  private firmwareRepository!: UserFirmwareRepository;
  private printerResponseParserProvider!: PrinterResponseParserProvider;

  constructor(
    dependencies: ViewModelDependencies,
    state: SideBarTissueStartViewModelState,
  ) {
    super(state);
    Object.assign(this, dependencies);
    this.setup(state);

    this.printingService.setPrintServiceProgressCallback(
      this.handlePrinterProgress.bind(this),
    );
  }

  async print() {
    if (!this.checkCanStartPrinting()) return;

    this.changeState({
      ...this.state,
      printingStatus: "printing",
      disableMovement: true,
    });

    const gCodeIndex = this.state.selectedGcodes[0];

    if (gCodeIndex === null) return;

    const url = this.state.gcodes[gCodeIndex].file.fileAddress;

    const response = await this.gcodeLoader.loadFromURL(url);

    await this.printingService.load(response);
  }

  async pause() {
    try {
      this.changeState({
        ...this.state,
        printingStatus: "paused",
        disableMovement: false,
      });

      await this.printingService.pause();
    } catch (err) {
      this.handleError("Failed to pause", err);
    }
  }

  async resume() {
    try {
      this.changeState({
        ...this.state,
        printingStatus: "printing",
        disableMovement: true,
      });

      await this.printingService.resume();
    } catch (err) {
      this.handleError("Failed to start", err);
    }
  }

  async connect() {
    try {
      if (this.state.isPrintingViaServerMode && !this.state.selectedPrinter) {
        throw new Error("Select a printer.");
      }

      const printerConnection = await (this.state.isPrintingViaServerMode
        ? this.printerConnectionProvider.getServerConnection(
            this.state.selectedPrinter!.id,
          )
        : this.printerConnectionProvider.getSerialConnection());

      this.changeState({
        ...this.state,
        printingStatus: "connected",
        printerConnection,
        isHomeSet: false,
        isZSet: false,
      });

      await this.printerRepository.setConnection(printerConnection);

      const greetRequestCommand = await this.commandTlcProvider.greetRequest();
      await this.printerRepository.send(greetRequestCommand);

      await this.printingService.start();
    } catch (err) {
      this.handleError("Failed to connect", err);
    }
  }

  async disconnect() {
    try {
      this.changeState({
        ...this.state,
        printingStatus: "disconnected",
        printerConnection: null,
        disableMovement: false,
      });

      await this.printingService.reset();
      await this.printerRepository.disconnect();
    } catch (err) {
      this.handleError("Failed to start", err);
    }
  }

  async cancel() {
    try {
      this.changeState({
        ...this.state,
        isCancelingPrinting: true,
      });
    } catch (err) {
      this.handleError("Failed to cancel", err);
    }
  }

  async handleCancelPrinting(confirmed: boolean) {
    try {
      if (confirmed) {
        this.changeState({
          ...this.state,
          printingStatus: "connected",
          disableMovement: false,
          isCancelingPrinting: false,
        });

        await this.printingService.reset();

        return;
      }

      this.changeState({
        ...this.state,
        isCancelingPrinting: false,
      });
    } catch (err) {
      this.handleError("Failed to cancel", err);
    }
  }

  async handleUserSoftwareUpdateResponse(confirmed: boolean) {
    try {
      const firmwareToUpdate = this.state.firmwareToUpdate;
      this.changeState({ ...this.state, firmwareToUpdate: null });

      if (confirmed && firmwareToUpdate) {
        const updateCommand = await this.commandTlcProvider.firmwareUpdate({
          type: firmwareToUpdate.type,
          downloadUrl: firmwareToUpdate.fileAddress,
        });

        await this.printerRepository.send(updateCommand);
      }
    } catch (err: unknown) {
      this.handleError("Failed to update printer software.", err);
    }
  }

  async setMixtureRatio(value: number) {
    try {
      const commands = await this.commandGcodeProvider.setPrintingMode(
        this.state.mixMode,
        value,
      );

      await this.printingService.sendCommands(commands);

      this.changeState({
        ...this.state,
        mixtureRatio: value,
      });
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async setMixMode(value: MixMode) {
    try {
      const showMixModeSlider = value === "mixtrusor" || value === "coaxial";

      const commands = await this.commandGcodeProvider.setPrintingMode(
        value,
        this.state.mixtureRatio,
      );

      await this.printingService.sendCommands(commands);

      this.changeState({
        ...this.state,
        mixMode: value,
        showMixModeSlider,
      });
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async setPriming(value: number) {
    this.changeState({
      ...this.state,
      priming: value,
    });
  }

  async moveSyringeDown() {
    try {
      const commands = await this.commandGcodeProvider.moveSyringePrimingDown(
        this.state.priming,
        this.state.mixMode,
        this.state.mixtureRatio,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveSyringeUp() {
    try {
      const commands = await this.commandGcodeProvider.moveSyringePrimingUp(
        this.state.priming,
        this.state.mixMode,
        this.state.mixtureRatio,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async clearAlert() {
    this.changeState({ ...this.state, alert: null });
  }

  async updateState(newState: SideBarTissueStartViewModelState) {
    this.changeState({ ...newState });
  }

  async updatePrintingMode(isPrintingViaServerMode: boolean) {
    this.changeState({
      ...this.state,
      isPrintingViaServerMode: this.state.isSerialConnectionSupported
        ? isPrintingViaServerMode
        : true,
    });
  }

  updateSelectedPrinter(selectedPrinter: UserPrinter) {
    if (selectedPrinter && !this.state.printers.includes(selectedPrinter)) {
      return;
    }

    const isToggle = selectedPrinter === this.state.selectedPrinter;

    this.changeState({
      ...this.state,
      selectedPrinter: isToggle ? null : selectedPrinter,
    });
  }

  private handleError(title: string, err: unknown) {
    const message = err instanceof Error ? err.message : String(err);

    this.changeState({
      ...this.state,
      alert: {
        title,
        message,
        type: "viewModelAlert",
      },
    });
  }

  private async handlePrinterProgress(
    progress: PrintingServiceProgress,
  ): Promise<void> {
    const percentage = (progress.current / (progress.total || 1)) * 100;

    this.changeState({
      ...this.state,
      printProgress: percentage,
      printingStatus:
        percentage === 100 ? "connected" : this.state.printingStatus,
    });
  }

  private checkCanStartPrinting() {
    if (this.state.gcodes.length <= 0) {
      this.changeState({
        ...this.state,
        alert: {
          title: "Warning",
          message: "Please slice your object first",
          type: "viewModelAlert",
        },
      });
      return false;
    }

    if (this.state.printingStatus === "connected" && !this.state.isHomeSet) {
      this.changeState({
        ...this.state,
        alert: {
          title: "Warning",
          message: "Don't forget to go home",
          type: "viewModelAlert",
        },
      });
      return false;
    }

    if (this.state.printingStatus === "connected" && !this.state.isZSet) {
      this.changeState({
        ...this.state,
        alert: {
          title: "Warning",
          message: "Don't forget to set the z axis of your printer",
          type: "viewModelAlert",
        },
      });
      return false;
    }

    if (this.state.mixMode === "") {
      this.changeState({
        ...this.state,
        alert: {
          title: "Warning",
          message: "Don't forget to set printing mode",
          type: "viewModelAlert",
        },
      });
      return false;
    }

    return true;
  }

  private async setup(state: SideBarTissueStartViewModelState) {
    const isSerialConnectionSupported =
      await this.printerConnectionProvider.isSerialConnectionSupported();

    this.changeState({
      ...this.state,
      isSerialConnectionSupported,
      isPrintingViaServerMode: isSerialConnectionSupported
        ? state.isPrintingViaServerMode
        : true,
      alert:
        state.printingStatus === "printing"
          ? this.getChangeControlsWhilePrintingAlert()
          : null,
    });

    await this.printerRepository.setReceiveCallback((e) =>
      this.handlePrinterReceivedMessage(e),
    );

    await this.printerRepository.setDisconnectCallback(() =>
      this.handlePrinterDisconnection(),
    );

    if (state.printingStatus === "disconnected") {
      await this.fetchPrinters();
    }
  }

  private getChangeControlsWhilePrintingAlert(): ProjectAlert {
    return {
      title: "Warning",
      message:
        "We strongly discourage you from manually trying to move the printer while it is in service",
      type: "viewModelAlert",
    };
  }

  private async handlePrinterReceivedMessage(message: string): Promise<void> {
    try {
      const lines = message.split("\n").map((line) => line.trim());

      for (const line of lines) {
        const printerResponse =
          await this.printerResponseParserProvider.parseLine(line);

        if (!printerResponse) continue;

        await this.printerResponseHandler[printerResponse.type](
          printerResponse.params,
        );
      }
    } catch (err) {
      this.handleError("Failed to parser printer response", err);
    }
  }

  private async handlePrinterDisconnection(): Promise<void> {
    this.changeState({
      ...this.state,
      printingStatus: "disconnected",
      printerConnection: null,
    });
  }

  private async fetchPrinters(): Promise<void> {
    try {
      const printers = await this.printerRepository.list();

      this.changeState({
        ...this.state,
        printers,
      });
    } catch (err: unknown) {
      this.handleError("Failed to fetch printers.", err);
    }
  }

  private printerResponseHandler: Record<
    PrinterResponse,
    (params: unknown[]) => Promise<void>
  > = {
    greet: (params) => this.printerGreetHandler(params),
    readyToReceive: (params) => this.printerReadyToReceiveHandler(params),
  };

  private printerModelFirmwareUpdateSelectorMap: Record<
    UserPrinterModel,
    (
      params: unknown[],
      availableFirmwares: UserFirmware[],
    ) => Promise<UserFirmware | null>
  > = {
    tissuestart: this.tissuestartFirmwareUpdateSelector.bind(this),
  };

  private async tissuestartFirmwareUpdateSelector(
    params: unknown[],
    availableFirmwares: UserFirmware[],
  ): Promise<UserFirmware | null> {
    const printerEsp32Version = params[1] as string;
    const printerStm32Version = params[2] as string;

    const availableEsp32 = availableFirmwares.find((e) => e.type === "ESP32");
    if (availableEsp32 && availableEsp32.version !== printerEsp32Version) {
      return availableEsp32;
    }

    const availableStm32 = availableFirmwares.find((e) => e.type === "STM32");
    if (availableStm32 && availableStm32.version !== printerStm32Version) {
      return availableStm32;
    }

    return null;
  }

  private async printerGreetHandler(params: unknown[]): Promise<void> {
    try {
      const printerModel = params[0] as UserPrinterModel;

      const firmwareUpdateSelector =
        this.printerModelFirmwareUpdateSelectorMap[printerModel];
      if (!firmwareUpdateSelector) {
        throw new Error(
          "Firmware update not supported for the given printer model.",
        );
      }

      const firmwares = await this.firmwareRepository.listEnabled(printerModel);
      const firmware = await firmwareUpdateSelector(params, firmwares);
      if (!firmware) return;

      this.changeState({ ...this.state, firmwareToUpdate: firmware });
    } catch (err: unknown) {
      this.handleError("Failed to fetch printer software updates.", err);
    }
  }

  private async printerReadyToReceiveHandler(params: unknown[]): Promise<void> {
    await this.printingService.printerReadyToReceive();
  }
}
