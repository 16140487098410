import React from "react";
import { Modal } from "react-bootstrap";

import { ProjectAlert } from "../../../models/ProjectState";

interface ViewModelAlertModalProps {
  alert: ProjectAlert | null;
  onClose: () => void;
}

export const ViewModelAlertModal: React.FC<ViewModelAlertModalProps> = ({
  alert,
  onClose,
}) => {
  return (
    <div>
      <Modal show={alert?.type === "viewModelAlert"} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#dc3545" }}>{alert?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body">{alert?.message}</Modal.Body>
      </Modal>
    </div>
  );
};
