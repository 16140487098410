import { withRouter } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  EditButton,
} from "react-admin";
import { ListProps } from "@material-ui/core";

const FirmwareList = (props: ListProps) => {
  return (
    <List pagination={false} {...props}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField
          source="printerModel"
          label="Printer Model"
          sortable={false}
        />
        <TextField source="typeDisplayName" label="Type" sortable={false} />
        <TextField source="version" sortable={false} />
        <TextField source="status" sortable={false} />
        <EditButton basePath="/firmware" />
        <DeleteButton basePath="/firmware" />
      </Datagrid>
    </List>
  );
};

export default withRouter(FirmwareList);
