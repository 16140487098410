import {
  PrinterConnection,
  PrinterConnectionDisconnectCallback,
  PrinterConnectionReceiveCallback,
} from "../../../application/data/connections/PrinterConnection";

export class PrinterConnectionWebSocket implements PrinterConnection {
  private _isConnected: boolean = false;
  private error: Error | null = null;
  private receiveCallback: PrinterConnectionReceiveCallback | null = null;
  private disconnectCallback: PrinterConnectionDisconnectCallback | null = null;
  private webSocket: WebSocket;

  constructor(authToken: string, serverBaseUrl: string, printerId: number) {
    this.webSocket = new WebSocket(
      `${serverBaseUrl}/user/printing/${printerId}?token=${authToken}`
    );
    this.webSocket.onopen = this.handleConnectionOpen.bind(this);
    this.webSocket.onclose = this.handleConnectionClose.bind(this);
    this.webSocket.onerror = this.handleError.bind(this);
    this.webSocket.onmessage = this.handleMessage.bind(this);
  }

  async send(data: string): Promise<void> {
    try {
      if (!this._isConnected) {
        return this.disconnectCallback?.();
      }

      this.webSocket.send(data);
      console.log(">>", data);
    } catch {
      this.disconnect();
    }
  }

  async isConnected(): Promise<boolean> {
    return this._isConnected;
  }

  async disconnect(): Promise<void> {
    this.webSocket.close();
  }

  async setReceiveCallback(
    cb: PrinterConnectionReceiveCallback
  ): Promise<void> {
    this.receiveCallback = cb;
  }

  async setDisconnectCallback(
    cb: PrinterConnectionDisconnectCallback
  ): Promise<void> {
    this.disconnectCallback = cb;

    if (!this._isConnected && this.error) {
      cb();
    }
  }

  private handleConnectionOpen(_: Event): void {
    this._isConnected = true;
  }

  private handleConnectionClose(_: CloseEvent): void {
    this._isConnected = false;
    this.disconnectCallback?.();
  }

  private handleError(err: Event): void {
    this.error = err instanceof Error ? err : new Error(String(err));
    this.webSocket.close();
  }

  private handleMessage(event: MessageEvent): void {
    const data = event.data;
    console.log("<<", data);
    this.receiveCallback?.(data);
  }
}
