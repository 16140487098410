import { Card } from "react-bootstrap";

import BtnsRow from "../../../components/shared/BtnsRow";
import DownloadBtn from "../../../components/deviceSection/downloadBtn";
import GcodeConfig from "../../../components/deviceSection/gcodeConfig";
import MarkSlider from "../../../components/shared/markSlider";
import { Gcode } from "../../../../models/GcodeState";

interface PrintPreviewAndDownloadProps {
  gcodes: Gcode[];
  selectedGcodes: (number | null)[];
  onGcodeLayerChange: (layer: number) => void;
}

export const PrintPreviewAndDownload: React.FC<PrintPreviewAndDownloadProps> = (
  props
) => {
  const gcodes = Array.isArray(props.gcodes) ? props.gcodes : [];
  const selectedGcodes = Array.isArray(props.selectedGcodes)
    ? props.selectedGcodes
    : [];

  const selectedIndex = selectedGcodes[0];
  if (selectedIndex === null) return null;

  const gcode = gcodes[selectedIndex];
  if (!gcode) return null;

  return (
    <Card style={{ width: "100%", padding: "1vw", gap: "1vw" }}>
      <BtnsRow>
        <DownloadBtn
          type="gcode"
          fileAddress={gcode.file.fileAddress}
          variant="success"
        />
      </BtnsRow>
      <div>
        <GcodeConfig gcodeId={gcode.file.id} />
        <p>layers</p>
        <MarkSlider
          defaultValue={0}
          step={1}
          min={0}
          max={gcode.children.length / 2}
          marks={[0, gcode.children.length / 2]}
          onChange={props.onGcodeLayerChange}
          onChangeCommitted={() => {}}
        />
      </div>
    </Card>
  );
};
