import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import {
  AdminRepository,
  AdminSessionParams,
} from "../../../application/data/repositories/AdminRepository";
import { Admin, AdminAndToken } from "../../../application/models/Admin";
import { AdminMapper } from "../mappers/AdminMapper";
import { AdminAndTokenRaw, AdminRaw } from "../models/AdminRaw";

export class AdminRepositoryImpl implements AdminRepository {
  constructor(
    private httpProvider: HttpProvider,
    private adminMapper: AdminMapper
  ) {}

  async session(params: AdminSessionParams): Promise<AdminAndToken> {
    const response = await this.httpProvider.request<AdminAndTokenRaw>({
      method: "POST",
      url: "/session",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.adminMapper.adminAndTokenRawToAdminAndToken(response.body);
  }

  async profile(): Promise<Admin> {
    const response = await this.httpProvider.request<AdminRaw>({
      method: "GET",
      url: "/profile",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.adminMapper.adminRawToAdmin(response.body);
  }

  async healthCheck(): Promise<void> {
    await this.httpProvider.request({ method: "GET", url: "/health-check" });
  }
}
