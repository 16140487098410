import { Dispatch } from "redux";

import { ProjectAlert } from "../models/ProjectState";

export class Popup {
  static message(dispatch: Dispatch, message: string) {
    const alert: ProjectAlert = {
      type: "message",
      title: "Wait... not so fast",
      message,
    };

    dispatch({ type: "project/message", alert });
  }

  static error(dispatch: Dispatch, err: unknown) {
    const alert: ProjectAlert = {
      type: "error",
      title: "Unexpected error",
      message: err instanceof Error ? err.message : String(err),
    };

    dispatch({ type: "project/message", alert });
  }

  static close(dispatch: Dispatch) {
    const alert: ProjectAlert = {
      type: "close",
      title: "Wait... not so fast",
      message: "don`t forget to save before closing",
    };

    dispatch({ type: "project/message", alert });
  }
}
