import React from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import DropDown from "../shared/dropDown";
import UploadModal from "./uploadModal";
import { DispatchProp, connect } from "react-redux";
import ModelControl from "./modelControl";
import Section from "../shared/section";
import BtnsRow from "../shared/BtnsRow";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";

interface SectionObjectProps extends AppState, DispatchProp {
  objects: any[];
  selectedObjects: any[];
  objectMaxNumber: number;
  nextTab: () => void;
}

interface SectionObjectState {
  showUploadModal: boolean;
}

class SectionObject extends React.Component<
  SectionObjectProps,
  SectionObjectState
> {
  constructor(props: SectionObjectProps) {
    super(props);

    this.state = {
      showUploadModal: false,
    };
  }

  render() {
    let objectSection = [];
    let reactKey = 0;

    let objects = Array.isArray(this.props.objects) ? this.props.objects : [];
    let selectedObjects = Array.isArray(this.props.selectedObjects)
      ? this.props.selectedObjects
      : [];

    if (objects && objects.length >= this.props.objectMaxNumber) {
      objectSection.push(
        <p key={reactKey++} className="model-item">
          Delete an object before uploading more
        </p>
      );
    } else {
      objectSection.push(
        <OverlayTrigger
          key={reactKey++}
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="add model">add model</Tooltip>}
        >
          <Button
            onClick={() => this.setState({ showUploadModal: true })}
            variant="light"
            className="model-item"
          >
            add model
          </Button>
        </OverlayTrigger>
      );
    }

    for (let slot = 0; slot < selectedObjects.length; slot++) {
      objectSection.push(
        <BtnsRow key={reactKey++}>
          <DropDown
            key={slot}
            handleSelect={(selected) =>
              this.props.dispatch({
                type: "object/select",
                selected,
                slot,
              })
            }
            selected={selectedObjects[slot]}
            label={"select object"}
            names={objects.map((object) => object.name)}
            dontRepeat={selectedObjects}
            handleClick={() => {}}
          />
          <Button
            onClick={() =>
              this.props.dispatch({
                type: "object/unselect",
                slot,
              })
            }
            variant="light"
          >
            -
          </Button>
        </BtnsRow>
      );

      if (objects[selectedObjects[slot]]) {
        objectSection.push(
          <Card key={reactKey++} style={{ padding: "1vw" }}>
            <ModelControl selectedObject={selectedObjects[slot]} slot={slot} />
          </Card>
        );
      }
    }

    if (selectedObjects.length < this.props.objectMaxNumber)
      objectSection.push(
        <Button
          onClick={() =>
            this.props.dispatch({
              type: "object/addSlot",
              selected: null,
            })
          }
          variant="light"
          className="model-item"
          key={reactKey++}
        >
          +
        </Button>
      );

    if (selectedObjects && objects[selectedObjects[0]] && this.props.nextTab)
      objectSection.push(
        <Button
          variant="light"
          className="next-btn"
          onClick={() => this.props.nextTab()}
          key={reactKey++}
        >
          next
        </Button>
      );

    objectSection.push(
      <UploadModal
        show={
          this.state.showUploadModal &&
          objects &&
          objects.length < this.props.objectMaxNumber
        }
        onHide={() => this.setState({ showUploadModal: false })}
        key={reactKey++}
      />
    );

    return <Section className="object-section">{objectSection}</Section>;
  }
}

export default connect(mapStateToProps)(SectionObject);
