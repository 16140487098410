import { withRouter } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ListProps,
} from "react-admin";

const UserList = (props: ListProps) => {
  return (
    <List pagination={false} {...props}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="email" sortable={false} />
        <EditButton basePath="/user" />
        <DeleteButton basePath="/user" />
      </Datagrid>
    </List>
  );
};

export default withRouter(UserList);
