import { API_BASE_URL } from "../../../constants";
import { HttpProvider } from "../../../shared/framework/providers/HttpProvider";
import { TokenStorageProvider } from "../../../shared/framework/providers/TokenStorageProvider";
import { HttpProviderImpl } from "../../../shared/framework/providers/impl/HttpProviderImpl";
import { TokenStorageProviderImpl } from "../../../shared/framework/providers/impl/TokenStorageProviderImpl";
import { AdminFileProvider } from "../../application/providers/AdminFileProvider";
import { AdminFirmwareFileParserProvider } from "../../application/providers/AdminFirmwareFileParserProvider";
import { AdminFirmwareHeaderParserTissueStartESP32 } from "../data/parsers/impl/AdminFirmwareHeaderParserTissueStartESP32";
import { AdminFirmwareHeaderParserTissueStartSTM32 } from "../data/parsers/impl/AdminFirmwareHeaderParserTissueStartSTM32";
import { AdminFileProviderImpl } from "../providers/AdminFileProviderImpl";
import { AdminFirmwareFileParserProviderImpl } from "../providers/AdminFirmwareFileParserProviderImpl";

export interface AdminProviders {
  http: HttpProvider;
  tokenStorage: TokenStorageProvider;
  file: AdminFileProvider;
  firmwareFileParser: AdminFirmwareFileParserProvider;
}

const tokenStorage = new TokenStorageProviderImpl("admin-token");
const http = new HttpProviderImpl(`${API_BASE_URL}/admin`, tokenStorage);
const file = new AdminFileProviderImpl();
const firmwareFileParser = new AdminFirmwareFileParserProviderImpl(file, [
  new AdminFirmwareHeaderParserTissueStartESP32(),
  new AdminFirmwareHeaderParserTissueStartSTM32(),
]);

export const adminProviders = {
  http,
  tokenStorage,
  file,
  firmwareFileParser,
};
