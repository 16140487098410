import { SettingsType } from "../../../shared/application/models/SettingsType";

export interface User {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserWithSettings extends User {
  settings: Record<SettingsType, number | null>;
}

export interface UserAndToken {
  token: string;
  user: User;
}

export const userRoles = ["client", "admin", "owner"] as const;
export type UserRole = (typeof userRoles)[number];
