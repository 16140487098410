import { Button } from "react-bootstrap";

import Section from "../../../components/shared/section";
import { SyringePrimingControls } from "../../../components/shared/SyringePrimingControls";
import { PrintingStatus } from "../../../../models/PrintingStatus";
import { MixMode } from "../../../../models/TissuestartState";

interface PrintSectionProps {
  printingStatus: PrintingStatus;
  priming: number;
  mixMode: MixMode;
  onDisconnect: () => void;
  onMoveSyringeUp: () => void;
  onMoveSyringeDown: () => void;
  setPriming: (value: number) => void;
}

export const PrintSection = ({
  printingStatus,
  priming,
  mixMode,
  onDisconnect,
  onMoveSyringeDown,
  onMoveSyringeUp,
  setPriming,
}: PrintSectionProps) => {
  return (
    <Section className="print-section">
      {printingStatus !== "disconnected" && (
        <Button variant="danger" onClick={() => onDisconnect()}>
          Disconnect
        </Button>
      )}

      {printingStatus !== "disconnected" && (
        <SyringePrimingControls
          onPrimingChange={(value) => setPriming(value)}
          priming={priming}
          onSyringeUp={() => onMoveSyringeUp()}
          onSyringeDown={() => onMoveSyringeDown()}
          disable={mixMode === ""}
        />
      )}
    </Section>
  );
};
