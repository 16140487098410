import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { AMFLoader } from "three/examples/jsm/loaders/AMFLoader.js";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";

import { GcodeLoader } from "./GcodeLoader";
import { AMFExporter } from "./AMFExporter";

import { ROTATION_OFFSET_X } from "../../../../constants";
import { Point2D } from "../../../application/models/Points";
import { userContainer } from "../../di/container";
import { UserProjectGcode } from "../../../application/models/UserProjectGcode";

export const loadModel = async (
  filename?: string,
  filePath?: string
): Promise<THREE.Group | THREE.BufferGeometry | null> => {
  var loader;
  if (!filePath) {
    filePath = filename;
  }

  if (!filePath) return null;

  if (filePath.includes(".stl")) {
    loader = new STLLoader();
  } else if (filePath.includes(".obj")) {
    loader = new OBJLoader();
  } else if (filePath.includes(".ply")) {
    loader = new PLYLoader();
  } else if (filePath.includes(".amf")) {
    loader = new AMFLoader();
  } else {
    window.alert("format not supported");
    return null;
  }

  return loader.loadAsync(filePath);
};

export const loadGcode = async (filePath: string) => {
  if (filePath.includes("gcode")) {
    const loader = new GcodeLoader();

    return new Promise<THREE.Group>((resolve) => {
      loader.load(
        filePath,
        resolve,
        () => ({}),
        () => ({})
      );
    });
  }

  throw Error("Missing gcode file.");
};

export const generatePipette = async (
  cycles: { steps: any[] }[],
  media: any,
  tool: any,
  projectId: number,
): Promise<UserProjectGcode> => {
  const optionsCycles: any[] = cycles.map((cycle) => {
    let steps = cycle.steps.map((step) => {
      let targets = [];
      let selectedWells = step.selectedWells;
      if (selectedWells)
        targets = media.centers.filter((_center: Point2D, selected: number) =>
          selectedWells.includes(selected)
        );
      return { ...step, targets };
    });

    return { ...cycle, steps };
  });

  const response = await userContainer.repositories.projectGcode.createPipette({
    projectId,
    options: {
      tool,
      mediaHeight: media.height,
      cycles: optionsCycles,
    },
  });

  return response;
};

export const sliceObjects = async (
  objects: any,
  options: any,
  media: any,
  selectedWells: any,
  tool: any,
  projectId: number
): Promise<UserProjectGcode> => {
  let objId = 0;
  let mixProportions: any[] = [];
  let boxes: any[] = [];
  objects = objects.filter((object: Record<string, any>) => object);
  objects.forEach((object: Record<string, any>) => {
    mixProportions.push(object.mixProportion);
    boxes.push(new THREE.Box3());
    object.geometry.computeBoundingBox();
    boxes[objId]
      .copy(object.geometry.boundingBox)
      .applyMatrix4(object.matrixWorld);

    // transform model to export (due to different axis conventions)
    object.rotation.set(
      object.rotation.x - ROTATION_OFFSET_X,
      object.rotation.y,
      object.rotation.z
    );
    object.position.set(
      object.position.x,
      -object.position.z,
      object.position.y
    );
    object.updateMatrixWorld();

    object.extruder = objId + 1;
    objId++;
  });

  var exporter = new AMFExporter(); //STLExporter();
  var finalModel = exporter.parse(objects);

  // Revert transformation
  objects.forEach((object: Record<string, any>) => {
    object.position.set(
      object.position.x,
      object.position.z,
      -object.position.y
    );
    object.rotation.set(
      object.rotation.x + ROTATION_OFFSET_X,
      object.rotation.y,
      object.rotation.z
    );
    object.updateMatrixWorld();
  });

  let maxValueZ!: number,
    minValueZ!: number,
    maxValueX!: number,
    minValueX!: number,
    maxValueY!: number,
    minValueY!: number;

  boxes.forEach((box) => {
    if (!maxValueZ || maxValueZ < box.max.y) {
      maxValueZ = box.max.y;
    }
    if (!minValueZ || minValueZ > box.min.y) {
      minValueZ = box.min.y;
    }
    if (!maxValueX || maxValueX < box.max.x) {
      maxValueX = box.max.x;
    }
    if (!minValueX || minValueX > box.min.x) {
      minValueX = box.min.x;
    }
    if (!maxValueY || maxValueY < box.max.z) {
      maxValueY = box.max.z;
    }
    if (!minValueY || minValueY > box.min.z) {
      minValueY = box.min.z;
    }
  });
  var maxHeight = maxValueZ - minValueZ;
  if (media.height + 5 > maxHeight) {
    maxHeight = media.height + 5;
  }
  var containerHeight = 25;
  if (containerHeight > maxHeight) {
    maxHeight = containerHeight;
  }

  let centers = media.centers.filter((_center: Point2D, selected: number) =>
    selectedWells.includes(selected)
  );

  return userContainer.repositories.projectGcode.create({
    file: {
      content: await userContainer.providers.arrayBuffer.plainTextToArrayBuffer(
        finalModel
      ),
      extension: "amf",
    },
    projectId,
    options,
    centers,
    height: maxHeight,
    mixProportions,
    toolOffset: tool.offset,
  });
};
