import { Component } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

interface RadiosProps<T> {
  radios: { name: T; value: T }[];
  variant?: string;
  disabled?: boolean;
  selected: T;
  onSelect: (selected: string) => void;
}

class Radio<T extends number | string> extends Component<RadiosProps<T>> {
  constructor(props: RadiosProps<T>) {
    super(props);

    this.state = {
      value: true,
    };
  }

  render() {
    return (
      <ButtonGroup toggle>
        {this.props.radios.map((radio, idx) => (
          <ToggleButton
            disabled={
              this.props.disabled && this.props.selected !== radio.value
            }
            key={idx}
            type="radio"
            variant={this.props.variant ? this.props.variant : "outline-light"}
            name="radio"
            value={radio.value}
            checked={this.props.selected === radio.value}
            onChange={(e) => this.props.onSelect(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    );
  }
}

export default Radio;
