import { TokenStorageProvider } from "../TokenStorageProvider";

export class TokenStorageProviderImpl implements TokenStorageProvider {
  constructor(private tokenName: string) {}

  async set(token: string): Promise<void> {
    sessionStorage.setItem(this.tokenName, token);
  }

  async get(): Promise<string | null> {
    return sessionStorage.getItem(this.tokenName);
  }

  async clear(): Promise<void> {
    sessionStorage.removeItem(this.tokenName);
  }
}
