import { combineReducers, createStore } from "redux";

import { TypedAction, printerReducer } from "./reducers";
import { AppState } from "../models/AppState";

const allReducers = combineReducers({
  printer: printerReducer,
});

const rootReducer = (state: any, action: TypedAction) => {
  if (action.type === "reset") {
    state = undefined;
  }

  return allReducers(state, action);
};

export const store = createStore(rootReducer);

export const mapStateToProps = (state: { printer: AppState }) => {
  (window as any).state = state.printer;
  return { ...state.printer };
};
