import {
  AdminFirmwareCreateParams,
  AdminFirmwareRepository,
} from "../../../application/data/repositories/AdminFirmwareRepository";
import { AdminFirmware } from "../../../application/models/AdminFirmware";
import { AdminFirmwareRaw } from "../models/AdminFirmwareRaw";
import { AdminFirmwareMapper } from "../mappers/AdminFirmwareMapper";
import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";

export class AdminFirmwareRepositoryImpl implements AdminFirmwareRepository {
  constructor(
    private httpProvider: HttpProvider,
    private firmwareMapper: AdminFirmwareMapper
  ) {}

  async findAll(): Promise<AdminFirmware[]> {
    const response = await this.httpProvider.request<AdminFirmwareRaw[]>({
      method: "GET",
      url: "/firmware",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) => this.firmwareMapper.firmwareRawToFirmware(e))
    );
  }

  async findById(id: number): Promise<AdminFirmware> {
    const response = await this.httpProvider.request<AdminFirmwareRaw>({
      method: "GET",
      url: `/firmware/${id}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.firmwareMapper.firmwareRawToFirmware(response.body);
  }

  async create(params: AdminFirmwareCreateParams): Promise<AdminFirmware> {
    const response = await this.httpProvider.request<AdminFirmwareRaw>({
      method: "POST",
      url: "/firmware",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.firmwareMapper.firmwareRawToFirmware(response.body);
  }

  async toggleDisabledAt(id: number): Promise<AdminFirmware> {
    const response = await this.httpProvider.request<AdminFirmwareRaw>({
      method: "PUT",
      url: `/firmware/${id}/toggle-disabled`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.firmwareMapper.firmwareRawToFirmware(response.body);
  }

  async delete(id: number): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/firmware/${id}`,
    });
  }
}
