export const userRoutes = [
  "signIn",
  "home",
  "tissuestart",
  "pipettestart",
] as const;
export type UserRoute = (typeof userRoutes)[number];

export class UserRoutes {
  static get(route: UserRoute, ...params: any[]): string {
    return UserRoutes.routePathMapper[route](params);
  }

  private static routePathMapper: Record<UserRoute, (params: any[]) => string> =
    {
      signIn: () => "/",
      home: () => "/home",
      tissuestart: (params: any[]) => `/tissuestart/${params[0] || ":id"}`,
      pipettestart: (params: any[]) => `/pipettestart/${params[0] || ":id"}`,
    };
}
