import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";

import {
  UserProjectCreateParams,
  UserProjectRepository,
  UserProjectUpdateParams,
} from "../../../application/data/repositories/UserProjectRepository";

import {
  UserProject,
  UserProjectType,
  UserProjectWithState,
} from "../../../application/models/UserProject";
import { ArrayBufferProvider } from "../../../application/providers/ArrayBufferProvider";
import { UserProjectMapper } from "../mappers/UserProjectMapper";
import {
  UserProjectRaw,
  UserProjectWithStateRaw,
} from "../models/UserProjectRaw";

export class UserProjectRepositoryImpl implements UserProjectRepository {
  constructor(
    private arrayBufferProvider: ArrayBufferProvider,
    private httpProvider: HttpProvider,
    private userProjectMapper: UserProjectMapper
  ) {}

  async findAllByType(type: UserProjectType): Promise<UserProject[]> {
    const response = await this.httpProvider.request<UserProjectRaw[]>({
      method: "GET",
      url: `/project?type=${type}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userProjectMapper.userProjectRawToUserProject(e)
      )
    );
  }

  async findById(id: number): Promise<UserProjectWithState> {
    const response = await this.httpProvider.request<UserProjectWithStateRaw>({
      method: "GET",
      url: `/project/${id}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectMapper.userProjectWithStateRawToUserProjectWithState(
      response.body
    );
  }

  async create(params: UserProjectCreateParams): Promise<UserProject> {
    const response = await this.httpProvider.request<UserProjectWithStateRaw>({
      method: "POST",
      url: "/project",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectMapper.userProjectRawToUserProject(response.body);
  }

  async update(params: UserProjectUpdateParams): Promise<UserProject> {
    const { id, thumbnail, ...updateBody } = params;
    if (thumbnail) {
      (updateBody as any).thumbnail = {
        content: await this.arrayBufferProvider.arrayBufferToBase64(
          thumbnail.content
        ),
        extension: thumbnail.extension,
      };
    }

    const response = await this.httpProvider.request<UserProjectWithStateRaw>({
      method: "PATCH",
      url: `/project/${id}`,
      body: updateBody,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userProjectMapper.userProjectRawToUserProject(response.body);
  }

  async delete(id: number): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/project/${id}`,
    });
  }
}
