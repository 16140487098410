export interface UserPrinter {
  id: number;
  model: UserPrinterModel;
  macAddress: string;
  createdAt: Date;
  updatedAt: Date;
  isOnline: boolean;
}

export const userPrinterModels = ["tissuestart"] as const;
export type UserPrinterModel = (typeof userPrinterModels)[number];
