import {
  AdminFirmware,
  AdminFirmwareType,
  adminFirmwareTypes,
} from "../../../../application/models/AdminFirmware";
import {
  AdminPrinterModel,
  adminPrinterModels,
} from "../../../../application/models/AdminPrinter";
import { AdminFirmwareRaw } from "../../models/AdminFirmwareRaw";
import { AdminFirmwareMapper } from "../AdminFirmwareMapper";

export class AdminFirmwareMapperImpl implements AdminFirmwareMapper {
  async firmwareRawToFirmware(
    firmwareRaw: AdminFirmwareRaw
  ): Promise<AdminFirmware> {
    if (
      !adminPrinterModels.includes(
        firmwareRaw.printerModel as AdminPrinterModel
      )
    ) {
      throw new Error("Invalid printer model.");
    }

    if (!adminFirmwareTypes.includes(firmwareRaw.type as AdminFirmwareType)) {
      throw new Error("Invalid printer model.");
    }

    return {
      id: firmwareRaw.id,
      printerModel: firmwareRaw.printerModel as AdminPrinterModel,
      type: firmwareRaw.type as AdminFirmwareType,
      version: firmwareRaw.version,
      fileName: firmwareRaw.fileName,
      fileAddress: firmwareRaw.fileAddress,
      disabledAt:
        firmwareRaw.disabledAt === null
          ? null
          : new Date(firmwareRaw.disabledAt),
      createdAt: new Date(firmwareRaw.createdAt),
      updatedAt: new Date(firmwareRaw.updatedAt),
    };
  }
}
