import { AdminPrinterModel } from "./AdminPrinter";

export interface AdminFirmware {
  id: number;
  printerModel: AdminPrinterModel;
  type: AdminFirmwareType;
  version: string;
  fileName: string;
  fileAddress: string;
  disabledAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
}

export const adminFirmwareTypes = ["STM32", "ESP32"] as const;
export type AdminFirmwareType = (typeof adminFirmwareTypes)[number];
