import {
  AdminPrinter,
  AdminPrinterModel,
  adminPrinterModels,
} from "../../../../application/models/AdminPrinter";
import { AdminPrinterRaw } from "../../models/AdminPrinterRaw";
import { AdminPrinterMapper } from "../AdminPrinterMapper";

export class AdminPrinterMapperImpl implements AdminPrinterMapper {
  async printerRawToPrinter(
    printerRaw: AdminPrinterRaw
  ): Promise<AdminPrinter> {
    if (!adminPrinterModels.includes(printerRaw.model as AdminPrinterModel)) {
      throw new Error("Invalid printer model.");
    }

    return {
      id: printerRaw.id,
      model: printerRaw.model as AdminPrinterModel,
      macAddress: printerRaw.macAddress,
      createdAt: new Date(printerRaw.createdAt),
      updatedAt: new Date(printerRaw.updatedAt),
    };
  }
}
