export interface AdminPrinter {
  id: number;
  model: AdminPrinterModel;
  macAddress: string;
  createdAt: Date;
  updatedAt: Date;
}

export const adminPrinterModels = ["tissuestart"] as const;
export type AdminPrinterModel = (typeof adminPrinterModels)[number];
