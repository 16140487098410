import { AdminFileProvider } from "../../application/providers/AdminFileProvider";

export class AdminFileProviderImpl implements AdminFileProvider {
  async convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () =>
        resolve(
          (reader.result as string).replace(
            /^data:[a-zA-Z-]+\/[a-zA-Z-]+;base64,/g,
            ""
          )
        );

      reader.onerror = reject;
    });
  }

  async extractExtensionFromFileName(fileName: string): Promise<string> {
    const matches = /\.[0-9a-z]+$/i.exec(fileName);
    return matches ? matches[0].slice(1) : "";
  }
}
