import {
  SettingsType,
  settingsTypes,
} from "../../../../../shared/application/models/SettingsType";
import {
  User,
  UserAndToken,
  UserRole,
  UserWithSettings,
  userRoles,
} from "../../../../application/models/User";
import {
  UserAndTokenRaw,
  UserRaw,
  UserWithSettingsRaw,
} from "../../models/UserRaw";
import { UserMapper } from "../UserMapper";

export class UserMapperImpl implements UserMapper {
  async userRawToUser(userRaw: UserRaw): Promise<User> {
    if (!userRoles.includes(userRaw.role as UserRole)) {
      throw new Error("Invalid user role.");
    }

    return {
      id: userRaw.id,
      name: userRaw.name,
      email: userRaw.email,
      role: userRaw.role as UserRole,
      createdAt: new Date(userRaw.createdAt),
      updatedAt: new Date(userRaw.updatedAt),
    };
  }

  async userWithSettingsRawToUserWithSettings(
    userWithSettingsRaw: UserWithSettingsRaw
  ): Promise<UserWithSettings> {
    const rawSettingsTypes = Object.keys(userWithSettingsRaw.settings);
    if (rawSettingsTypes.length !== settingsTypes.length) {
      throw new Error("Invalid settings type");
    }

    for (const rawSettingsType of rawSettingsTypes) {
      if (!settingsTypes.includes(rawSettingsType as SettingsType)) {
        throw new Error("Invalid settings type");
      }
    }

    return {
      id: userWithSettingsRaw.id,
      name: userWithSettingsRaw.name,
      email: userWithSettingsRaw.email,
      role: userWithSettingsRaw.role as UserRole,
      createdAt: new Date(userWithSettingsRaw.createdAt),
      updatedAt: new Date(userWithSettingsRaw.updatedAt),
      settings: userWithSettingsRaw.settings,
    };
  }

  async userSignResponseRawToUserSignInResponse(
    userAndTokenRaw: UserAndTokenRaw
  ): Promise<UserAndToken> {
    return {
      token: userAndTokenRaw.token,
      user: await this.userRawToUser(userAndTokenRaw.user),
    };
  }
}
