export interface UserProject {
  id: number;
  name: string;
  userId: number;
  thumbnailAddress: string;
  type: UserProjectType;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserProjectWithState extends UserProject {
  state: object;
}

export const userProjectTypes = ["tissuestart", "pipettestart"] as const;
export type UserProjectType = (typeof userProjectTypes)[number];
