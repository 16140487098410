import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Record,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

import { AdminSettingsProviderImpl } from "./AdminSettingsProviderImpl";
import { AdminUserProviderImpl } from "./AdminUserProviderImpl";
import { AdminTissueModelProviderImpl } from "./AdminTissueModelProviderImpl";
import { AdminFirmwareProviderImpl } from "./AdminFirmwareProviderImpl";
import { AdminPrinterProviderImpl } from "./AdminPrinterProviderImpl";

export class AdminDataProviderImpl implements DataProvider {
  constructor(
    private firmwareProvider: AdminFirmwareProviderImpl,
    private printerProvider: AdminPrinterProviderImpl,
    private settingsProvider: AdminSettingsProviderImpl,
    private userProvider: AdminUserProviderImpl,
    private tissueModelProvider: AdminTissueModelProviderImpl
  ) {}

  async getList<RecordType extends Record = Record>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    switch (resource) {
      case "firmware":
        return this.firmwareProvider.getList();
      case "printer":
        return this.printerProvider.getList();
      case "settings":
        return this.settingsProvider.getList(params);
      case "user":
        return this.userProvider.getList();
      case "tissueModel":
        return this.tissueModelProvider.getList();
      default:
        throw new Error("Resource not supported.");
    }
  }

  async getOne<RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    switch (resource) {
      case "firmware":
        return this.firmwareProvider.getOne(params);
      case "settings":
        return this.settingsProvider.getOne(params);
      case "user":
        return this.userProvider.getOne(params);
      default:
        throw new Error("Resource not supported.");
    }
  }

  async create<RecordType extends Record = Record>(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    switch (resource) {
      case "firmware":
        return this.firmwareProvider.create(params);
      case "printer":
        return this.printerProvider.create(params);
      case "settings":
        return this.settingsProvider.create(params);
      case "user":
        return this.userProvider.create(params);
      case "tissueModel":
        return this.tissueModelProvider.create(params);
      default:
        throw new Error("Resource not supported.");
    }
  }

  async update<RecordType extends Record = Record>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    switch (resource) {
      case "firmware":
        return this.firmwareProvider.update(params);
      case "settings":
        return this.settingsProvider.update(params);
      case "user":
        return this.userProvider.update(params);
      default:
        throw new Error("Resource not supported.");
    }
  }

  async delete<RecordType extends Record = Record>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    switch (resource) {
      case "firmware":
        return this.firmwareProvider.delete(params);
      case "printer":
        return this.printerProvider.delete(params);
      case "settings":
        return this.settingsProvider.delete(params);
      case "user":
        return this.userProvider.delete(params);
      case "tissueModel":
        return this.tissueModelProvider.delete(params);
      default:
        throw new Error("Resource not supported.");
    }
  }

  async getMany<RecordType extends Record = Record>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    switch (resource) {
      case "settings":
        return this.settingsProvider.getMany();
      default:
        throw Error("Resource not supported.");
    }
  }

  async getManyReference<RecordType extends Record = Record>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    throw Error("Method not supported.");
  }

  async updateMany(
    resource: string,
    params: UpdateManyParams<any>
  ): Promise<UpdateManyResult> {
    throw Error("Method not supported.");
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    switch (resource) {
      case "printer":
        return this.printerProvider.deleteMany(params);
      case "settings":
        return this.settingsProvider.deleteMany(params);
      case "user":
        return this.userProvider.deleteMany(params);
      case "tissueModel":
        return this.tissueModelProvider.deleteMany(params);
      case "firmware":
        return this.firmwareProvider.deleteMany(params);
      default:
        throw new Error("Resource not supported.");
    }
  }
}
