import {
  SettingsType,
  settingsTypes,
} from "../../../../../shared/application/models/SettingsType";
import {
  UserSettings,
  UserSettingsWithContent,
} from "../../../../application/models/UserSettings";
import {
  UserSettingsRaw,
  UserSettingsWithContentRaw,
} from "../../models/UserSettingsRaw";
import { UserSettingsMapper } from "../UserSettingsMapper";

export class UserSettingsMapperImpl implements UserSettingsMapper {
  async userSettingsRawToUserSettings(
    userSettingsRaw: UserSettingsRaw
  ): Promise<UserSettings> {
    if (!settingsTypes.includes(userSettingsRaw.type as SettingsType)) {
      throw new Error("Invalid user settings type.");
    }

    return {
      id: userSettingsRaw.id,
      name: userSettingsRaw.name,
      fileAddress: userSettingsRaw.fileAddress,
      type: userSettingsRaw.type as SettingsType,
      createdAt: new Date(userSettingsRaw.createdAt),
      updatedAt: new Date(userSettingsRaw.updatedAt),
    };
  }

  async userSettingsWithContentRawToUserSettingsWithContent(
    userSettingsWithContentRaw: UserSettingsWithContentRaw
  ): Promise<UserSettingsWithContent> {
    const { content, ...rest } = userSettingsWithContentRaw;

    return {
      ...(await this.userSettingsRawToUserSettings(rest)),
      content,
    };
  }
}
