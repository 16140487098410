import {
  AdminUserCreateParams,
  AdminUserRepository,
  AdminUserUpdateParams,
} from "../../../application/data/repositories/AdminUserRepository";
import {
  AdminUser,
  AdminUserWithDetails,
} from "../../../application/models/AdminUser";
import { AdminUserRaw, AdminUserWithDetailsRaw } from "../models/AdminUserRaw";
import { AdminUserMapper } from "../mappers/AdminUserMapper";
import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";

export class AdminUserRepositoryImpl implements AdminUserRepository {
  constructor(
    private httpProvider: HttpProvider,
    private userMapper: AdminUserMapper
  ) {}

  async findAll(): Promise<AdminUser[]> {
    const response = await this.httpProvider.request<AdminUserRaw[]>({
      method: "GET",
      url: "/user",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) => this.userMapper.userRawToUser(e))
    );
  }

  async findById(id: number): Promise<AdminUserWithDetails> {
    const response = await this.httpProvider.request<AdminUserWithDetailsRaw>({
      method: "GET",
      url: `/user/${id}`,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userMapper.userWithDetailsRawToUserWithDetails(response.body);
  }

  async create(params: AdminUserCreateParams): Promise<AdminUser> {
    const response = await this.httpProvider.request<AdminUserRaw>({
      method: "POST",
      url: "/user",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userMapper.userRawToUser(response.body);
  }

  async update(params: AdminUserUpdateParams): Promise<AdminUserWithDetails> {
    const response = await this.httpProvider.request<AdminUserWithDetailsRaw>({
      method: "PUT",
      url: `/user/${params.id}`,
      body: {
        name: params.name,
        role: params.role,
        settings: params.settings,
        printers: params.printers,
        password: params.password,
      },
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userMapper.userWithDetailsRawToUserWithDetails(response.body);
  }

  async delete(id: number): Promise<void> {
    await this.httpProvider.request({
      method: "DELETE",
      url: `/user/${id}`,
    });
  }
}
