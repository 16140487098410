import { withRouter } from "react-router-dom";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  CreateProps,
} from "react-admin";

const TissuemodelCreate = (props: CreateProps) => {
  return (
    <Create title="Create an tissuemodel" {...props}>
      <SimpleForm>
        <TextInput source="folder" required />
        <TextInput source="name" required />
        <ImageInput
          source="thumbnail"
          label="thumbnail"
          accept="image/*"
          isRequired
        >
          <ImageField source="src" />
        </ImageInput>
        <FileInput source="file" label="3D model" accept=".stl" isRequired>
          <FileField source="src" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default withRouter(TissuemodelCreate);
