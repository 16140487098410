import { Component } from "react";
import { Button } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import MotionControl from "./motionControl";
import Radio from "../shared/radio";
import DeleteBtn from "../shared/deleteBtn";
import MarkSlider from "../shared/markSlider";
import CopyBtn from "./copyBtn";
import { MIXTRUSOR } from "../../../../../../constants";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";
import { userContainer } from "../../../../di/container";

const MOVE = "move";
const ROTATE = "rotate";
const SCALE = "scale";

interface ModelControlProps extends AppState, DispatchProp {
  slot: number;
  selectedObject: number;
}

interface ModelControlState {
  selectedControlModelTab: string;
  lockScale: boolean;
}

class ModelControl extends Component<ModelControlProps, ModelControlState> {
  constructor(props: ModelControlProps) {
    super(props);

    this.state = {
      selectedControlModelTab: MOVE,
      lockScale: true,
    };
  }

  handleLockScale() {
    this.setState({
      lockScale: !this.state.lockScale,
    });
  }

  handleSelectControlModelTab(selected: string) {
    this.setState({
      selectedControlModelTab: selected,
    });
  }

  render() {
    let modelControl = [];
    let reactKey = 0;
    modelControl.push(
      <div className="model-utils" key={reactKey++}>
        <Radio
          radios={[
            { name: "move", value: MOVE },
            { name: "rotate", value: ROTATE },
            { name: "scale", value: SCALE },
          ]}
          selected={this.state.selectedControlModelTab}
          onSelect={(selected) => this.handleSelectControlModelTab(selected)}
          variant="secondary"
        />
        <DeleteBtn
          onClick={async () => {
            this.props.dispatch({
              type: "object/delete",
              slot: this.props.slot,
            });
            await userContainer.repositories.projectModel.delete({
              projectId: parseInt(this.props.projectId),
              modelId: this.props.objects[this.props.selectedObject].file.id,
            });
            this.props.dispatch({ type: "project/save" });
          }}
        />
        {this.props.objects.length < this.props.objectMaxNumber &&
          this.props.objects[this.props.selectedObject] && (
            <CopyBtn
              modelId={parseInt(
                this.props.objects[this.props.selectedObject].file.id,
              )}
              handleModel={(model, file) => {
                this.props.dispatch({
                  type: "object/create",
                  model,
                  file,
                });
                this.props.dispatch({ type: "object/select" });
              }}
              onWaitServer={() =>
                this.props.dispatch({
                  type: "project/wait",
                })
              }
              onServerResponse={() =>
                this.props.dispatch({
                  type: "project/doneWait",
                })
              }
            />
          )}
      </div>,
    );

    if (this.state.selectedControlModelTab === MOVE) {
      modelControl.push(
        <div className="motion-forms" key={reactKey++}>
          <MotionControl
            label={"position x"}
            parameter={"position"}
            axis={"x"}
            object={this.props.objects[this.props.selectedObject]}
            increment={1}
            multFactor={1}
            handleMotion={(parameter, axis, newValue) =>
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              })
            }
          />
          <MotionControl
            label={"position y"}
            parameter={"position"}
            axis={"z"}
            object={this.props.objects[this.props.selectedObject]}
            increment={1}
            multFactor={1}
            handleMotion={(parameter, axis, newValue) =>
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              })
            }
          />
          <MotionControl
            label={"position z"}
            parameter={"position"}
            axis={"y"}
            object={this.props.objects[this.props.selectedObject]}
            increment={1}
            multFactor={1}
            handleMotion={(parameter, axis, newValue) =>
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              })
            }
          />

          <Button
            variant="secondary"
            onClick={() =>
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              })
            }
          >
            center &#8982;
          </Button>
        </div>,
      );
    }

    if (this.state.selectedControlModelTab === ROTATE) {
      modelControl.push(
        <div className="motion-forms" key={reactKey++}>
          <MotionControl
            label={"rotation x"}
            parameter={"rotation"}
            axis={"x"}
            object={this.props.objects[this.props.selectedObject]}
            increment={Math.PI / 4}
            multFactor={1}
            unitConversion={180 / Math.PI}
            handleMotion={(parameter, axis, newValue) => {
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              });
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              });
            }}
          />
          <MotionControl
            label={"rotation y"}
            parameter={"rotation"}
            axis={"y"}
            object={this.props.objects[this.props.selectedObject]}
            increment={Math.PI / 4}
            multFactor={1}
            unitConversion={180 / Math.PI}
            handleMotion={(parameter, axis, newValue) => {
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              });
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              });
            }}
          />
          <MotionControl
            label={"rotation z"}
            parameter={"rotation"}
            axis={"z"}
            object={this.props.objects[this.props.selectedObject]}
            increment={Math.PI / 4}
            multFactor={1}
            unitConversion={180 / Math.PI}
            handleMotion={(parameter, axis, newValue) => {
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              });
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              });
            }}
          />
          {this.props.alignMode && (
            <Button
              variant="secondary"
              onClick={() =>
                this.props.dispatch({
                  type: "object/cancelAlign",
                })
              }
            >
              cancel
            </Button>
          )}
          {!this.props.alignMode && (
            <Button
              variant="secondary"
              onClick={() =>
                this.props.dispatch({
                  type: "object/generateAlign",
                  selected: this.props.selectedObject,
                })
              }
            >
              align in bed
            </Button>
          )}
        </div>,
      );
    }

    if (this.state.selectedControlModelTab === SCALE) {
      modelControl.push(
        <div className="motion-forms" key={reactKey++}>
          <MotionControl
            label={"scale x"}
            parameter={"scale"}
            axis={"x"}
            allAxes={this.state.lockScale}
            object={this.props.objects[this.props.selectedObject]}
            increment={0}
            multFactor={1.1}
            unitConversion={100}
            handleMotion={(parameter, axis, newValue) => {
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              });
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              });
            }}
          />
          <MotionControl
            label={"scale y"}
            parameter={"scale"}
            axis={"y"}
            allAxes={this.state.lockScale}
            object={this.props.objects[this.props.selectedObject]}
            increment={0}
            multFactor={1.1}
            unitConversion={100}
            handleMotion={(parameter, axis, newValue) => {
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              });
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              });
            }}
          />
          <MotionControl
            label={"scale z"}
            parameter={"scale"}
            axis={"z"}
            allAxes={this.state.lockScale}
            object={this.props.objects[this.props.selectedObject]}
            increment={0}
            multFactor={1.1}
            unitConversion={100}
            handleMotion={(parameter, axis, newValue) => {
              this.props.dispatch({
                type: "object/move",
                selected: this.props.selectedObject,
                parameter,
                axis,
                stringValue: newValue,
              });
              this.props.dispatch({
                type: "object/center",
                selected: this.props.selectedObject,
              });
            }}
          />
          {!this.state.lockScale && (
            <Button
              variant="outline-light"
              onClick={() => this.handleLockScale()}
            >
              {" "}
              &#128275;
            </Button>
          )}
          {this.state.lockScale && (
            <Button
              variant="outline-light"
              onClick={() => this.handleLockScale()}
            >
              {" "}
              &#128274;
            </Button>
          )}
        </div>,
      );
    }

    if (
      this.props.selectedTool !== null &&
      this.props.tools[this.props.selectedTool] &&
      this.props.tools[this.props.selectedTool].id === MIXTRUSOR
    ) {
      modelControl.push(
        <div
          className="mixtrusor-forms"
          style={{ marginTop: "2vw", marginRight: "3vw" }}
          key={reactKey++}
        >
          <p>mixtrusor (%)</p>
          <MarkSlider
            defaultValue={
              this.props.objects[this.props.selectedObject].mixProportion[0]
            }
            step={1}
            min={0}
            max={100}
            marks={[
              { value: 0, label: "100:0" },
              { value: 100, label: "0:100" },
            ]}
            onChange={(proportion) =>
              this.props.dispatch({
                type: "object/material",
                mixProportion: [proportion, 100 - proportion],
                slot: this.props.slot,
              })
            }
            onChangeCommitted={() => {}}
            valueLabelFormat={(value) => {
              const mixLeft = 100 - value;
              return `${mixLeft}:${value}`;
            }}
          />
        </div>,
      );
    }

    return <div className="model-forms">{modelControl}</div>;
  }
}

export default connect(mapStateToProps)(ModelControl);
