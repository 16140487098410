import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { generatePipette, loadGcode } from "../../../../data/network/apiServer";
import Section from "../shared/section";

import PipetteCycle from "./pipetteCycle";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";

interface SectionPipetteProps extends AppState, DispatchProp {
  nextTab: () => void;
}

class SectionPipette extends React.Component<SectionPipetteProps> {
  private cycles: PipetteCycle[] = [];

  addNewCycle = () => {
    this.props.dispatch({ type: "cycle/create" });
  };

  handleSubmit = async () => {
    try {
      let cycles = this.cycles.map((cycle) => cycle.getData());

      this.props.dispatch({ type: "project/wait" });

      const file = await generatePipette(
        cycles,
        this.props.medias[this.props.selectedMedia!],
        this.props.tools[this.props.selectedTool!],
        parseInt(this.props.projectId),
      );

      const gcode = await loadGcode(file.fileAddress);

      this.props.dispatch({ type: "gcode/create", gcode, file });
      this.props.dispatch({ type: "gcode/unselectAll" });
      this.props.dispatch({ type: "gcode/select", slot: 0 });

      this.props.dispatch({ type: "project/doneWait" });

      if (this.props.nextTab) {
        this.props.nextTab();
      }
    } catch (err) {
      this.props.dispatch({ type: "project/doneWait" });
      Popup.error(this.props.dispatch, err);
    }
  };

  componentDidMount() {
    if (
      this.props.selectedMedia === null ||
      !this.props.medias[this.props.selectedMedia]
    ) {
      Popup.message(this.props.dispatch, "Please select a print media");
    } else if (
      this.props.selectedTool === null ||
      !this.props.tools[this.props.selectedTool]
    ) {
      Popup.message(this.props.dispatch, "Please select a extruder");
    }
  }

  render() {
    this.cycles = [];

    if (
      this.props.medias &&
      this.props.selectedMedia !== null &&
      this.props.medias[this.props.selectedMedia] &&
      this.props.tools &&
      this.props.selectedTool !== null &&
      this.props.tools[this.props.selectedTool]
    ) {
      let cards = this.props.pipetteCycles.map((cycle, cycleIndex) => {
        return (
          <PipetteCycle
            cycle={cycle}
            cycleIndex={cycleIndex}
            key={cycleIndex}
            ref={(el) => (el ? this.cycles.push(el) : {})}
            medias={this.props.medias}
            selectedMedia={this.props.selectedMedia}
            pipetteForms={this.props.pipetteForms}
            dispatch={(data) => this.props.dispatch(data)}
          />
        );
      });

      return (
        <Section className="pipette-section">
          {cards}
          <Button variant="light" onClick={() => this.addNewCycle()}>
            +
          </Button>
          {!this.props.isProjectWaiting && (
            <Button onClick={() => this.handleSubmit()} variant="light">
              generate
            </Button>
          )}
        </Section>
      );
    } else {
      return [];
    }
  }
}

export default connect(mapStateToProps)(SectionPipette);
