import { PrinterReducer } from ".";
import { SlicerState } from "../../models/SlicerState";

export const slicerState0: SlicerState = {
  slicerForms: [],
  slicerFields: {},
};

export const createSlicerForms: PrinterReducer = (state, action) => {
  let slicerForms = action.slicerForms;
  return { ...state, slicerForms };
};

export const fillSlicerForms: PrinterReducer = (state, action) => {
  let slicerFields = { ...state.slicerFields, ...action.inputData };

  return { ...state, slicerFields };
};

export const selectWellsForSlicer: PrinterReducer = (state, action) => {
  let selected = action.selected;
  let selectedWells = state.selectedWells;
  if (!selected && selected !== 0) return state;
  if (selectedWells.includes(selected) && state.wells.length > 1) {
    selectedWells = selectedWells.filter((value) => {
      return value !== selected;
    });
    if (state.wells[selected]) state.wells[selected].visible = false;
  } else {
    selectedWells = [...selectedWells, selected];
    state.wells[selected].visible = true;
  }
  return { ...state, selectedWells };
};
