import { AdminFileProvider } from "../../application/providers/AdminFileProvider";
import {
  AdminFirmwareFileParserProvider,
  AdminFirmwareParsedFile,
} from "../../application/providers/AdminFirmwareFileParserProvider";
import { AdminFirmwareHeaderParser } from "../data/parsers/AdminFirmwareHeaderParser";

export class AdminFirmwareFileParserProviderImpl
  implements AdminFirmwareFileParserProvider
{
  constructor(
    private fileProvider: AdminFileProvider,
    private firmwareHeaderParsers: AdminFirmwareHeaderParser[]
  ) {}

  async execute(file: File): Promise<AdminFirmwareParsedFile> {
    const [content, buffer] = await Promise.all([
      this.fileProvider.convertFileToBase64(file),
      file.arrayBuffer(),
    ]);

    const byteArray = Array.from(new Uint8Array(buffer));

    for (const headerParser of this.firmwareHeaderParsers) {
      try {
        const parsedHeader = await headerParser.execute(byteArray);
        return { ...parsedHeader, content };
      } catch {}
    }

    throw new Error("Firmware format not supported.");
  }
}
