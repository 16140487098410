import { TokenStorageProvider } from "../../../../shared/framework/providers/TokenStorageProvider";
import { PrinterConnection } from "../../../application/data/connections/PrinterConnection";
import { PrinterConnectionProvider } from "../../../application/providers/PrinterConnectionProvider";
import { PrinterConnectionWebSerial } from "../../data/connections/PrinterConnectionWebSerial";
import { PrinterConnectionWebSocket } from "../../data/connections/PrinterConnectionWebSocket";

export class PrinterConnectionProviderImpl
  implements PrinterConnectionProvider
{
  constructor(
    private tokenStorageProvider: TokenStorageProvider,
    private serverBaseUrl: string,
    private receiveMessageLineEnding: string,
    private serialBaudRate: number,
    private websocketConnectionDelay: number
  ) {}

  async getServerConnection(printerId: number): Promise<PrinterConnection> {
    const token = await this.tokenStorageProvider.get();
    if (!token) {
      throw new Error("Missing authorization token.");
    }

    return new Promise((resolve) => {
      const connection = new PrinterConnectionWebSocket(
        token,
        this.serverBaseUrl,
        printerId
      );

      setTimeout(() => resolve(connection), this.websocketConnectionDelay); // await the connection to be established
    });
  }

  async getSerialConnection(): Promise<PrinterConnection> {
    if (!(await this.isSerialConnectionSupported())) {
      throw new Error("Web Serial API not supported.");
    }

    const port = await navigator.serial.requestPort();
    return new PrinterConnectionWebSerial(
      port,
      this.receiveMessageLineEnding,
      this.serialBaudRate
    );
  }

  async isSerialConnectionSupported(): Promise<boolean> {
    return "serial" in navigator;
  }
}
