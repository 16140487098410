import React, { HTMLAttributes } from "react";

class Section extends React.Component<HTMLAttributes<HTMLDivElement>> {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1vw",
          margin: "1vw",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Section;
