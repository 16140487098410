import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Card, Navbar, Container } from "react-bootstrap";

import { userContainer } from "../../../../di/container";
import { useViewModel } from "../../hooks/useViewModel";
import { UserRoutes } from "../../common/UserRoutes";

import { SignInViewModel, SignInViewModelState } from "./SignInViewModel";
import logo from "../../../assets/images/logo-full.png";
import "./styles.css";

export const SignInPage: React.FC = () => {
  const { state, viewModel } = useViewModel(
    () =>
      new SignInViewModel(
        userContainer.providers.tokenStorage,
        userContainer.repositories.user,
        SignInViewModel.initialState
      ),
    []
  );

  if (state.isAuthenticated) {
    return <Redirect to={UserRoutes.get("home")} />;
  }

  return (
    <div className="sign-in">
      <SignInHeader />
      <SignInForm state={state} viewModel={viewModel} />
    </div>
  );
};

const SignInHeader: React.FC = () => {
  return (
    <Navbar>
      <Container>
        <Navbar.Brand href="#">
          <img className="logo" src={logo} alt="Tissuelabs logo"></img>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

const SignInForm: React.FC<{
  state: SignInViewModelState;
  viewModel: SignInViewModel;
}> = ({ state, viewModel }) => {
  return (
    <div className="form-wrapper">
      <Card className="card">
        <Form className="form">
          <label htmlFor="email">email</label>
          <input
            type="email"
            id="email"
            required
            onChange={(e) => viewModel.updateEmail(e.target.value)}
          />
          <label htmlFor="password">password</label>
          <input
            type="password"
            id="password"
            onChange={(e) => viewModel.updatePassword(e.target.value)}
          />
          <Button
            className="button"
            variant="info"
            onClick={() => viewModel.singIn()}
          >
            Login
          </Button>
          <p className="error">{state.errorMessage}</p>
        </Form>
      </Card>
    </div>
  );
};
