import * as THREE from "three";
import { SAVED_PARAMETERS } from "../../../../../constants";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ProjectState } from "../../models/ProjectState";
import { PrinterReducer } from ".";
import { userContainer } from "../../../di/container";

export const projectState0: ProjectState = {
  scene: null,
  camera: null,
  controls: null,
  renderer: null,
  center: { x: 0, y: 0 },
  bed: null,
  bedLine: null,
  projectId: "",
  projectName: "",
  projectType: "",
  saved: false,
  unsavedChanges: false,
  alert: null,
  loadMessage: "",
  isViewerReady: false,
  isProjectWaiting: false,
  willCloseProject: false,
};

export const loadViewer: PrinterReducer = (state, action) => {
  // Renderer
  let renderer = new THREE.WebGLRenderer();
  let viewerSpace = document.getElementsByClassName("viewer")[0];
  let viewerDim = viewerSpace.getBoundingClientRect();
  renderer.setSize(viewerDim.width, viewerDim.height);
  renderer.localClippingEnabled = true;
  viewerSpace.appendChild(renderer.domElement);

  // Scene
  let scene = new THREE.Scene();
  scene.background = new THREE.Color("skyblue");

  // Camera
  let camera = new THREE.OrthographicCamera(
    viewerDim.width / -2,
    viewerDim.width / 2,
    viewerDim.height / 2,
    viewerDim.height / -2,
    -100,
    10000
  );
  camera.position.x = state.center.x - 40;
  camera.position.z = -state.center.y - 100;
  camera.position.y = 55;
  camera.zoom = 3;
  camera.updateProjectionMatrix();
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.target.x = state.center.x;
  controls.target.z = -state.center.y;

  // Light
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  var ambientLight = new THREE.AmbientLightProbe(0xffffff, 0.2);
  scene.add(ambientLight);
  var topLightSource = new THREE.PointLight(0xffffff, 0.9, 2000);
  topLightSource.position.set(state.center.x, 500, -state.center.y); //default; light shining from top
  topLightSource.castShadow = true; // default false
  scene.add(topLightSource);
  var rightLightSource = new THREE.PointLight(0xffffff, 0.6, 100);
  rightLightSource.position.set(state.center.x + 50, -10, -state.center.y); //default; light shining from top
  rightLightSource.castShadow = true; // default false
  scene.add(rightLightSource);
  var leftLightSource = new THREE.PointLight(0xffffff, 0.6, 100);
  leftLightSource.position.set(state.center.x - 50, -10, -state.center.y); //default; light shining from top
  leftLightSource.castShadow = true; // default false
  scene.add(leftLightSource);
  return { ...state, scene, renderer, camera, controls };
};

export const openProject: PrinterReducer = (state, action) => {
  let projectId = action.projectId;
  let projectName = action.projectName;
  let projectType = action.projectType;

  state.center = action.center;
  state = loadViewer(state, action);

  return {
    ...state,
    projectId,
    projectName,
    projectType,
    isApp: false,
    alert: null,
  };
};

export const renameProject: PrinterReducer = (state, action) => {
  return { ...state, projectName: action.projectName };
};

export const waitingProject: PrinterReducer = (state, action) => {
  return { ...state, isProjectWaiting: true };
};

export const doneWaitProject: PrinterReducer = (state, action) => {
  state = saveProject(state, action);
  return { ...state, isProjectWaiting: false };
};

const filterObject = (
  obj: object,
  callback: (key: string, value: any) => boolean
): Record<string, any> => {
  let newObj = {};
  for (let key in obj) {
    let value = obj[key as keyof typeof obj];
    if (callback(key, value)) newObj[key as keyof typeof obj] = value;
  }
  return newObj;
};

export const saveProject: PrinterReducer = (state, action) => {
  const projectKeys = Object.keys(projectState0);
  const savedState = filterObject(
    state,
    (key, _value) => !projectKeys.includes(key)
  );
  for (const key in savedState) {
    const value = savedState[key as keyof typeof savedState];
    if (value && value.isObject3D) {
      savedState[key as keyof typeof savedState] = filterObject(
        value,
        (key, _value) => SAVED_PARAMETERS.includes(key)
      );
    }
    if (
      Array.isArray(value) &&
      value.some((element) => element && element.isObject3D)
    ) {
      savedState[key] = value.map((obj) => {
        return filterObject(obj, (key, _value) =>
          SAVED_PARAMETERS.includes(key)
        );
      });
    }
  }

  const updateProject = async () => {
    try {
      await userContainer.repositories.project.update({
        id: parseInt(state.projectId),
        name: state.projectName ? state.projectName : "new project",
        state: savedState,
      });

      const blob = await new Promise<Blob | null>((res) => {
        state.renderer?.render(state.scene!, state.camera!);
        state.renderer?.domElement.toBlob(
          (blob: Blob | null) => res(blob),
          "image/png"
        );
      });

      if (!blob) {
        return;
      }

      await userContainer.repositories.project.update({
        id: parseInt(state.projectId),
        thumbnail: {
          content: await blob.arrayBuffer(),
          extension: "png",
        },
      });
    } catch (e) {
      console.error("Save error:", e instanceof Error ? e.message : String(e));
    }
  };

  updateProject();

  return { ...state, saved: true, unsavedChanges: false };
};

export const unsavedChanges: PrinterReducer = (state, action) => {
  return { ...state, unsavedChanges: true };
};

export const hideSaveMessage: PrinterReducer = (state, action) => {
  return { ...state, saved: false };
};

export const writeMessage: PrinterReducer = (state, action) => {
  return { ...state, alert: action.alert };
};

export const writeLoadMessage: PrinterReducer = (state, action) => {
  return { ...state, loadMessage: action.loadMessage };
};

export const finishLoadingProject: PrinterReducer = (state, action) => {
  return { ...state, loadMessage: "", isViewerReady: true };
};

export const createBed: PrinterReducer = (state, action) => {
  let bed = action.bed;
  let offset = action.offset;
  let scene = state.scene;

  let box = new THREE.Box3().setFromObject(bed);

  let centerPositionX = (box.max.x + box.min.x) / 2;
  let centerPositionY = (box.max.z + box.min.z) / 2;
  let topPositionZ = box.max.y - box.min.y;
  bed.position.set(
    state.center.x - centerPositionX + offset.x,
    -topPositionZ - offset.z,
    -state.center.y - centerPositionY - offset.y
  );

  let bedLine = new THREE.BoxHelper(bed, "white");
  const material = bedLine.material as THREE.Material;
  material.depthTest = true;
  material.opacity = 0.8;
  material.transparent = true;
  bedLine.visible = false;

  scene?.add(bedLine);
  scene?.add(bed);
  return { ...state, bed, bedLine, scene };
};

export const closeProject: PrinterReducer = (state, action) => {
  return { ...state, willCloseProject: true };
};
