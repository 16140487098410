import { Component } from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";

interface MotionControlProps {
  increment: number;
  multFactor: number;
  object: Record<string, any>;
  parameter: string;
  axis: string;
  allAxes?: boolean;
  unitConversion?: number;
  label: string;
  handleMotion: (parameter: string, axis: string, value: number) => void;
}

interface MotionControlState {
  value: number;
}

class MotionControl extends Component<MotionControlProps, MotionControlState> {
  constructor(props: MotionControlProps) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  handleLeftClick() {
    let newValue = this.props.object[this.props.parameter][this.props.axis];
    newValue -= this.props.increment;
    newValue /= this.props.multFactor;
    this.handleChange(newValue.toFixed(2));
  }

  handleRightClick() {
    let newValue = this.props.object[this.props.parameter][this.props.axis];
    newValue += this.props.increment;
    newValue *= this.props.multFactor;
    this.handleChange(newValue.toFixed(2));
  }

  handleChange = (value: number) => {
    if (this.props.allAxes) {
      this.props.handleMotion(this.props.parameter, "x", value);
      this.props.handleMotion(this.props.parameter, "y", value);
      this.props.handleMotion(this.props.parameter, "z", value);
    } else {
      this.props.handleMotion(this.props.parameter, this.props.axis, value);
    }
  };

  handleGetValue = () => {
    let currentValue;
    if (
      this.props.object[this.props.parameter][`${this.props.axis}String`] ===
      undefined
    ) {
      currentValue =
        this.props.object[this.props.parameter][this.props.axis].toFixed(2);
    } else {
      currentValue =
        this.props.object[this.props.parameter][`${this.props.axis}String`];
    }

    let conversionFactor = this.props.unitConversion
      ? this.props.unitConversion
      : 1;

    let value = Math.round(parseFloat(currentValue) * conversionFactor);

    if (value !== this.state.value)
      this.setState({
        value,
      });
  };

  componentDidMount() {
    this.handleGetValue();
  }

  componentDidUpdate(
    _prevProps: MotionControlProps,
    prevState: MotionControlState
  ) {
    if (prevState.value === this.state.value) this.handleGetValue();
  }

  render() {
    let conversionFactor = this.props.unitConversion
      ? this.props.unitConversion
      : 1;

    return (
      <div
        className="motion-control"
        style={{
          display: "grid",
          gridTemplateColumns:
            "[first] 9.7vw [line2] 2.8vw [line3] 6.8vw [col4-start] 2.6vw [five] 1vw",
        }}
      >
        <div className="motion-control-label"> {this.props.label}</div>
        <div className="motion-control-btn">
          <Button onClick={() => this.handleLeftClick()} variant="secondary">
            &#9666;
          </Button>
        </div>
        <div>
          <InputGroup>
            <FormControl
              onBlur={() =>
                this.handleChange(this.state.value / conversionFactor)
              }
              onChange={(e) => this.setState({ value: Number(e.target.value) })}
              type="text"
              value={this.state.value}
              className="motion-control-input"
              style={{
                backgroundColor: "#ff80ae",
                color: "white",
              }}
            />
          </InputGroup>
        </div>
        <div className="motion-control-btn">
          <Button
            className="motion-control-btn"
            onClick={() => this.handleRightClick()}
            variant="secondary"
          >
            &#9656;
          </Button>
        </div>
      </div>
    );
  }
}

export default MotionControl;
