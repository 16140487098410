import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import Viewer from "../components/viewer/viewer";
import AlertPopUp from "../components/shared/alertPopUp";
import { UserRoutes } from "../common/UserRoutes";
import { store } from "../../redux";

import SidebarTissueStart from "./tissueStart/sidebarTissueStart";
import SidebarPipetteStart from "./pipetteStart/sidebarPipetteStart";
import { HomePage } from "./home/HomePage";
import { SignInPage } from "./signIn/SignInPage";

export const UserPages: React.FC = () => {
  return (
    <Provider store={store}>
      <Switch>
        <Route exact path={UserRoutes.get("tissuestart")}>
          <Viewer />
          <SidebarTissueStart />
        </Route>
        <Route exact path={UserRoutes.get("pipettestart")}>
          <Viewer />
          <SidebarPipetteStart />
        </Route>
        <Route exact path={UserRoutes.get("home")}>
          <HomePage />
        </Route>
        <Route exact path={UserRoutes.get("signIn")}>
          <SignInPage />
        </Route>
      </Switch>
      <AlertPopUp />
    </Provider>
  );
};
