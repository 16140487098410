export const TISSUESTART_SECTION = "tissuestart";
export const MODEL_SECTION = "model";
export const SLICER_SECTION = "slicer";
export const TOOLS_SECTION = "tools";
export const PRINT_SECTION = "tissuestartprint";
export const PIPETTE_SECTION = "pipette";
export const TISSUESTART = "tissuestart";
export const PIPETTESTART = "pipettestart";
export const GCODE = "gcode";
export const OBJECT = "object";
export const WELLS = "wells";
export const MIXTRUSOR = "mixtrusor";
export const DUAL_EXTRUDER = "dual";
export const RIGHT_EXTRUDER = 0;
export const LEFT_EXTRUDER = 1;
export const ROTATION_OFFSET_X = -Math.PI / 2;
export const ROTATION_OFFSET_Y = Math.PI;
export const SAVED_PARAMETERS = [
  "mixProportion",
  "position",
  "rotation",
  "scale",
];

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
export const API_WS_BASE_URL = process.env.REACT_APP_API_WS_BASE_URL || "";

export const PRINTER_SEND_LINE_ENDING = "\r\n";
export const PRINTER_RECEIVE_LINE_ENDING = "\n";
export const PRINTER_SERIAL_BAUD_RATE = 250_000; // bps
export const PRINTER_FEEDRATE = 8_000; // mm/min
export const PRINTER_Z_SAFETY_HEIGHT = 25; // mm
export const PRINTER_WEBSOCKET_CONNECTION_DELAY = 500; // ms
