import {
  UserPrinterModel,
  userPrinterModels,
  UserPrinter,
} from "../../../../application/models/UserPrinter";
import { UserPrinterRaw } from "../../models/UserPrinterRaw";
import { UserPrinterMapper } from "../UserPrinterMapper";

export class UserPrinterMapperImpl implements UserPrinterMapper {
  async userPrinterRawToUserPrinter(
    userPrinterRaw: UserPrinterRaw
  ): Promise<UserPrinter> {
    if (!userPrinterModels.includes(userPrinterRaw.model as UserPrinterModel)) {
      throw new Error("Invalid printer model.");
    }

    return {
      id: userPrinterRaw.id,
      model: userPrinterRaw.model as UserPrinterModel,
      macAddress: userPrinterRaw.macAddress,
      createdAt: new Date(userPrinterRaw.createdAt),
      updatedAt: new Date(userPrinterRaw.updatedAt),
      isOnline: userPrinterRaw.isOnline,
    };
  }
}
