import { Component } from "react";
import { DispatchProp, connect } from "react-redux";
import { Button } from "react-bootstrap";

import DropDown from "../shared/dropDown";
import Section from "../shared/section";
import BtnsRow from "../shared/BtnsRow";
import CustomMedia from "./customMedia";
import { AppState } from "../../../models/AppState";
import { mapStateToProps } from "../../../redux";

interface SectionToolProps extends AppState, DispatchProp {
  nextTab: () => void;
}

class SectionTool extends Component<SectionToolProps> {
  render() {
    let reactKey = 0;
    let toolSection = [];

    let tools = this.props.tools;
    let selectedTool = this.props.selectedTool;
    toolSection.push(
      <DropDown
        key={reactKey++}
        handleSelect={(selected) =>
          this.props.dispatch({ type: "tool/select", selected })
        }
        selected={selectedTool}
        label={"select extruder"}
        names={tools ? tools.map((tool) => tool.label) : []}
        dontRepeat={[]}
        handleClick={() => {}}
      />,
    );

    let medias = this.props.medias;
    let selectedMedia = this.props.selectedMedia;

    const mediaBrands =
      medias && selectedMedia ? medias[selectedMedia].brand : [];

    toolSection.push(
      <BtnsRow key={reactKey++}>
        <DropDown
          handleSelect={(selected) =>
            this.props.dispatch({ type: "media/select", selected })
          }
          selected={selectedMedia}
          label={"select media"}
          names={medias ? medias.map((media) => media.label) : []}
          dontRepeat={[]}
          handleClick={() => {}}
        />
        {mediaBrands.length > 0 && (
          <DropDown
            handleSelect={(selected) =>
              this.props.dispatch({ type: "brand/select", selected })
            }
            selected={this.props.selectedBrand}
            label={""}
            names={mediaBrands.map((item) => item.brand)}
            dontRepeat={[]}
            handleClick={() => {}}
          />
        )}
      </BtnsRow>,
    );

    if (
      medias &&
      selectedMedia !== null &&
      medias[selectedMedia] &&
      tools &&
      selectedTool !== null &&
      tools[selectedTool] &&
      this.props.nextTab
    )
      toolSection.push(
        <Button
          key={reactKey++}
          variant="light"
          className="next-btn"
          onClick={() => this.props.nextTab()}
        >
          next
        </Button>,
      );
    return <Section className="tool-section">{toolSection}</Section>;
  }
}

export default connect(mapStateToProps)(SectionTool);
