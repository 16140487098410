import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  Modal,
  Form,
  Navbar,
  Container,
  Nav,
  NavDropdown,
} from "react-bootstrap";

import { userContainer } from "../../../../di/container";
import { useViewModel } from "../../hooks/useViewModel";
import { UserRoutes } from "../../common/UserRoutes";
import {
  UserProject,
  UserProjectType,
} from "../../../../../application/models/UserProject";

import { HomeUserProfileData, HomeViewModel } from "./HomeViewModel";
import "./styles.css";
import logo from "../../../assets/images/logo-full.png";

export const HomePage: React.FC = () => {
  const { state, viewModel } = useViewModel(
    () =>
      new HomeViewModel(
        userContainer.providers.tokenStorage,
        userContainer.repositories.user,
        userContainer.repositories.project,
        HomeViewModel.initialState
      ),
    []
  );

  if (!state.isAuthenticated) {
    return <Redirect to={UserRoutes.get("signIn")} />;
  }

  if (state.openProject) {
    return (
      <Redirect
        to={UserRoutes.get(state.projectsType, state.openProject?.id)}
      />
    );
  }

  return (
    <div className="home">
      <MainNavBar
        selectedType={state.projectsType}
        onProfile={() => viewModel.updateUserProfileModalVisibility(true)}
        onSelectProjectTypeChange={(type) => viewModel.selectProjectType(type)}
        onSignOut={() => viewModel.signOut()}
      />

      <div className="content">
        <div className="list">
          <NoProjectsMessage
            show={!state.projects.length}
            type={state.projectsType}
          />

          {state.projects.map((project) => (
            <ProjectCard
              project={project}
              key={project.id}
              onOpen={() => viewModel.openProject(project)}
              onDelete={() => viewModel.deleteProject(project.id)}
            />
          ))}

          <NewProjectButton
            onClick={() => viewModel.updateNewProjectModalVisibility(true)}
          />
        </div>

        <NewProjectModal
          show={state.showNewProjectModal}
          errorMessage={state.errorMessage}
          newProjectName={state.newProjectName}
          onHide={() => viewModel.updateNewProjectModalVisibility(false)}
          onNameChange={(name) => viewModel.updateNewProjectName(name)}
          onCreate={() => viewModel.createProject()}
        />

        <UserProfileModal
          show={state.showUserProfileModal}
          errorMessage={state.errorMessage}
          userName={state.user?.name || ""}
          userProfile={state.userProfile}
          onHide={() => viewModel.updateUserProfileModalVisibility(false)}
          onUserProfileDataChange={(data) =>
            viewModel.updateUserProfileData(data)
          }
          onUpdate={() => viewModel.saveUserProfile()}
        />
      </div>
    </div>
  );
};

const NoProjectsMessage: React.FC<{
  show: boolean;
  type: UserProjectType;
}> = ({ show, type }) => {
  if (!show) {
    return null;
  }

  return (
    <p className="proj-arrow" key={0}>
      start using {type} <i className="fas fa-arrow-right" />
    </p>
  );
};

const ProjectCard: React.FC<{
  project: UserProject;
  onOpen: () => void;
  onDelete: () => void;
}> = ({ project, onOpen, onDelete }) => {
  return (
    <Card key={project.id} className="proj-item">
      <img src={project.thumbnailAddress} className="thumb" alt="" />
      <p className="proj-item-title">{project.name}</p>
      <div className="proj-item-btns">
        <Button variant="success" onClick={onOpen}>
          open
        </Button>
        <Button variant="danger" onClick={onDelete}>
          delete
        </Button>
      </div>
    </Card>
  );
};

const NewProjectButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <Button className="add-proj-btn" variant="secondary" onClick={onClick}>
      +
    </Button>
  );
};

export const MainNavBar: React.FC<{
  selectedType: UserProjectType;
  onProfile: () => void;
  onSelectProjectTypeChange: (type: UserProjectType) => void;
  onSignOut: () => void;
}> = ({ selectedType, onProfile, onSelectProjectTypeChange, onSignOut }) => {
  const title = `TissueStart - ${
    selectedType === "pipettestart" ? "pipette" : "print"
  }`;

  return (
    <Navbar>
      <Container>
        <Navbar.Brand>
          <img
            src={logo}
            style={{
              height: "3vw",
            }}
            alt="tissuelabs logo"
          ></img>
        </Navbar.Brand>

        <Nav className="me-auto">
          <NavDropdown title={title} id="nav-dropdown">
            <NavDropdown.Item
              onSelect={() => onSelectProjectTypeChange("tissuestart")}
            >
              print
            </NavDropdown.Item>
            <NavDropdown.Item
              onSelect={() => onSelectProjectTypeChange("pipettestart")}
            >
              pipette
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={onProfile}>profile</Nav.Link>
          <Nav.Link onClick={onSignOut}>logout</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

const NewProjectModal: React.FC<{
  show: boolean;
  errorMessage: string | null;
  newProjectName: string;
  onHide: () => void;
  onNameChange: (name: string) => void;
  onCreate: () => void;
}> = ({
  errorMessage,
  newProjectName,
  show,
  onHide,
  onNameChange,
  onCreate,
}) => {
  return (
    <Modal show={show} onHide={onHide} className="home-modal">
      <Modal.Header closeButton>
        <Modal.Title>add project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId={"newProjectForm"}>
            <Form.Control
              className="form-control"
              onChange={(e) => onNameChange(e.target.value)}
              value={newProjectName}
              placeholder={"type name"}
            />
          </Form.Group>
          <Button variant="info" onClick={onCreate}>
            add project
          </Button>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const UserProfileModal: React.FC<{
  show: boolean;
  errorMessage: string | null;
  userName: string;
  userProfile: HomeUserProfileData;
  onHide: () => void;
  onUpdate: () => void;
  onUserProfileDataChange: (data: HomeUserProfileData) => void;
}> = ({
  show,
  errorMessage,
  userName,
  userProfile,
  onHide,
  onUpdate,
  onUserProfileDataChange,
}) => {
  return (
    <Modal show={show} onHide={onHide} className="home-modal">
      <Modal.Header closeButton>
        <Modal.Title>{userName} profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId={"newProjectForm"}>
            <Form.Label>name</Form.Label>
            <Form.Control
              className="form-control"
              onChange={(e) =>
                onUserProfileDataChange({
                  ...userProfile,
                  newName: e.target.value,
                })
              }
              value={userProfile.newName}
              placeholder={""}
            />

            <Form.Label>old password</Form.Label>
            <Form.Control
              className="form-control"
              type="password"
              onChange={(e) =>
                onUserProfileDataChange({
                  ...userProfile,
                  oldPassword: e.target.value,
                })
              }
              value={userProfile.oldPassword}
              placeholder={""}
            />

            <Form.Label>new password</Form.Label>
            <Form.Control
              className="form-control"
              type="password"
              onChange={(e) =>
                onUserProfileDataChange({
                  ...userProfile,
                  newPassword: e.target.value,
                })
              }
              value={userProfile.newPassword}
              placeholder={""}
            />
          </Form.Group>
          <Button variant="info" onClick={onUpdate}>
            save
          </Button>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </Form>
      </Modal.Body>
    </Modal>
  );
};
