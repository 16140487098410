import { PrinterReducer } from ".";
import { GcodeState } from "../../models/GcodeState";

export const gcodeState0: GcodeState = {
  gcodes: [],
  selectedGcodes: [],
};

export const createGcode: PrinterReducer = (state, action) => {
  const gcode = action.gcode;
  const file = action.file;

  if (gcode) {
    state.gcodes.forEach((e) => e && state.scene?.remove(e as any));

    const tool =
      state.selectedTool !== null ? state.tools[state.selectedTool] : null;
    if (tool) {
      gcode.position.x -= tool.offset.x;
      gcode.position.y -= tool.offset.y;
      gcode.position.z -= tool.offset.z;
    }

    gcode.visible = false;
    state.scene?.add(gcode);
    gcode.name = file.name;
    gcode.file = file;
    state = { ...state, gcodes: [gcode], selectedGcodes: [0] };
  }

  return state;
};

export const deleteGcode: PrinterReducer = (state, action) => {
  let gcodes = state.gcodes.slice();
  let selectedGcodes = state.selectedGcodes.slice();
  let deleted: number;
  if (action.deleted || action.deleted === 0) deleted = action.deleted;
  else deleted = selectedGcodes[action.slot] as number;
  let gcode = gcodes[deleted];

  state.scene?.remove(gcode as any);

  gcodes.splice(deleted, 1);
  selectedGcodes[action.slot] = null;
  selectedGcodes.forEach((_val, index) => {
    const selected = selectedGcodes[index];
    if (selected !== null && selected > deleted) {
      (selectedGcodes[index] as any) -= 1;
    }
  });
  return { ...state, gcodes, selectedGcodes };
};

export const selectGcode: PrinterReducer = (state, action) => {
  let selected = action.selected;
  let slot = action.slot;
  let selectedGcodes = state.selectedGcodes.slice();

  if (!Number.isInteger(selected)) {
    selected = state.gcodes.length - 1;
  }
  if (!Number.isInteger(slot)) {
    for (let s = 0; s < selectedGcodes.length; s++) {
      let selectedGcode = state.selectedGcodes[s];
      if (!Number.isInteger(selectedGcode)) {
        slot = s;
      }
    }
  }

  unstageGcode(state, { selected: selectedGcodes[slot] });
  selectedGcodes[slot] = selected;
  stageGcode(state, { selected });
  return { ...state, selectedGcodes };
};

export const unselectGcode: PrinterReducer = (state, action) => {
  let selectedGcodes = state.selectedGcodes.slice();
  let selected = selectedGcodes[action.slot];
  if (selectedGcodes.length <= 1) selectedGcodes = [null];
  else selectedGcodes.splice(action.slot, 1);
  unstageGcode(state, { selected });
  return { ...state, selectedGcodes };
};

export const unselectAllGcodes: PrinterReducer = (state, action) => {
  state.selectedGcodes.forEach((selected) => unstageGcode(state, { selected }));
  return { ...state, selectedGcodes: [null] };
};

export const stageGcode: PrinterReducer = (state, action) => {
  let gcode = state.gcodes[action.selected];
  if (gcode) gcode.visible = true;
  return state;
};

export const stageAllGcodes: PrinterReducer = (state, action) => {
  state.selectedGcodes.forEach((selected) => {
    stageGcode(state, { selected });
  });
  return state;
};

export const unstageGcode: PrinterReducer = (state, action) => {
  let gcode = state.gcodes[action.selected];
  if (gcode) gcode.visible = false;
  return state;
};

export const unstageAllGcodes: PrinterReducer = (state, action) => {
  state.selectedGcodes.forEach((selected) => {
    unstageGcode(state, { selected });
  });
  return state;
};
