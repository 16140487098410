import {
  CommandTlcFirmwareUpdateParams,
  CommandTlcProvider,
} from "../../../application/providers/CommandTlcProvider";

export class CommandTlcProviderImpl implements CommandTlcProvider {
  async firmwareUpdate(
    params: CommandTlcFirmwareUpdateParams
  ): Promise<string> {
    return `;tlc update ${params.type} ${params.downloadUrl}`;
  }

  async greetRequest(): Promise<string> {
    return ";tlc greet-request";
  }
}
