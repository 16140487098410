import {
  UserRepository,
  UserSignInParams,
  UserSignInResponse,
  UserUpdateParams,
} from "../../../application/data/repositories/UserRepository";
import { User, UserWithSettings } from "../../../application/models/User";
import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import { UserMapper } from "../mappers/UserMapper";
import {
  UserAndTokenRaw,
  UserRaw,
  UserWithSettingsRaw,
} from "../models/UserRaw";

export class UserRepositoryImpl implements UserRepository {
  constructor(
    private httpProvider: HttpProvider,
    private userMapper: UserMapper
  ) {}

  async signIn(params: UserSignInParams): Promise<UserSignInResponse> {
    const response = await this.httpProvider.request<UserAndTokenRaw>({
      method: "POST",
      url: "/session",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userMapper.userSignResponseRawToUserSignInResponse(
      response.body
    );
  }

  async profile(): Promise<UserWithSettings> {
    const response = await this.httpProvider.request<UserWithSettingsRaw>({
      method: "GET",
      url: "/profile",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userMapper.userWithSettingsRawToUserWithSettings(response.body);
  }

  async healthCheck(): Promise<void> {
    await this.httpProvider.request({
      method: "GET",
      url: "/health-check",
    });
  }

  async update(params: UserUpdateParams): Promise<User> {
    const response = await this.httpProvider.request<UserRaw>({
      method: "PATCH",
      url: "/",
      body: params,
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return this.userMapper.userRawToUser(response.body);
  }
}
