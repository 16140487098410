import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";

interface DeleteBtnProps extends DispatchProp, AppState {
  onClick: () => Promise<void>;
}

class DeleteBtn extends React.Component<DeleteBtnProps> {
  async handleClick() {
    try {
      this.props.dispatch({ type: "project/wait" });
      this.props.onClick();
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    } finally {
      this.props.dispatch({ type: "project/doneWait" });
    }
  }

  render() {
    if (!this.props.isProjectWaiting)
      return (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="deletedFile">delete</Tooltip>}
        >
          <Button onClick={() => this.props.onClick()} variant="danger">
            <i className="fas fa-trash" />
          </Button>
        </OverlayTrigger>
      );
    else return [];
  }
}

export default connect(mapStateToProps)(DeleteBtn);
