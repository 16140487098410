import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListResult,
  Record,
} from "react-admin";

import { AdminPrinterRepository } from "../../../../application/data/repositories/AdminPrinterRepository";

export class AdminPrinterProviderImpl {
  constructor(private printerRepository: AdminPrinterRepository) {}

  async getList<RecordType extends Record = Record>(): Promise<
    GetListResult<RecordType>
  > {
    const data = await this.printerRepository.findAll();
    return { data: data as unknown as RecordType[], total: data.length };
  }

  async create<RecordType extends Record = Record>(
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const data = await this.printerRepository.create(params.data);
    return { data: data as unknown as RecordType };
  }

  async delete<RecordType extends Record = Record>(
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    await this.printerRepository.delete(parseInt(params.id.toString()));
    return { data: {} as RecordType };
  }

  async deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    await Promise.all(
      params.ids.map((id) =>
        this.printerRepository.delete(parseInt(id.toString()))
      )
    );

    return { data: [] };
  }
}
