import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";

interface MediaFormsProps {
  selectedWells: any[];
  centers: any[];
  title: string;
  handleSelectWell: (index: number) => void;
}

class MediaForms extends Component<MediaFormsProps> {
  handleClick(i: number) {
    this.props.handleSelectWell(i);
  }

  handleUnselectWell = (i: number) => {
    this.props.handleSelectWell(i);
  };

  componentDidMount() {
    let selectedWells = this.props.selectedWells;
    let centers = this.props.centers;

    if (selectedWells.some((well) => well >= centers.length)) {
      selectedWells.forEach((well) => {
        this.handleUnselectWell(well);
      });
    }

    if (centers.length <= 1 && !selectedWells.length) {
      this.props.handleSelectWell(0);
    }
  }

  render() {
    let btnsTable: React.ReactElement | React.ReactElement[] = [];

    if (this.props.centers.length > 1) {
      let temp = [];
      for (let i = 0; i < this.props.centers.length; i++) {
        if (
          Array.isArray(this.props.selectedWells) &&
          this.props.selectedWells.includes(i)
        ) {
          temp.push(
            <Button
              key={i}
              onClick={() => this.handleClick(i)}
              variant="light"
              className="media-well"
              style={{ fontSize: "1vw", padding: "0.5vw" }}
            >
              &#9679;
            </Button>
          );
        } else {
          temp.push(
            <Button
              key={i}
              onClick={() => this.handleClick(i)}
              variant="light"
              style={{ fontSize: "1vw", padding: "0.5vw" }}
            >
              &#9675;
            </Button>
          );
        }

        if (
          !this.props.centers[i + 1] ||
          this.props.centers[i].y !== this.props.centers[i + 1].y
        ) {
          btnsTable.push(
            <div key={i} className="media-rows">
              {temp}
            </div>
          );
          temp = [];
        }
      }
      btnsTable = <div className="media-forms">{btnsTable}</div>;
      return (
        <Form.Group>
          <Form.Label>{this.props.title}</Form.Label>
          {btnsTable}
        </Form.Group>
      );
    } else {
      return [];
    }
  }
}

export default MediaForms;
