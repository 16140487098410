import {
  PrinterResponseParserProvider,
  PrinterResponseWithParams,
} from "../../../application/providers/PrinterResponseParserProvider";

type ResponseParser = (
  params: string[]
) => Promise<PrinterResponseWithParams | null>;

class PrinterReadyToReceiveResponseParser {
  static async printerReadyToReceiveParser(
    _: string[]
  ): Promise<PrinterResponseWithParams | null> {
    return {
      type: "readyToReceive",
      params: [],
    };
  }
}

class TissueLabsCommandParser {
  static async tissuelabsCommandParser(
    params: string[]
  ): Promise<PrinterResponseWithParams | null> {
    if (params.length < 1) return null;

    const [command, ...args] = params;

    const parser =
      TissueLabsCommandParser.tissuelabsCommandParserMapper[command];

    return parser?.(args) || null;
  }

  private static tissuelabsCommandParserMapper: Record<string, ResponseParser> =
    {
      greet: TissueLabsCommandParser.tissuelabsGreetCommandParser,
    };

  private static async tissuelabsGreetCommandParser(
    args: string[]
  ): Promise<PrinterResponseWithParams | null> {
    if (args.length !== 4) return null;

    return {
      type: "greet",
      params: args,
    };
  }
}

export class PrinterResponseParserProviderImpl
  implements PrinterResponseParserProvider
{
  async parseLine(line: string): Promise<PrinterResponseWithParams | null> {
    const cleanLine = line
      .trim()
      .split(";") // remove printer comments (except for ';tlc' which comes in the line start)
      .filter((e) => e)[0];

    if (!cleanLine) return null;

    const [command, ...params] = cleanLine.split(/\s/);

    const parser =
      PrinterResponseParserProviderImpl.commandResponseParserMapper[command];

    return parser?.(params) || null;
  }

  private static commandResponseParserMapper: Record<string, ResponseParser> = {
    ok: PrinterReadyToReceiveResponseParser.printerReadyToReceiveParser,
    tlc: TissueLabsCommandParser.tissuelabsCommandParser,
  };
}
