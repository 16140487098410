import { useMemo, useRef, useState } from "react";

import { ViewModel } from "../common/ViewModel";

type StateType<K> = K extends ViewModel<infer T> ? T : never;

export const useViewModel = <T, K>(init: () => K, deps: any[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const viewModel = useMemo(init, deps);
  const stateRef = useRef<T>();
  const setStateRef = useRef<Function>();

  useMemo(() => {
    if (!(viewModel instanceof ViewModel)) {
      throw new Error("useViewModel should receive an instance of ViewModel.");
    }

    viewModel.setStateChangeCallback((state: T) => {
      stateRef.current = state;
      setStateRef.current?.(state);
    });
  }, [viewModel]);

  const [state, setState] = useState<StateType<K>>(
    stateRef.current as StateType<K>
  );
  setStateRef.current = setState;

  return { state, viewModel };
};
