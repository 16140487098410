import * as THREE from "three";
import {
  DUAL_EXTRUDER,
  LEFT_EXTRUDER,
  MIXTRUSOR,
  RIGHT_EXTRUDER,
  ROTATION_OFFSET_X,
} from "../../../../../constants";
import { ObjectState } from "../../models/ObjectState";
import { PrinterReducer } from ".";
import { AppState } from "../../models/AppState";

export const objectState0: ObjectState = {
  objectMaxNumber: 6,
  objects: [],
  selectedObjects: [null],
  alignMode: false,
  interactiveObject: null,
  selectedAlignObject: null,
};

export const addScene: PrinterReducer = (state, action) => {
  let scene = action.scene;
  return { ...state, scene };
};

export const createObject: PrinterReducer = (state, action) => {
  let object, geometry;
  const objectMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000 });
  let file = action.file;
  let model = action.model;

  if (file) {
    while (model.type === "Group") model = model.children[0];

    if (model.type === "Mesh" || model.type === "Group") {
      object = model;
    } else {
      geometry = model;
      object = new THREE.Mesh(geometry, objectMaterial);
    }
    object.name = file.name;
  } else {
    object = action.object;
  }

  object.visible = false;
  if (!action.isPositionCorrect) object = fixObjectOrientation(state, object);
  let mixProportion =
    state.selectedTool !== null
      ? getProportionFromTool(state.tools[state.selectedTool])
      : null;
  object = setObjectProportion(object, mixProportion);
  object.file = file;
  state = addObject(state, { object });

  return state;
};

export const addObject: PrinterReducer = (state, action) => {
  let object = action.object;
  let objects = state.objects;
  state.scene?.add(object);

  if (objects.length + 1 > state.objectMaxNumber)
    state = deleteObject({ ...state }, { deleted: 0 });
  objects = [...objects, object];
  return { ...state, objects };
};

export const editObject: PrinterReducer = (state, action) => {
  let objects = state.objects.slice();
  objects[action.selected] = action.object;
  return { ...state, objects };
};

export const deleteObject: PrinterReducer = (state, action) => {
  let objects = state.objects.slice();
  let selectedObjects = state.selectedObjects.slice();
  let slot = action.slot;
  let deleted = action.deleted;
  if (Number.isInteger(slot)) {
    deleted = selectedObjects[action.slot];
  } else {
    slot = selectedObjects.indexOf(deleted);
  }
  let object = objects[deleted];

  state.scene?.remove(object);

  objects.splice(deleted, 1);
  if (Number.isInteger(selectedObjects[slot])) selectedObjects[slot] = null;
  selectedObjects.forEach((_val, index) => {
    if (selectedObjects[index] && selectedObjects[index]! > deleted) {
      selectedObjects[index]! -= 1;
    }
  });
  return { ...state, objects, selectedObjects };
};

export const addObjectSlot: PrinterReducer = (state, action) => {
  let selectedObjects = state.selectedObjects.slice();
  selectedObjects.push(null);
  return { ...state, selectedObjects };
};

export const selectObject: PrinterReducer = (state, action) => {
  let selected = action.selected;
  let slot = action.slot;
  let selectedObjects = state.selectedObjects.slice();

  if (!Number.isInteger(selected)) {
    selected = state.objects.length - 1;
  }
  if (!Number.isInteger(slot)) {
    let freeSlot = selectedObjects.indexOf(null);
    if (freeSlot === -1) selectedObjects.push(selected);
    else selectedObjects[freeSlot] = selected;
  } else {
    unstageObject(state, { selected: selectedObjects[slot] });
    selectedObjects[slot] = selected;
  }

  stageObject(state, { selected });
  return { ...state, selectedObjects };
};

export const unselectObject: PrinterReducer = (state, action) => {
  let selectedObjects = state.selectedObjects.slice();
  let selected = selectedObjects[action.slot];
  if (selectedObjects.length <= 1) selectedObjects = [null];
  else selectedObjects.splice(action.slot, 1);
  unstageObject(state, { selected });
  return { ...state, selectedObjects };
};

export const unselectAllObjects: PrinterReducer = (state, action) => {
  state.selectedObjects.forEach((selected) =>
    unstageObject(state, { selected })
  );
  return { ...state, selectedObjects: [null] };
};

export const moveObject: PrinterReducer = (state, action) => {
  let selected = action.selected;
  let objects = state.objects;
  if (selected === -1) selected = objects.length - 1;
  let object = objects[selected];
  if (object) {
    object[action.parameter][`${action.axis}String`] = action.stringValue;
    object[action.parameter][action.axis] = parseFloat(action.stringValue);
    object.matrixWorldNeedsUpdate = true;
    action.object = object;
  }
  return editObject(state, action);
};

export const stageObject: PrinterReducer = (state, action) => {
  let object = state.objects[action.selected];
  if (object) object.visible = true;
  return state;
};

export const stageAllObjects: PrinterReducer = (state, action) => {
  state.selectedObjects.forEach((selected) => {
    stageObject(state, { selected });
  });
  return state;
};

export const unstageObject: PrinterReducer = (state, action) => {
  let object = state.objects[action.selected];
  if (object) object.visible = false;
  return state;
};

export const unstageAllObjects: PrinterReducer = (state, action) => {
  state.selectedObjects.forEach((selected) => {
    unstageObject(state, { selected });
  });
  return state;
};

export const centerObject: PrinterReducer = (state, action) => {
  let selected = action.selected;
  let object = state.objects[selected];

  let box = new THREE.Box3().setFromObject(object);
  let positionOffsetZ = box.min.y;
  let boxPositionX = (box.max.x + box.min.x) / 2;
  let boxPositionY = (box.max.z + box.min.z) / 2;
  let positionOffsetX = boxPositionX - state.center.x;
  let positionOffsetY = boxPositionY + state.center.y;

  state = moveObject(state, {
    ...action,
    selected,
    parameter: "position",
    axis: "x",
    stringValue: (object.position.x - positionOffsetX).toFixed(2),
  });
  state = moveObject(state, {
    ...action,
    selected,
    parameter: "position",
    axis: "y",
    stringValue: (object.position.y - positionOffsetZ).toFixed(2),
  });
  return moveObject(state, {
    ...action,
    selected,
    parameter: "position",
    axis: "z",
    stringValue: (object.position.z - positionOffsetY).toFixed(2),
  });
};

const fixObjectOrientation = (state: AppState, object: Record<string, any>) => {
  let box = new THREE.Box3();
  if (object && object.geometry) {
    object.geometry.computeBoundingBox();
    box.copy(object.geometry.boundingBox).applyMatrix4(object.matrixWorld);
  } else {
    box.setFromObject(object as any);
  }

  const positionOffsetZ = box.min.y;
  object.position.x = state.center.x;
  object.position.z = -state.center.y;
  object.rotation.x = ROTATION_OFFSET_X;
  if (positionOffsetZ < 0) {
    object.position.y -= positionOffsetZ;
  }

  return object;
};

export const getProportionFromTool = (tool?: Record<string, any>) => {
  let mixProportion = [100, 0];
  if (!tool || tool.id === MIXTRUSOR) {
    mixProportion[0] = 100;
    mixProportion[1] = 0;
  } else if (tool.id === DUAL_EXTRUDER) {
    mixProportion[0] = 100;
    mixProportion[1] = 100;
  } else if (tool.id === RIGHT_EXTRUDER) {
    mixProportion[0] = 100;
    mixProportion[1] = 0;
  } else if (tool.id === LEFT_EXTRUDER) {
    mixProportion[0] = 0;
    mixProportion[1] = 100;
  }

  return mixProportion;
};

const setObjectProportion = (
  object: Record<string, any>,
  mixProportion: number[] | null
) => {
  if (object.material) {
    if (!mixProportion) mixProportion = [100, 0];
    let mixProportion1 = mixProportion[0];
    let redProp = mixProportion1 / 100;
    let blueProp = (100 - mixProportion1) / 100;
    let color =
      (Math.trunc(0xff * redProp) << 16) + Math.trunc(0xff * blueProp);
    object.material.color.setHex(color);
    object.mixProportion = mixProportion;
  }
  return object;
};

export const changeObjectMaterialProportion: PrinterReducer = (
  state,
  action
) => {
  let mixProportion = action.mixProportion;
  let slot = action.slot;
  let selected: any;
  if (action.selected || action.selected === 0) {
    selected = action.selected;
  } else if (slot || slot === 0) {
    selected = state.selectedObjects[slot];
  }

  if (selected === 0 && state.objects[selected]) {
    const object = setObjectProportion(state.objects[selected], mixProportion);
    return editObject(state, { ...action, object, selected });
  } else {
    state.objects
      .filter((object) => !!object)
      .forEach((object) => {
        object = setObjectProportion(object, mixProportion);
        state = editObject(state, { ...action, object, selected });
      });
    return state;
  }
};

export const generateAlignInBed: PrinterReducer = (state, action) => {
  let selectedAlignObject = action.selected;
  let oldObject = state.objects[selectedAlignObject];

  let objectMaterial = new THREE.MeshPhongMaterial({
    vertexColors: true,
    color: new THREE.Color("white"),
  });
  let interactiveObject = new THREE.Mesh(oldObject.geometry, objectMaterial);
  let positionAttribute = interactiveObject.geometry.getAttribute("position");
  let colors = [];
  let color = new THREE.Color("white");
  for (let i = 0; i < positionAttribute.count; i += 3) {
    colors.push(color.r, color.g, color.b);
    colors.push(color.r, color.g, color.b);
    colors.push(color.r, color.g, color.b);
  }

  interactiveObject.geometry.setAttribute(
    "color",
    new THREE.Float32BufferAttribute(colors, 3)
  );
  interactiveObject.rotation.x = oldObject.rotation.x;
  interactiveObject.rotation.y = oldObject.rotation.y;
  interactiveObject.rotation.z = oldObject.rotation.z;
  interactiveObject.position.x = oldObject.position.x;
  interactiveObject.position.y = oldObject.position.y;
  interactiveObject.position.z = oldObject.position.z;
  interactiveObject.scale.x = oldObject.scale.x;
  interactiveObject.scale.y = oldObject.scale.y;
  interactiveObject.scale.z = oldObject.scale.z;

  state.scene?.add(interactiveObject);
  oldObject.visible = false;
  let alignMode = true;

  return { ...state, alignMode, interactiveObject, selectedAlignObject };
};

export const alignObject: PrinterReducer = (state, action) => {
  let intersectedFace = action.intersectedFace;
  let object = state.objects[state.selectedAlignObject];
  let normal = new THREE.Vector3(
    intersectedFace.normal.x,
    intersectedFace.normal.y,
    intersectedFace.normal.z
  );
  let axis = new THREE.Vector3(0, -1, 0);
  object.quaternion.setFromUnitVectors(normal, axis);

  state = moveObject(state, {
    selected: state.selectedAlignObject,
    parameter: "rotation",
    axis: "x",
    stringValue: object.rotation.x.toFixed(2),
  });
  state = moveObject(state, {
    selected: state.selectedAlignObject,
    parameter: "rotation",
    axis: "y",
    stringValue: object.rotation.y.toFixed(2),
  });
  state = moveObject(state, {
    selected: state.selectedAlignObject,
    parameter: "rotation",
    axis: "z",
    stringValue: object.rotation.z.toFixed(2),
  });
  state = centerObject(state, { selected: state.selectedAlignObject });
  state = cancelAlignInBed(state, {});
  return state;
};

export const cancelAlignInBed: PrinterReducer = (state, action) => {
  let object = state.objects[state.selectedAlignObject];
  let interactiveObject = state.interactiveObject;
  if (interactiveObject) {
    state.scene?.remove(interactiveObject);
    interactiveObject = null;
  }
  if (object && state.selectedObjects.includes(state.selectedAlignObject)) {
    object.visible = true;
  }
  return {
    ...state,
    alignMode: false,
    interactiveObject,
    selectedAlignObject: null,
  };
};
