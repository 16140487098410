import React from "react";
import { Card, Button, Carousel } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";

import { loadModel } from "../../../../data/network/apiServer";
import MyCarousel, { CarouselBtn } from "../shared/myCarousel";
import folderImage from "../../../assets/images/folder.png";
import { AppState } from "../../../models/AppState";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";
import {
  UserTissueModel,
  UserTissueModelFolder,
} from "../../../../../application/models/UserTissueModel";
import { userContainer } from "../../../../di/container";

class DbModelCardsSet extends React.Component {
  render() {
    return (
      <div
        className="dbmodel-cards"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          padding: "1rem 1.5rem",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

interface DbModelListProps extends AppState, DispatchProp {
  models: any[];
  getModels: (folder: string) => Promise<UserTissueModel[]>;
  getFolders: () => Promise<UserTissueModelFolder[]>;
  updateModels: (newValue: any) => Promise<void>;
  handleObjectUpload: (model: any, file: any) => void;
}

interface DbModelListState {
  folders: string[];
  selectedFolder: string | null;
  carouselItems: any[];
}

class DbModelList extends React.Component<DbModelListProps, DbModelListState> {
  constructor(props: DbModelListProps) {
    super(props);

    this.state = {
      folders: [],
      selectedFolder: null,
      carouselItems: [],
    };
  }

  componentDidMount() {
    const loadModels = async () => {
      try {
        const folders = await this.props.getFolders();
        await this.handleAddFolders(folders);
        await this.handlePrepareCarousel();
      } catch (err) {
        Popup.error(this.props.dispatch, err);
      }
    };

    loadModels();
  }

  handleAddModels = (newModels: UserTissueModel[]) => {
    newModels = newModels.filter(
      (newModel) =>
        -1 === this.props.models.findIndex((model) => model.id === newModel.id)
    );
    return this.props.updateModels(this.props.models.concat(newModels));
  };

  handleAddFolders = async (newFolders: UserTissueModelFolder[]) => {
    return new Promise<void>((resolve) =>
      this.setState(
        {
          folders: newFolders.map((e) => e.name),
        },
        resolve
      )
    );
  };

  handleSelectFolder = async (folder: any) => {
    await new Promise<void>((resolve) =>
      this.setState({ selectedFolder: folder }, resolve)
    );

    await this.props.updateModels([]);
  };

  handleUseModel = async (modelId: number) => {
    try {
      const response = await userContainer.repositories.tissueModel.copy({
        projectId: parseInt(this.props.projectId),
        modelId,
      });
      const model = await loadModel(response.fileAddress);
      this.props.handleObjectUpload(model, response);
    } catch (error) {
      console.warn("Use model error:", error);
      const msg = error instanceof Error ? error.message : String(error);
      alert(msg);
    }
  };

  handleOpenFolder = async (folder: string) => {
    const response = await this.props.getModels(folder);

    await this.handleAddModels(response);
    await this.handlePrepareCarousel();
  };

  handleCloseCarousel = async () => {
    try {
      await this.handleSelectFolder("");
      await this.handlePrepareCarousel();
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    }
  };

  handleCardClick = async (folder: string) => {
    try {
      await this.handleSelectFolder(folder);
      await this.handleOpenFolder(folder);
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    }
  };

  handlePrepareCarousel = async () => {
    const carouselItems: React.ReactElement[] = [];

    if (!this.state.selectedFolder) {
      for (let j = 0; j < this.state.folders.length; j += 3) {
        let modelCards: React.ReactElement[] = [];
        this.state.folders.slice(j, j + 3).forEach((folder, index) => {
          modelCards.push(
            <Card key={index} onClick={() => this.handleCardClick(folder)}>
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={folderImage}
                  style={{ width: "12vw" }}
                />
                <Card.Title>{folder}</Card.Title>
              </Card.Body>
            </Card>
          );
        });
        carouselItems.push(
          <Carousel.Item key={j}>
            <DbModelCardsSet>{modelCards}</DbModelCardsSet>
          </Carousel.Item>
        );
      }
    } else {
      for (let j = 0; j < this.props.models.length; j += 3) {
        let modelCards: React.ReactElement[] = [];
        this.props.models.slice(j, j + 3).forEach((model, index) => {
          modelCards.push(
            <Card key={index} style={{ width: "15vw" }}>
              <Card.Body>
                <Card.Img variant="top" src={model.thumbnailAddress} />
                <Card.Title>{model.name}</Card.Title>
                <Button
                  className="add-model-btn"
                  variant="primary"
                  onClick={() => this.handleUseModel(model.id)}
                >
                  upload
                </Button>
              </Card.Body>
            </Card>
          );
        });
        carouselItems.push(
          <Carousel.Item key={j}>
            <DbModelCardsSet>{modelCards}</DbModelCardsSet>
          </Carousel.Item>
        );
      }
    }

    return new Promise<void>((resolve) =>
      this.setState(
        {
          carouselItems: carouselItems,
        },
        resolve
      )
    );
  };

  handleRefresh = async () => {
    try {
      const folder = this.state.selectedFolder;
      if (!folder) {
        return;
      }

      const tissueModels = await this.props.getModels(folder);
      if (!tissueModels.length) {
        return;
      }

      await this.handleAddModels(tissueModels);
      await this.handlePrepareCarousel();
    } catch (err) {
      Popup.error(this.props.dispatch, err);
    }
  };

  render() {
    return (
      <div>
        {!!this.state.selectedFolder && (
          <CarouselBtn onClick={() => this.handleCloseCarousel()}>
            <i className="fas fa-long-arrow-alt-left" /> back
          </CarouselBtn>
        )}
        <MyCarousel
          key={this.state.selectedFolder ? this.state.selectedFolder : 0}
          refresh={() => this.handleRefresh()}
        >
          {this.state.carouselItems}
        </MyCarousel>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DbModelList);
