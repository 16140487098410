import {
  AdminFirmwareHeaderParser,
  AdminFirmwareHeader,
} from "../AdminFirmwareHeaderParser";

export class AdminFirmwareHeaderParserTissueStartESP32
  implements AdminFirmwareHeaderParser
{
  async execute(
    firmwareByteArray: Array<number>
  ): Promise<AdminFirmwareHeader> {
    const magicWord = firmwareByteArray
      .slice(32, 36)
      .map((e) => e.toString(16).toUpperCase())
      .join("");

    if (magicWord !== "3254CDAB") {
      throw new Error("Invalid magic word.");
    }

    const projectName = firmwareByteArray
      .slice(80, 112)
      .filter((e) => e)
      .map((e) => String.fromCharCode(e))
      .join("");

    if (projectName !== "tissue_brain") {
      throw new Error("Invalid project name.");
    }

    const version = firmwareByteArray
      .slice(48, 80)
      .filter((e) => e)
      .map((e) => String.fromCharCode(e))
      .join("");

    return {
      printerModel: "tissuestart",
      type: "ESP32",
      version,
    };
  }
}
