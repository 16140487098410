import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Record,
  UpdateParams,
  UpdateResult,
} from "react-admin";

import { AdminSettingsRepository } from "../../../../application/data/repositories/AdminSettingsRepository";

export class AdminSettingsProviderImpl {
  constructor(private settingsRepository: AdminSettingsRepository) {}

  async getList<RecordType extends Record = Record>(
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {
    const data = await (params.filter.type
      ? this.settingsRepository.findAllByType(params.filter.type)
      : this.settingsRepository.findAll());
    return { data: data as unknown as RecordType[], total: data.length };
  }

  async getOne<RecordType extends Record = Record>(
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const data = await this.settingsRepository.findById(
      parseInt(params.id.toString())
    );
    return { data: data as unknown as RecordType };
  }

  async create<RecordType extends Record = Record>(
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType>> {
    const data = await this.settingsRepository.create(params.data);
    return { data: data as unknown as RecordType };
  }

  async update<RecordType extends Record = Record>(
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const data = await this.settingsRepository.update({
      id: parseInt(params.id.toString()),
      content: {
        tools: params.data.tools,
        medias: params.data.medias,
        slicer: params.data.slicer,
        pipette: params.data.pipette,
        storage: params.data.storage,
      },
    });
    return { data: data as unknown as RecordType };
  }

  async delete<RecordType extends Record = Record>(
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    await this.settingsRepository.delete(parseInt(params.id.toString()));
    return { data: {} as RecordType };
  }

  async getMany<RecordType extends Record = Record>(): Promise<
    GetManyResult<RecordType>
  > {
    const data = await this.settingsRepository.findAll();
    return { data: data as unknown as RecordType[] };
  }

  async deleteMany(params: DeleteManyParams): Promise<DeleteManyResult> {
    await Promise.all(
      params.ids.map((id) =>
        this.settingsRepository.delete(parseInt(id.toString()))
      )
    );

    return { data: [] };
  }
}
