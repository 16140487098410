import { withRouter } from "react-router-dom";
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  CreateProps,
} from "react-admin";

const FirmwareCreate = (props: CreateProps) => {
  return (
    <Create title="Add a new firmware version" {...props}>
      <SimpleForm>
        <FileInput source="file" label="Firmware" accept=".bin" isRequired>
          <FileField source="src" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default withRouter(FirmwareCreate);
