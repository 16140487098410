import { Gcode } from "../../presentation/models/GcodeState";
import { GCodeLayerProvider } from "../GCodeLayerProvider";

export class GCodeLayerProviderImpl implements GCodeLayerProvider {
  async makeLayerVisible(gcode: Gcode, layerNumber: number): Promise<void> {
    const reg = layerNumber !== 0 ? `extruded-layer${layerNumber}$` : "";

    gcode.children.forEach((layer: any) => {
      const regex = new RegExp(reg);
      layer.visible = !!layer.name.match(regex);
    });
  }
}
