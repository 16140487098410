import {
  SettingsType,
  settingsTypes,
} from "../../../../../shared/application/models/SettingsType";
import { AdminSettings } from "../../../../application/models/AdminSettings";
import { AdminSettingsRaw } from "../../models/AdminSettingsRaw";
import { AdminSettingsMapper } from "../AdminSettingsMapper";

export class AdminSettingsMapperImpl implements AdminSettingsMapper {
  async settingsRawToSettings(
    settingsRaw: AdminSettingsRaw
  ): Promise<AdminSettings> {
    if (!settingsTypes.includes(settingsRaw.type as SettingsType)) {
      throw new Error("Invalid settings type.");
    }

    return {
      id: settingsRaw.id,
      name: settingsRaw.name,
      fileName: settingsRaw.fileName,
      type: settingsRaw.type as SettingsType,
      createdAt: new Date(settingsRaw.createdAt),
      updatedAt: new Date(settingsRaw.updatedAt),
    };
  }
}
