import { HttpProvider } from "../../../../shared/framework/providers/HttpProvider";
import { PrinterConnection } from "../../../application/data/connections/PrinterConnection";
import {
  UserPrinterDisconnectCallback,
  UserPrinterReceiveCallback,
  UserPrinterRepository,
} from "../../../application/data/repositories/UserPrinterRepository";
import { UserPrinter } from "../../../application/models/UserPrinter";
import { UserPrinterMapper } from "../mappers/UserPrinterMapper";
import { UserPrinterRaw } from "../models/UserPrinterRaw";

export class UserPrinterRepositoryImpl implements UserPrinterRepository {
  private connection: PrinterConnection | null = null;
  private receiveCallback: UserPrinterReceiveCallback | null = null;
  private disconnectCallback: UserPrinterDisconnectCallback | null = null;

  constructor(
    private httpProvider: HttpProvider,
    private userPrinterMapper: UserPrinterMapper,
    private sendMessageLineEnding: string
  ) {}

  async list(): Promise<UserPrinter[]> {
    const response = await this.httpProvider.request<UserPrinterRaw[]>({
      method: "GET",
      url: "/printer",
    });

    if (!response.body) {
      throw new Error("Missing response body.");
    }

    return Promise.all(
      response.body.map((e) =>
        this.userPrinterMapper.userPrinterRawToUserPrinter(e)
      )
    );
  }

  async setConnection(connection: PrinterConnection): Promise<void> {
    this.connection = connection;

    if (this.receiveCallback) {
      this.connection!.setReceiveCallback(this.receiveCallback);
    }

    if (this.disconnectCallback) {
      this.connection!.setDisconnectCallback(this.disconnectCallback);
    }
  }

  async send(data: string): Promise<void> {
    if (!data.endsWith(this.sendMessageLineEnding)) {
      data += this.sendMessageLineEnding;
    }

    await this.connection?.send(data);
  }

  async isConnected(): Promise<boolean> {
    return (await this.connection?.isConnected()) || false;
  }

  async disconnect(): Promise<void> {
    this.connection?.disconnect();
  }

  async setReceiveCallback(
    callback: UserPrinterReceiveCallback
  ): Promise<void> {
    this.receiveCallback = callback;
    this.connection?.setReceiveCallback(callback);
  }

  async setDisconnectCallback(
    callback: UserPrinterDisconnectCallback
  ): Promise<void> {
    this.disconnectCallback = callback;
    this.connection?.setDisconnectCallback(callback);
  }
}
