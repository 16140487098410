import { Component } from "react";
import { Form, Dropdown, DropdownButton } from "react-bootstrap";

import MarkSlider from "./markSlider";

interface FormsFromJSONProps {
  forms: any[];
  data: Record<string, any>;
  showAdvanced?: boolean;
  handleEdit: (inputData: any) => void;
}

class FormsFromJSON extends Component<FormsFromJSONProps> {
  componentDidMount() {
    let forms = this.props.forms;

    if (forms && Array.isArray(forms)) {
      let ids: string[] = forms.map((field) => field.id);
      let fieldTypes = forms.map((field) => field.inputType);
      let inputOptions = forms.map((field) =>
        field.options.map((option: any) => option.id)
      );
      let standardOptions = forms.map((field) => field.standardOption);

      for (let i = 0; i < ids.length; i++) {
        if (fieldTypes[i] === "fixed") {
          const inputData: Record<string, any> = {};
          inputData[ids[i]] = inputOptions[i][0];
          this.props.handleEdit(inputData);
        } else if (
          fieldTypes[i] === "plain-text" ||
          fieldTypes[i] === "drop-box" ||
          fieldTypes[i] === "slider"
        ) {
          const inputData: Record<string, any> = {};
          if (!this.props.data[ids[i] as keyof typeof this.props.data]) {
            let standard = Number.parseInt(standardOptions[i]);
            standard = standard ? standard : 0;
            inputData[ids[i] as any] = inputOptions[i][standard];
            this.props.handleEdit(inputData);
          }
        }
      }
    }
  }

  handleForm(event: Event) {
    let inputData = function () {
      return {
        // @ts-ignore
        [this.target.id]: this.target.value,
      };
    }.bind(event)();

    this.props.handleEdit(inputData);
  }

  handleSelect(id: string, option: any) {
    let inputData: Record<string, any> = {};
    inputData[id] = option;
    this.props.handleEdit(inputData);
  }

  handleShowAdvanced() {
    this.setState({
      showAdvanced: true,
    });
  }

  getData = () => {
    let fieldNames = this.props.forms.map((field) => field.label);
    let ids = this.props.forms.map((field) => field.id);
    let fieldTypes = this.props.forms.map((field) => field.inputType);
    let multipliers = this.props.forms.map((field) => {
      let multiplier = parseFloat(field.multiplier);
      return Number.isFinite(multiplier) ? multiplier : 1;
    });
    let inputOptions = this.props.forms.map((field) =>
      field.options.map((option: any) => option.id)
    );
    let data = { ...this.props.data };
    let standardOptions = this.props.forms.map((field) => field.standardOption);

    for (let i = 0; i < ids.length; i++) {
      if (fieldTypes[i] === "copy") {
        let copiedOption = data[fieldNames[i]];
        let copiedNumber = parseFloat(copiedOption);
        if (Number.isFinite(copiedNumber))
          data[ids[i]] = multipliers[i] * copiedNumber;
        else data[ids[i]] = copiedOption;
      } else if (
        fieldTypes[i] === "plain-text" ||
        fieldTypes[i] === "drop-box" ||
        fieldTypes[i] === "slider"
      ) {
        let dataNumber = parseFloat(this.props.data[ids[i]]);
        let optionNumber = parseFloat(inputOptions[i][0]);

        let standard = Number.parseInt(standardOptions[i]);
        standard = standard ? standard : 0;

        if (Number.isFinite(dataNumber))
          data[ids[i]] = multipliers[i] * dataNumber;
        else if (this.props.data[ids[i]])
          data[ids[i]] = this.props.data[ids[i]];
        else if (Number.isFinite(optionNumber))
          data[ids[i]] = multipliers[i] * optionNumber;
        else data[ids[i]] = inputOptions[i][standard];
      }
    }

    return data;
  };

  render() {
    let forms = this.props.forms;
    let formFields = [];

    if (forms && Array.isArray(forms)) {
      let fieldNames = forms.map((field) => field.label);
      let ids = forms.map((field) => field.id);
      let tips = forms.map((field) => field.tips);
      let fieldTypes = forms.map((field) => field.inputType);
      let inputLabels = forms.map((field) =>
        field.options.map((option: any) => option.label)
      );
      let inputOptions = forms.map((field) =>
        field.options.map((option: any) => option.id)
      );
      let isAdvanced = forms.map((field) => field.isAdvanced);
      let standardOptions = forms.map((field) => field.standardOption);

      for (let i = 0; i < ids.length; i++) {
        if (
          fieldTypes[i] === "plain-text" &&
          (!isAdvanced[i] || this.props.showAdvanced)
        ) {
          formFields.push(
            <Form.Group key={i} controlId={ids[i]}>
              <Form.Label>{fieldNames[i]}</Form.Label>
              <Form.Control
                onChange={(e) => this.handleForm(e as unknown as Event)}
                value={this.props.data[ids[i]]}
                placeholder={tips[i]}
              />
            </Form.Group>
          );
        }
        if (
          fieldTypes[i] === "drop-box" &&
          (!isAdvanced[i] || this.props.showAdvanced)
        ) {
          let items = [];
          for (let j = 0; j < inputLabels[i].length; j++) {
            items.push(
              <Dropdown.Item
                key={i * j}
                onClick={() => this.handleSelect(ids[i], inputOptions[i][j])}
              >
                {inputLabels[i][j]}
              </Dropdown.Item>
            );
          }
          let fieldValue;
          inputOptions[i].forEach((option: any, index: number) => {
            if (option === this.props.data[ids[i]]) {
              fieldValue = inputLabels[i][index];
            }
          });
          formFields.push(
            <Form.Group key={i} controlId={ids[i]}>
              <Form.Label>{fieldNames[i]}</Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                variant="secondary"
                title={fieldValue ? fieldValue : tips[i]}
                className="forms-dropdown"
              >
                {items}
              </DropdownButton>
            </Form.Group>
          );
        }
        if (
          fieldTypes[i] === "slider" &&
          (!isAdvanced[i] || this.props.showAdvanced)
        ) {
          let options = inputOptions[i];
          let first = options[0];
          let last = options[options.length - 1];
          let step = Math.pow(10, Math.round(Math.log10(last - first)) - 2);

          let currentSlider;
          if (!Number.isFinite(this.props.data[ids[i]])) {
            let standard = Number.parseInt(standardOptions[i]);
            standard = standard ? standard : 0;
            currentSlider = options[standard];
          } else {
            currentSlider = this.props.data[ids[i]];
          }
          formFields.push(
            <Form.Group key={i} controlId={ids[i]}>
              <Form.Label>{fieldNames[i]}</Form.Label>
              <div>
                <MarkSlider
                  step={step}
                  defaultValue={currentSlider}
                  min={first}
                  max={last}
                  marks={[
                    {
                      value: first,
                      label: first,
                    },
                    {
                      value: last,
                      label: last,
                    },
                  ]}
                  onChange={(val) => this.handleSelect(ids[i], val)}
                  onChangeCommitted={() => {}}
                />
              </div>
            </Form.Group>
          );
        }
      }
      return formFields;
    } else {
      return [];
    }
  }
}

// dont add redux to components with refs
export default FormsFromJSON;
