import { ButtonGroup, ToggleButton } from "react-bootstrap";

interface SectionStepsControlProps<T> {
  radios: { name: T; value: T; disabled: boolean }[];
  variant?: string;
  selected: T;
  onSelect: (selected: string) => void;
}

export const SectionStepsControl = <T extends string | number>({
  radios,
  selected,
  variant,
  onSelect,
}: SectionStepsControlProps<T>) => {
  return (
    <ButtonGroup toggle>
      {radios.map((radio, idx) => (
        <ToggleButton
          disabled={radio.disabled}
          key={idx}
          type="radio"
          variant={variant ? variant : "outline-light"}
          name="radio"
          value={radio.value}
          checked={selected === radio.value}
          onChange={(e) => onSelect(e.currentTarget.value)}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};
