import React from "react";
import { Spinner } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import * as THREE from "three";
import { WEBGL } from "three/examples/jsm/WebGL.js";

import { AppState } from "../../../models/AppState";

import "./viewer.css";
import { Popup } from "../../../redux/Popup";
import { mapStateToProps } from "../../../redux";

interface ViewerProps extends AppState, RouteComponentProps, DispatchProp {}

interface ViewerState {
  intersected: any;
  raycaster: any;
  saveInterval: any;
}

class Viewer extends React.Component<ViewerProps, ViewerState> {
  private animationId = -1;
  private mouse: THREE.Vector2 = new THREE.Vector2();
  private alignEventsFired = false;

  constructor(props: ViewerProps) {
    super(props);
    this.state = {
      intersected: null,
      raycaster: null,
      saveInterval: null,
    };

    this.onMouseMove = this.onMouseMove.bind(this);
    this.handleClickAlignObject = this.handleClickAlignObject.bind(this);
  }

  onWindowResize = () => {
    let camera = this.props.camera;
    let renderer = this.props.renderer;
    if (camera && renderer) {
      let viewerSpace = document.getElementsByClassName("viewer")[0];
      let viewerDim = viewerSpace.getBoundingClientRect();
      camera.left = viewerDim.width / -2;
      camera.right = viewerDim.width / 2;
      camera.top = viewerDim.height / 2;
      camera.bottom = viewerDim.height / -2;
      camera.updateProjectionMatrix();

      renderer.setSize(viewerDim.width, viewerDim.height);
    } else {
      console.log("camera undefined");
    }
  };

  onMouseMove = (event: any) => {
    if (this.props.alignMode) {
      let viewerSpace = document.getElementsByClassName("viewer")[0];
      let viewerDim = viewerSpace.getBoundingClientRect();

      this.mouse.x = (event.clientX / viewerDim.width) * 2 - 2.1;
      this.mouse.y = -(event.clientY / viewerDim.height) * 2 + 1;
    }
  };

  handleCloseProject() {
    if (this.props.unsavedChanges) {
      Popup.close(this.props.dispatch);
    } else {
      this.props.dispatch({ type: "project/close" });
    }
  }

  handleHoverAlignObject = () => {
    let interactiveObject = this.props.interactiveObject;
    if (this.props.alignMode && interactiveObject) {
      if (!this.alignEventsFired) {
        let viewerSpace = document.getElementsByClassName("viewer")[0];
        viewerSpace.addEventListener("pointermove", this.onMouseMove);
        viewerSpace.addEventListener("click", () =>
          this.handleClickAlignObject()
        );
        this.alignEventsFired = true;
      }

      let raycaster = this.state.raycaster;
      raycaster.setFromCamera(this.mouse, this.props.camera);
      let intersects = raycaster.intersectObjects([interactiveObject]);
      let colorAttribute, face;
      if (intersects[0]) {
        if (this.state.intersected) {
          colorAttribute =
            this.state.intersected.object.geometry.getAttribute("color");
          face = this.state.intersected.face;
          colorAttribute.setXYZ(face.a, 1, 1, 1);
          colorAttribute.setXYZ(face.b, 1, 1, 1);
          colorAttribute.setXYZ(face.c, 1, 1, 1);
        }

        colorAttribute = intersects[0].object.geometry.getAttribute("color");
        face = intersects[0].face;
        colorAttribute.setXYZ(face.a, 0, 0, 0);
        colorAttribute.setXYZ(face.b, 0, 0, 0);
        colorAttribute.setXYZ(face.c, 0, 0, 0);

        this.setState({
          intersected: intersects[0],
        });
        colorAttribute.needsUpdate = true;
      } else if (this.state.intersected) {
        colorAttribute =
          this.state.intersected.object.geometry.getAttribute("color");
        face = this.state.intersected.face;
        colorAttribute.setXYZ(face.a, 1, 1, 1);
        colorAttribute.setXYZ(face.b, 1, 1, 1);
        colorAttribute.setXYZ(face.c, 1, 1, 1);

        this.setState({
          intersected: null,
        });
        colorAttribute.needsUpdate = true;
      }
    } else if (this.alignEventsFired) {
      let viewerSpace = document.getElementsByClassName("viewer")[0];
      viewerSpace.removeEventListener("pointermove", this.onMouseMove, false);
      viewerSpace.removeEventListener(
        "click",
        () => this.handleClickAlignObject(),
        false
      );
      this.alignEventsFired = false;

      this.setState({
        intersected: null,
      });
    }
  };

  handleClickAlignObject() {
    let intersected = this.state.intersected;
    if (this.props.alignMode && intersected) {
      this.props.dispatch({
        type: "object/align",
        intersectedFace: intersected.face,
      });
    }
  }

  // Animate
  animate = () => {
    this.animationId = requestAnimationFrame(this.animate);

    if (
      this.props.scene &&
      this.props.camera &&
      this.props.controls &&
      this.props.renderer
    ) {
      this.props.controls.update();
      this.handleHoverAlignObject();
      this.props.renderer.render(this.props.scene, this.props.camera);
      if (
        this.props.camera.position.y <= 0 &&
        this.props.bed &&
        this.props.bedLine
      ) {
        this.props.bed.visible = false;
        this.props.bedLine.visible = true;
      } else if (this.props.bed && this.props.bedLine) {
        this.props.bed.visible = true;
        this.props.bedLine.visible = false;
      }
    }
  };

  activateAutoSave = () => {
    this.setState({
      saveInterval: setInterval(() => {
        if (
          !this.props.isProjectWaiting &&
          this.props.printingStatus !== "printing"
        )
          this.props.dispatch({
            type: "project/save",
          });
      }, 5 * 60 * 1000),
    });
  };

  componentDidMount() {
    this.props.dispatch({ type: "reset" });

    this.alignEventsFired = false;
    this.activateAutoSave();

    // Renderer
    window.addEventListener("resize", this.onWindowResize, false);

    // Mouse
    let raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.setState({
      raycaster,
    });

    if (WEBGL.isWebGLAvailable()) {
      // Initiate function or other initializations here
      this.animate();
    } else {
      const warning = WEBGL.getWebGLErrorMessage();
      document.getElementById("container")?.appendChild(warning);
    }
  }

  componentWillUnmount() {
    try {
      this.setState({
        saveInterval: clearInterval(this.state.saveInterval),
      });

      let viewerSpace = document.getElementsByClassName("viewer")[0];
      this.props.renderer?.dispose();
      cancelAnimationFrame(this.animationId);
      window.removeEventListener("resize", this.onWindowResize, false);
      if (viewerSpace) {
        viewerSpace.removeEventListener("pointermove", this.onMouseMove, false);
        viewerSpace.removeEventListener(
          "click",
          this.handleClickAlignObject,
          false
        );
      }
      this.props.dispatch({ type: "reset" });
    } catch (e) {
      return;
    }
  }

  componentDidUpdate(prevProps: ViewerProps) {
    if (this.props.saved && !prevProps.saved)
      setTimeout(
        () =>
          this.props.dispatch({
            type: "project/hideSaveMessage",
          }),
        2000
      );
  }

  render() {
    if (this.props.willCloseProject) {
      return <Redirect to="/home" />;
    }

    let infoMessage = this.props.saved ? "saved!" : "";
    let spinner = this.props.isProjectWaiting ? (
      <Spinner animation="border" variant="secondary" />
    ) : null;

    return (
      <div>
        <div className="viewer" />

        {this.props.isViewerReady && (
          <div>
            <button
              className="close-btn"
              onClick={() => this.handleCloseProject()}
            >
              <i className="fas fa-times" />
            </button>

            <button
              className="save-btn"
              onClick={() => this.props.dispatch({ type: "project/save" })}
            >
              <i className="fas fa-save" />
            </button>

            <p className="informer">{infoMessage}</p>

            <div className="loading-spinner">{spinner}</div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Viewer));
