import { ViewModel } from "../../../common/ViewModel";
import { ProjectAlert } from "../../../../models/ProjectState";
import { PrintingStatus } from "../../../../models/PrintingStatus";
import { CommandGcodeProvider } from "../../../../../../application/providers/CommandGcodeProvider";
import { PrintingService } from "../../../../../../application/services/PrintingService";

export interface ControlSectionViewModelState {
  isZSet: boolean;
  isHomeSet: boolean;
  isHomeWarningShowed: boolean;
  distance: number;
  alert: ProjectAlert | null;
  toolOffSet: {
    x: number;
    y: number;
  };
  centers: {
    x: number;
    y: number;
  };
}

export class ControlSectionViewModel extends ViewModel<ControlSectionViewModelState> {
  constructor(
    private commandGcodeProvider: CommandGcodeProvider,
    private printingService: PrintingService,
    state: ControlSectionViewModelState,
  ) {
    super(state);
  }

  async verifyPrintingAlert(printingStatus: PrintingStatus) {
    this.changeState({
      ...this.state,
      alert:
        printingStatus === "printing"
          ? this.getChangeControlsWhilePrintingAlert()
          : null,
    });
  }

  private getChangeControlsWhilePrintingAlert(): ProjectAlert {
    return {
      title: "Warning",
      message:
        "We strongly discourage you from manually trying to move the printer while it is in service",
      type: "viewModelAlert",
    };
  }

  async moveForward() {
    try {
      const commands = await this.commandGcodeProvider.moveForward(
        this.state.distance,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveBackward() {
    try {
      const commands = await this.commandGcodeProvider.moveBackward(
        this.state.distance,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveLeft() {
    try {
      const commands = await this.commandGcodeProvider.moveLeft(
        this.state.distance,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveRight() {
    try {
      const commands = await this.commandGcodeProvider.moveRight(
        this.state.distance,
      );
      await this.printingService.sendCommands(commands);
      this.changeState({ ...this.state, isHomeSet: true });
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveUpZ() {
    try {
      const commands = await this.commandGcodeProvider.moveUpZ(
        this.state.distance,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveDownZ() {
    try {
      const commands = await this.commandGcodeProvider.moveDownZ(
        this.state.distance,
      );
      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveToCenter() {
    try {
      const commands = await this.commandGcodeProvider.moveToCenter({
        height: this.state.distance,
        toolOffset: {
          x: this.state.toolOffSet.x,
          y: this.state.toolOffSet.y,
        },
        centers: {
          x: this.state.centers.x,
          y: this.state.centers.y,
        },
      });

      await this.printingService.sendCommands(commands);
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async moveToHome() {
    try {
      if (!this.state.isHomeWarningShowed) {
        return this.changeState({
          ...this.state,
          isHomeWarningShowed: true,
          alert: {
            title: "Warning",
            message:
              "Please, before homing, check if there are no objects on the way to avoid collisions",
            type: "viewModelAlert",
          },
        });
      }

      const commands = await this.commandGcodeProvider.moveToHome();
      await this.printingService.sendCommands(commands);
      this.changeState({ ...this.state, isHomeSet: true });
    } catch (err) {
      this.handleError("Failed to send command", err);
    }
  }

  async setZ() {
    const commands = await this.commandGcodeProvider.setZAxis();
    await this.printingService.sendCommands(commands);

    this.changeState({
      ...this.state,
      isZSet: true,
    });
  }

  async setDistance(value: number) {
    this.changeState({
      ...this.state,
      distance: value,
    });
  }

  async clearAlert() {
    this.changeState({ ...this.state, alert: null });
  }

  private handleError(title: string, err: unknown) {
    const message = err instanceof Error ? err.message : String(err);

    this.changeState({
      ...this.state,
      alert: {
        title,
        message,
        type: "viewModelAlert",
      },
    });
  }
}
