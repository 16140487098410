import { withRouter } from "react-router-dom";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  EditProps,
} from "react-admin";

const FirmwareEdit = (props: EditProps) => {
  return (
    <Edit title="Edit firmware" {...props}>
      <SimpleForm>
        <TextInput source="printerModel" disabled />
        <TextInput source="typeDisplayName" label="Type" disabled />
        <TextInput source="version" disabled />
        <SelectInput
          source="status"
          choices={[
            { id: "enabled", name: "enabled" },
            { id: "disabled", name: "disabled" },
          ]}
          required
        />
      </SimpleForm>
    </Edit>
  );
};

export default withRouter(FirmwareEdit);
