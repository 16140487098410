import { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface DropDownProps {
  selected: number | null;
  label: string;
  names: any[];
  dontRepeat: number[];
  disabled?: boolean;
  handleSelect: (selected: number) => void;
  handleClick: () => void;
}

class DropDown extends Component<DropDownProps> {
  constructor(props: DropDownProps) {
    super(props);
    this.state = {};
  }

  render() {
    const rows = [];
    const numberOfNames = Array.isArray(this.props.names)
      ? this.props.names.length
      : 0;
    for (let i = 0; i < numberOfNames; i++) {
      if (!this.props.dontRepeat.includes(i)) {
        rows.push(
          <Dropdown.Item key={i} onClick={() => this.props.handleSelect(i)}>
            {this.props.names[i]}
          </Dropdown.Item>
        );
      }
    }

    const currentSelection =
      this.props.selected !== null && this.props.names[this.props.selected]
        ? this.props.names[this.props.selected]
        : "";

    const title = this.props.label
      ? this.props.label + ": " + currentSelection
      : currentSelection;

    return (
      <DropdownButton
        className="dropdown-section"
        onClick={() => this.props.handleClick()}
        id="dropdown-basic-button"
        title={title}
        variant="light"
        disabled={this.props.disabled}
      >
        {rows}
      </DropdownButton>
    );
  }
}

export default DropDown;
