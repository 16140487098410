import React from "react";

import TutorialModal from "./tutorialModal";

interface HelpBtnProps {
  isNewbie: boolean;
  section: string;
  onClickScreenProtector: () => void;
}

interface HelpBtnState {
  showTutorialTab?: string;
}

class HelpBtn extends React.Component<HelpBtnProps, HelpBtnState> {
  constructor(props: HelpBtnProps) {
    super(props);

    this.state = {
      showTutorialTab: "",
    };
  }

  render() {
    let newbieScreenProtector = this.props.isNewbie ? (
      <div>
        <div
          onClick={() => this.props.onClickScreenProtector()}
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 9,
          }}
        />
        <style>
          {`
        			    @keyframes point {
        			         0% { margin-left: 32%; }
        			         100% { margin-left: 34%; }
        			    }
        			`}
        </style>
        <div
          style={{
            position: "fixed",
            top: "8rem",
            fontSize: "3rem",
            color: "white",
            zIndex: 11,
            animation: `point 0.8s ease alternate infinite`,
          }}
        >
          <i className="fas fa-arrow-left" />
          &#160; {this.props.section} tutorial
        </div>
      </div>
    ) : (
      []
    );

    return (
      <div>
        <button
          type="button"
          className="help-btn"
          onClick={() => {
            this.props.onClickScreenProtector();
            this.setState({
              showTutorialTab: this.props.section,
            });
          }}
          style={{
            position: "fixed",
            left: "23rem",
            top: "9.25rem",
            height: "2rem",
            width: "2rem",
            background: "gray",
            color: "white",
            fontSize: "1rem",
            lineHeight: "2rem",
            textAlign: "center",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: "10",
          }}
        >
          ?
        </button>
        <TutorialModal
          section={this.state.showTutorialTab}
          onHide={() => this.setState({ showTutorialTab: undefined })}
        />
        {newbieScreenProtector}
      </div>
    );
  }
}

export default HelpBtn;
