import { UserMappers, userMappers } from "./mappers";
import { UserProviders, userProviders } from "./providers";
import { UserRepositories, userRepositories } from "./repositories";
import { userServices, UserServices } from "./services";

interface UserContainer {
  mappers: UserMappers;
  providers: UserProviders;
  repositories: UserRepositories;
  services: UserServices;
}

export const userContainer: UserContainer = {
  mappers: userMappers,
  providers: userProviders,
  repositories: userRepositories,
  services: userServices,
};
